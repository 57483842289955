


























export default {
    name: 'CoDragDropFileUpload',
    props: {
        supportedFile: {
            type: String,
            default: 'image/*',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    data() {
        return {
            dragging: false,
            results: null,
        };
    },
    methods: {
        handleFileInput(event) {
            this.processFileInput(event.target.files);
            event.target.value = '';
        },
        handleDragOver(event) {
            event.stopPropagation();
            event.preventDefault();
            this.dragging = event.type === 'dragover';
            event.dataTransfer.dropEffect = 'copy';
        },
        handleFileSelect(event) {
            event.stopPropagation();
            event.preventDefault();
            this.dragging = false;
            this.processFileInput(event.dataTransfer.files);
        },
        processFileInput(files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.match(this.supportedFile)) {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        if (this.results === null) this.results = [];
                        const results = {
                            filePreview: reader.result,
                            file,
                        };
                        this.results.push(results);
                        this.$emit('handleInput', results);
                    }.bind(this);
                    reader.readAsDataURL(file);
                    if (!this.multiple) break;
                }
            }
        },
        clearResults() {
            this.results = null;
        },
        clickInput() {
            this.$refs.fileInput.click();
        },
    },
};
