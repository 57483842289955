<template>
    <div>
        <CoEventCreate v-if="$unleash.isEnabled('frontend.neweventview')" />
        <Create v-else />
    </div>
</template>

<script>
import Create from './Create.vue';
import CoEventCreate from '@/components/Pages/user/event/CoEventCreate.vue';

export default {
    name: 'CreateEventWrapper',
    components: {
        Create,
        CoEventCreate,
    },
};
</script>
