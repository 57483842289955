





































import axios from 'axios';
import CoCardMarketItem from '@/components/Molecules/co-card-market-item/CoCardMarketItem.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';

import { MarketItem } from '@/components/Molecules/co-card-market-item/models.ts';
import { User } from '@/components/Molecules/co-card-page/models.ts';

export default {
    name: 'MarketItems',
    components: { CoCardMarketItem, CoButton, CoHeadline, CoRoundButton, CoCard },
    i18n: {
        messages: {
            en: {
                emptyheader: 'Work together',
                emptymessage: 'Create a market item for your community.',
            },
            de: {
                emptyheader: 'Arbeite zusammen',
                emptymessage: 'Erstelle einen Marktartikel für deine Community.',
            },
        },
    },
    data() {
        return {
            items: Array<MarketItem>(),
            next: null,
            loading: true,
            authors: {},
            isCirclesOn: this.$store.state.circlesOn,
            nextParams: null,
            feed: [],
        };
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();

        this.getItemsForCircles();
    },
    computed: {
        isDesktop() {
            return window.innerWidth >= 768;
        },
    },
    methods: {
        mapMarketItemStructure(object) {
            const marketItem = object.object;
            const spaceItem = object.space;
            const e: MarketItem = {
                id: marketItem.ID,
                title: marketItem.Title,
                description: marketItem.Description,
                slug: marketItem.Slug,
                image: marketItem.ImageURL,
                authorId: marketItem.AuthorID,
                author: this.authors[marketItem.AuthorID] ? this.authors[marketItem.AuthorID][0] : null,
                open: marketItem.Open,
            };

            if (marketItem.Participants && marketItem.Participants.length > 0) {
                e.followed = marketItem.Participants.includes(this.me.ID);
            }

            if (spaceItem && spaceItem.ID !== this.$store.state.space.ID) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            return e;
        },
        mapMarketItemsStructure() {
            this.items = this.feed.map((object) => this.mapMarketItemStructure(object));
        },
        getAuthors(items) {
            this.loading = true;
            const authorsIDs = [];

            // loop through items
            for (let i = 0; i < items.length; i += 1) {
                // get author id
                const obj = items[i].object;
                // check if author id is in authorsIDs, if not add it
                if (!authorsIDs.includes(obj.AuthorID)) {
                    authorsIDs.push(obj.AuthorID);
                }
            }

            const ids = authorsIDs.map((id) => ({ ID: id }));
            const authorID = { IDS: ids };
            const data = JSON.stringify(authorID);

            this.$store
                .dispatch('listUsersByIDs', { ids: data })
                .then((response) => {
                    if (response.Users) {
                        let authors = response.Users;

                        authors = authors.map((owner) => {
                            // add owner to item
                            const tmp: User = {
                                id: owner.ID,
                                slug: owner.Slug,
                                name: `${owner.Profile.FirstName} ${owner.Profile.LastName}`,
                                image: owner.Profile.ImageURL,
                            };

                            if (!owner.Profile.FirstName) {
                                tmp.name = owner.Profile.Name;
                            }
                            return tmp;
                        });

                        this.authors = authors.reduce((acc, cur) => {
                            acc[cur.id] = acc[cur.id] || [];
                            acc[cur.id].push(cur);
                            return acc;
                        }, {});
                        this.mapMarketItemsStructure();
                    }
                })
                .catch(() => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        createItem() {
            this.$router.push('/submit-job');
        },

        getItemsForCircles() {
            this.loading = true;
            const url = '/market-item/list';
            const data = {
                Params: this.nextParams,
                Desc: false,
                Limit: 100,
            };
            axios({
                method: 'POST',
                url,
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Objects && response.data.Objects.length > 0) {
                        this.feed = [...this.feed, ...response.data.Objects];

                        if (response.data.NextParams) {
                            this.nextParams = response.data.NextParams;
                        } else {
                            this.nextParams = null;
                        }
                        this.getAuthors(this.feed);
                    } else {
                        this.nextParams = null;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getItems() {
            this.loading = true;
            let url = '/job/list';
            if (this.next) {
                url = this.next;
            }
            axios({
                method: 'GET',
                url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.objects) {
                        this.feed = [...this.feed, ...response.data.objects];

                        if (response.data.next && response.data.next !== '') {
                            const paramsString = response.data.next.match(/\?\S+/gi)
                                ? response.data.next.match(/\?\S+/gi)[0]
                                : null;
                            const urlParams = new URLSearchParams(paramsString);

                            if (
                                urlParams.get('id') &&
                                urlParams.get('id').length > 0 &&
                                urlParams.get('createdAt') &&
                                urlParams.get('createdAt').length > 0
                            ) {
                                // get next page as there is more content
                                this.next = response.data.next;
                            } else {
                                // prevent from loading the same content again
                                this.next = null;
                            }
                        } else {
                            this.next = null;
                        }
                        this.getAuthors(this.feed);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 10) {
                    if (this.nextParams != null && this.nextParams.length > 0 && !this.loading) {
                        this.loading = true;
                        this.getItemsForCircles();
                    }
                }
            };
        },
    },
};
