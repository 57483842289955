<template>
    <div>
        <EventView
            v-if="$unleash.isEnabled('frontend.neweventview')"
            :me="$store.state.me"
            :slug="$route.params.slug"
        />
        <ViewEvent v-else />
    </div>
</template>

<script>
import ViewEvent from './ViewEvent.vue';
import EventView from '../../components/Templates/User/Event/View/EventView.vue';

export default {
    name: 'ViewEventWrapper',
    components: {
        ViewEvent,
        EventView,
    },
};
</script>
