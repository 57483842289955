<template>
    <div class="">
        <!-- header start -->
        <CoHeadline :level="2" class="p-3">{{ $t('labels.timepass') }}</CoHeadline>

        <!-- header end -->

        <div class="d-flex flex-wrap p-4 pr-0 pr-lg-5 align-items-center justify-content-between mb-2 w-100">
            <span class="text-muted pl-4 mb-3">My wallet</span>
            <div class="d-flex flex-column pl-md-2 mb-4 w-100">
                <div class="row m-0 p-0 w-100">
                    <div class="row w-100" v-for="timepass in wallet" :key="timepass.ID">
                        <TimepassCardWallet
                            v-if="
                                activeTimepass &&
                                activeTimepass.WalletItemID &&
                                timepass.ID === activeTimepass.WalletItemID
                            "
                            :admin="false"
                            :active="true"
                            :timepass="timepass"
                        ></TimepassCardWallet>
                        <TimepassCardWallet
                            v-else
                            :disabled="activeTimepass && activeTimepass.WalletItemID ? true : false"
                            :admin="false"
                            :active="false"
                            :timepass="timepass"
                            @updatetimepass="updateWallet()"
                        ></TimepassCardWallet>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-alert
                v-if="timepassesSpace && timepassesSpace.length !== 0 && (!wallet || wallet.length === 0)"
                show
                variant="danger"
                >No time passes in your wallet
            </b-alert>
        </div>

        <div
            v-if="activeTimepass && activeTimepass.WalletItemID"
            class="d-flex flex-wrap p-4 pr-0 pr-lg-5 align-items-center justify-content-between mb-2 w-100"
        >
            <div class="d-flex flex-column pl-md-2 mt-3 mb-4 w-100">
                <span class="grey-text pl-md-2 ml-2">Active timepass</span>
                <div class="row m-0 p-0 mt-1">
                    <div class="col-12 p-0">
                        <table class="w-100 p-0 m-0 mt-4 resource-table">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50%">Name</th>
                                    <th scope="col" style="width: 25%">Applied at</th>
                                    <th scope="col" style="width: 25%">Expiration at</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ activeTimepass.WalletItem.Name }}</td>
                                    <td>{{ activeTimepass.appliedTime }}</td>
                                    <td>{{ activeTimepass.ValidUntillTime }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-alert class="col-4" show variant="warning">No active time pass</b-alert>
        </div>

        <div
            v-if="timepassesSpace && timepassesSpace.length != 0"
            class="d-flex flex-wrap p-4 pr-0 pr-lg-5 align-items-center justify-content-between mb-2 w-100"
        >
            <div class="d-flex flex-column pl-md-2 mt-3 mb-4 w-100">
                <span class="grey-text pl-md-2 ml-2">Further available time passes</span>
                <div class="row m-0 p-0 mt-1">
                    <div
                        class="col-12 m-0 p-0 pr-4 mt-2"
                        v-for="(timepass, index) in timepassesSpace"
                        :key="timepass.ID"
                    >
                        <TimepassCard
                            @checkPayment="checkPaymentMethods(timepass.ID)"
                            @refreshWallet="updateWallet()"
                            :timepass="timepass"
                            :active="false"
                            :isAdminSpace="false"
                            :user="me"
                        ></TimepassCard>
                        <div v-if="errorMsg && index == 0" class="d-flex align-items-center pt-4">
                            <b-alert show variant="danger">{{ errorMsg }}</b-alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-alert class="col-4" show variant="danger">No time passes available in your space</b-alert>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import TimepassCard from '@/components/cards/TimepassCard.vue';
import TimepassCardWallet from '@/components/cards/TimepassCardWallet.vue';
import { format, fromUnixTime } from 'date-fns';
import EventBus from '../../../eventBus';

export default {
    name: 'TimepassOverview',
    components: { TimepassCardWallet, TimepassCard },
    data() {
        return {
            me: this.$store.state.me,
            paymentMethods: [],
            errorMsg: '',
            isAdmin: false,
            loading: false,
            wallet: [],
            timepassesSpace: [],
            activeTimepass: {},
        };
    },
    mounted() {
        this.getTimePassesWallet();
        this.getTimepassesSpace();
        this.getActiveTimepass();
    },
    methods: {
        getTimePassesWallet() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/time-pass/wallet`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.wallet = response.data.WalletItems;
                    } else {
                        this.wallet = [];
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.wallet = [];
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to get time passes from wallet',
                        Details: '',
                    });
                });
        },
        getTimepassesSpace() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/space/time-passes/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Passes.forEach((element) => {
                            this.timepassesSpace.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        updateWallet() {
            this.getTimePassesWallet();
            this.getActiveTimepass();
        },

        checkPaymentMethods(ID) {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods && this.paymentMethods.length === 0) {
                        this.$router.push(`/account/time-passes/buy/${ID}`);
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    this.$router.push(`/account/time-passes/buy/${ID}`);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before buying time passes";
                    }
                });
        },
        getActiveTimepass() {
            axios({
                method: 'GET',
                url: '/user/time-pass/acitve',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.activeTimepass = response.data;
                        this.activeTimepass.appliedTime = format(
                            fromUnixTime(this.activeTimepass.Timestamp),
                            'dd.MM.yyyy HH:mm'
                        );
                        this.activeTimepass.ValidUntillTime = format(
                            fromUnixTime(this.activeTimepass.ValidUntil),
                            'dd.MM.yyyy HH:mm'
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
