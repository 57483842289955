





























// replaces sidebar.vue to implement updated page grid
import UserMainGrid from '../../Templates/User/MainGrid/UserMainGrid.vue';

import axios from 'axios';

import CoButton from '@/components/Atoms/co-button/CoButton.vue';

import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoMenuItem from '@/components/Molecules/co-menu-item/CoMenuItem.vue';
import CoActiveMembersWidget from '../../components/Organisms/co-active-members-widget/CoActiveMembersWidget.vue';
import CoUpcomingEventsWidget from '../../components/Organisms/co-upcoming-events-widget/CoUpcomingEventsWidget.vue';
import CoLeftMainNavigation from '@/components/Organisms/co-left-main-navigation/CoLeftMainNavigation.vue';
import EventBus from '../../../eventBus';

import { get } from 'lodash';

export default {
    name: 'UserMain',
    components: {
        UserMainGrid,
        CoText,
        CoHeadline,
        CoModal,
        CoButton,
        CoMenuItem,
        CoLeftMainNavigation,
    },
    data() {
        return {
            asAdmin: get(this.$store, 'state.me.Permissions', []).includes('space_admin'),
            isCirclesOn: get(this.$store, 'state.circlesOn', null),
            spaceID: get(this.$store, 'state.space.ID', null),
            space: get(this.$store, 'state.space', {}),
            isInMobile: get(this.$store, 'state.isInMobile', false),
            metaGridOptions: {},
        };
    },
    watch: {
        //get route meta options for custom rendering of the main grid
        $route(to, from) {
            this.metaGridOptions = get(to, 'meta.mainGridOptions', {});
        },
    },
    mounted() {
        this.metaGridOptions = get(this.$route, 'meta.mainGridOptions', {});
    },
};
