





















































































































































































































































import axios from 'axios';
import EventBus from '@/eventBus';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'vue-i18n';
import { get, uniq, cloneDeep } from 'lodash';
import { User, Image, Space, Event, Page, MarketItem } from '@/types/GlobalTypes.ts';
import linkifyHtml from 'linkifyjs/html';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoCheckbox from '../../Atoms/co-checkbox/CoCheckbox.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoDate from '../../Molecules/co-date/CoDate.vue';
import CoComments from '../co-comments/coComments.vue';
import CoContentPreview from '../co-content-preview/CoContentPreview.vue';
import CoLinkPreview from '../co-link-preview/CoLinkPreview.vue';
import CoDropdown from '../../Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../../Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoImage from '../../Atoms/co-image/CoImage.vue';
import CoSlider from '../../Molecules/co-slider/CoSlider.vue';
import CoReactions from '../../Molecules/co-reactions/coReactions.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoConfirmation from '../../Molecules/co-confirmation/CoConfirmation.vue';

interface ContentFetch {
    Fetch: {
        ID: string;
        Type: string;
        Slug: string;
    };
}

interface Content {
    ID: string;
    Type: string;
    ImageURL: string;
    Title: string;
    Subtitle?: string;
    Label?: string;
    Starttime?: string;
    Endtime?: string;
}

interface Post {
    ID: string;
    Type: string;
    AuthorID?: string;
    Author: User;

    Text: string;
    Slug: string;
    CreatedAt: string;
    UpdatedAt: string;

    AnnouncedBy?: string;
    SentAnnouncement?: boolean;

    Images?: [Image];

    SpaceID?: string;
    Space?: Space;

    ParentContent?: ContentFetch | Event | Page | MarketItem;
    Parent?: ContentFetch | Event | Page | MarketItem;

    FeaturedContent?: ContentFetch | Event | Page | MarketItem;
    Featured?: ContentFetch | Event | Page | MarketItem;
}

export default {
    i18n: {
        messages: {
            en: {
                announce: 'Announce this post',
                announcementwarning:
                    'This will send an email notification to every community member. Are you sure you want to send this?',
                announcementsend: 'Send announcement',
                announced: 'Post has been announced',
                announcedby: 'Announced by {name}',
                forceannouncementwarning:
                    'If checked, the announcement will be sent to all community members regardless of their notification preferences.',
                forceannouncement: 'Ignore notification preferences',
            },
        },
    },
    name: 'CoPost',
    components: {
        CoCard,
        CoThumbnail,
        CoLink,
        CoText,
        CoDate,
        CoComments,
        CoContentPreview,
        CoLinkPreview,
        CoDropdown,
        CoDropdownItem,
        CoIcon,
        CoImage,
        CoSlider,
        CoReactions,
        CoSkeleton,
        CoConfirmation,
        CoCheckbox,
    },
    props: {
        post: {
            type: Object as () => Post,
            default: () => ({}),
        },
        me: {
            type: Object as () => User,
        },
        loading: {
            // if true, the post is in loading state and displays as skeleton
            type: Boolean,
            default: false,
        },
        updated: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            linkInventory: [],
            rerender: 0,
            isAdmin: !!(
                get(this.$store, 'state.me.Permissions') &&
                get(this.$store, 'state.me.Permissions').includes('space_admin')
            ),
            deleted: false,
            thisSpaceID: get(this.$store, 'state.space.ID', ''),

            forceAnnouncing: false,
        };
    },
    watch: {
        post: {
            handler() {
                this.updateLinkInventory();
                if (this.updated) {
                    this.$emit('wasupdated');
                }
            },
            deep: true,
        },
    },
    computed: {
        isMySpace() {
            return get(this.$store, 'state.space.ID', '') === get(this, 'post.SpaceID');
        },
        postURL() {
            if (this.post.Slug) {
                return `${window.location.origin}/${this.post.Type}/${this.post.Slug}`;
            }
            return `${window.location.origin}/${this.post.Type}/${this.post.ID}`;
        },
        canEdit() {
            if (!get(this, '$store.state.me.ID') || !get(this, 'post.Author.ID')) return false;
            return this.$store.state.me.ID === this.post.Author.ID;
        },
        hasImages() {
            return get(this.post, 'Images', []).length > 0;
        },
        links() {
            const links = [];
            // eslint-disable-next-line no-restricted-syntax
            for (let item of this.linkInventory) {
                item = new URL(item);
                if (
                    item.host &&
                    item.host === get(this, '$store.state.space.DomainName') &&
                    !this.post.FeaturedContent
                ) {
                    const slug = item.pathname.split('/').pop();
                    const type = item.pathname.split('/')[1];

                    this.post.FeaturedContent = {
                        Fetch: {
                            Slug: item.href,
                            Link: item.href,
                        },
                    };
                } else if (item.href !== get(this, 'post.FeaturedContent.Fetch.Link')) {
                    links.push(item.href);
                }
            }
            this.rerender += 1;

            return uniq(links);
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.updateLinkInventory();
            if (this.updated) this.$emit('wasupdated');
        });
    },
    methods: {
        get,
        cloneDeep,
        // returns height for the slider based on width of container to keep aspect ratio 16:9
        sliderHeight() {
            if (!this.$refs.slider_container) return '486px';
            return `${((this.$refs.slider_container as HTMLElement).clientWidth * 9) / 16}px`;
        },
        updateLinkInventory() {
            // reset variables
            this.linkInventory = [];
            this.post.FeaturedContent = null;
            // linkify the post content, important for backwards compatibility
            const input = get(this.post, 'Text', '');
            if (!input) return;

            this.post.Text = linkifyHtml(input, {
                nl2br: true, // optional
                className: 'link',
                defaultProtocol: 'https',
                ignoreTags: ['a', 'pre', 'code', 'span'],
                validate: {
                    mention(value) {
                        return false;
                    },
                },
            });

            const doc = new DOMParser().parseFromString(this.post.Text, 'text/html');
            if (!doc) return;
            // get all links in the post content
            for (const item of doc.querySelectorAll('a')) {
                item['href'] ? this.linkInventory.push(item['href']) : null;
            }
        },
        copyLink() {
            EventBus.$emit('INFO', {
                Message: this.$t('messages.copiedtoclipboard'),
            });
            navigator.clipboard.writeText(this.postURL);
        },
        postDelete(asAdmin = false) {
            if (this.post.Type === 'project-update') {
                this.pageUpdateDelete(asAdmin);
                return;
            }

            const delPost = this.post;
            // remove images from delPost, so backend doesn't break because of different object structure
            delete delPost.Images;
            axios({
                method: 'DELETE',
                url: asAdmin ? '/admin/post' : '/post',
                data: delPost,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.deleted = true;
                    this.$emit('postDeleted', this.post);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to delete the post',
                    });
                    console.error(error);
                });
        },

        pageUpdateDelete(asAdmin = false) {
            const delPost = this.post;
            // remove images from delPost, so backend doesn't break because of different object structure
            delete delPost.Images;
            delete delPost.AnnouncedBy;
            axios({
                method: 'DELETE',
                url: asAdmin ? '/admin/project-update' : '/project/update',
                data: delPost,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.deleted = true;
                    this.$emit('postDeleted', this.post);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to delete the page update',
                    });
                    console.error(error);
                });
        },
        sendAnouncement() {
            let url = `/admin/community/post/send-announcement/${this.post.ID}`;
            // add support for page updates
            if (this.post.Type === 'project-update') {
                url = `/admin/community/project/update/send-announcement/${this.post.ID}`;
            }

            if (this.forceAnnouncing) {
                url += '?force=true';
            }

            axios({
                method: 'POST',
                url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Announcement was successfuly sent',
                        Details: null,
                    });
                    this.post = {
                        ...this.post,
                        SentAnnouncement: true,
                        AnnouncedBy: get(this.$store, 'state.me.Name', ''),
                    };
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'An error occured. The announcement was not sent',
                        Details: null,
                    });
                    console.log(error);
                });
        },
    },
};
