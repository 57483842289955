






































































































































import axios from 'axios';
import i18n from 'vue-i18n';
import { get } from 'lodash';
import { User } from '@/types/GlobalTypes.ts';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoPeople from '@/components/Molecules/co-people/CoPeople.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';

export default {
    name: 'ViewPage',
    components: {
        CoThumbnail,
        CoText,
        CoRoundButton,
        CoButton,
        CoIcon,
        CoHeadline,
        CoSkeleton,
        CoPeople,
        CoCard,
        CoLink,
    },
    props: {
        pageSlug: {
            type: String,
            required: true,
        },
        channelSlug: {
            type: String,
            required: true,
        },
        me: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            page: {},
            followers: [],
            owners: [],
        };
    },
    watch: {
        pageSlug() {
            this.getPage();
        },
        page: {
            handler() {
                this.getPageFollowers();
            },
            deep: true,
        },
    },
    computed: {
        amIOwner(): boolean {
            // check if page.Owners contains user.ID
            return this.page.Owner && this.page.Owner.includes(this.me.ID);
        },
        amIContributor(): boolean {
            // check if page.Contributor contains user.ID
            return this.page.Contributor && this.page.Contributor.includes(this.me.ID);
        },
        amIAdmin(): boolean {
            // check if me.Permissions contain space_admin
            return this.me.Permissions && this.me.Permissions.includes('space_admin');
        },
    },
    created() {
        this.getPage();
    },
    methods: {
        get,
        getPage() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/project/by-slug/${this.pageSlug}`,
            })
                .then((response) => {
                    if (response.data) this.page = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getPageFollowers() {
            this.loading = true;

            if (!this.page.FollowedBy || !this.page.FollowedBy.length) {
                this.loading = false;
                return;
            }

            // map list of follower ids from page.FollowedBy to {IDS: [{ID: 1}, {ID: 2}]} to send as payload
            const ids = this.page.FollowedBy.map((id) => ({ ID: id }));
            if (!ids.length) {
                this.loading = false;
                return;
            }

            const requestBody = { IDS: ids };
            axios({
                method: 'POST',
                url: `/user/listbyids`,
                data: requestBody,
            })
                .then((response) => {
                    if (response.data && response.data.Users) {
                        this.owners = response.data.Users;
                        // map response data to User object
                        this.followers = response.data.Users.map((item) => ({
                            ID: item.ID,
                            Name: get(item, 'Profile.Name', ''),
                            Slug: item.Slug,
                            ImageURL: item.ImageURL,
                        }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
