<template>
    <b-overlay :show="deleting" spinner-variant="primary" rounded="sm">
        <div v-if="loaded" class="px-2">
            <div class="d-lg-none">
                <b-aspect class="d-lg-none" :aspect="2">
                    <div class="position-relative h-100">
                        <img
                            class="event-header-image round-unify position-absolute w-100 d-block img-fluid h-100"
                            :src="filterPictures(event.ImageURL)"
                            img-blank-color="#ffffff"
                            alt
                        />
                    </div>
                </b-aspect>
            </div>
            <div class="container">
                <div class="d-none d-lg-block">
                    <b-aspect :aspect="4">
                        <div class="position-relative h-100">
                            <img
                                class="event-header-image round-unify position-absolute w-100 d-block img-fluid h-100"
                                :src="filterPictures(event.ImageURL)"
                                img-blank-color="#ffffff"
                                alt
                            />
                        </div>
                    </b-aspect>
                </div>
                <nav class>
                    <div class="row mt-4 mb-2 mb-lg-4">
                        <div class="col-12 col-sm">
                            <h1>{{ event.Title }}</h1>
                        </div>
                        <div class="order-2 order-lg-0 col col-auto mt-1 mb-2 d-flex align-items-center">
                            <b-button
                                v-if="IAmOwner"
                                @click="edit(event.Slug)"
                                variant="outline-primary"
                                class="btn-round mt-1 mt-lg-0"
                                alt="Edit"
                            >
                                <b-icon
                                    icon="pencil"
                                    class="m-0"
                                    style="position: relative; bottom: 1px"
                                    scale="1"
                                ></b-icon>
                            </b-button>
                            <b-button
                                v-if="!IAmOwner && isAdmin"
                                v-b-modal="'deleteEventModal'"
                                variant="outline-danger"
                                class="btn-round mt-1 mt-lg-0"
                                alt="Delete"
                            >
                                <b-icon
                                    icon="trash"
                                    class="m-0"
                                    style="position: relative; bottom: 1px"
                                    scale="1"
                                ></b-icon>
                            </b-button>

                            <b-popover
                                popover-class="popover-no-margin"
                                target="calendar"
                                ref="calendarPopover"
                                triggers="manual"
                                class="p-0"
                            >
                                Add this event to your calendar!
                            </b-popover>

                            <b-dropdown variant="ml-1 px-2" menu-class="mt-0 border-1" no-caret right style="">
                                <template v-slot:button-content>
                                    <CoRoundButton icon="calendar2-check" variant="outline" />
                                </template>
                                <b-dropdown-item :href="this.googleCalendarLink" target="_blank">
                                    Google Calendar
                                </b-dropdown-item>
                                <b-dropdown-item :href="this.outlookCalendarLink" target="_blank"
                                    >Outlook Calendar
                                </b-dropdown-item>
                                <b-dropdown-item :href="this.icsCalendarLink" target="_blank"
                                    >ICS Calendar file
                                </b-dropdown-item>
                            </b-dropdown>

                            <b-button
                                v-if="isTheParticipantGoing"
                                variant="outline-primary"
                                @click="updateParticipantattendance"
                                class="d-flex align-items-center"
                            >
                                <span> {{ $t('labels.going') }} </span>
                                <b-icon icon="caret-down-fill" scale=".7" class="m-0 ml-1"></b-icon>
                            </b-button>

                            <b-button
                                v-else
                                variant="primary"
                                @click="updateParticipantattendance"
                                class="d-flex align-items-center"
                            >
                                <span>{{ $t('labels.join') }}</span>
                            </b-button>
                        </div>
                    </div>
                </nav>

                <div class="row">
                    <div class="col-np-mobile col-12 col-lg-8 wrap">
                        <b-tabs justified v-model="tabIndex" nav-class="d-flex justify-content-center">
                            <b-tab>
                                <template #title>
                                    <span v-if="isDesktop">Event</span>
                                    <b-icon v-else icon="calendar-event"></b-icon>
                                </template>
                                <b-card>
                                    <h3 v-if="!isDesktop" class="mb-4">Event</h3>
                                    <div class="d-lg-none">
                                        <h3 class="">{{ $t('labels.eventtime') }}</h3>
                                        <h2 class="mt-1">
                                            <coDate :date="event.StartDate" />
                                            <span> - </span>
                                            <coDate v-if="!isMultiDay" :date="event.EndDate" format="time" />
                                            <coDate v-else :date="event.EndDate" />
                                        </h2>

                                        <h3 v-if="this.event.Location" class="mt-4">{{ $t('labels.location') }}</h3>
                                        <h2 v-if="this.event.Location" class="mt-1">
                                            {{ this.event.Location }}
                                        </h2>
                                        <div v-if="event.FullURL">
                                            <h3 class="mt-4">{{ $t('labels.link') }}</h3>
                                            <LinkPreview :url="event.FullURL" class="mt-1 mb-4"></LinkPreview>
                                        </div>
                                    </div>
                                    <div
                                        v-if="event"
                                        v-html="event.Description"
                                        v-linkifytiptap
                                        style="white-space: pre-wrap"
                                    ></div>
                                    <div class="d-lg-none">
                                        <h3 class="mt-4" v-if="event.tags">
                                            {{
                                                this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                                    ? $t('labels.topics')
                                                    : $t('labels.tags')
                                            }}
                                        </h3>
                                        <div>
                                            <span
                                                v-for="(tag, index) in event.Tags"
                                                :key="index"
                                                class="color-2-link mr-2 mb-2"
                                                @click.stop="$router.push('/search?term=' + tag)"
                                                >#{{ tag }}
                                            </span>
                                        </div>
                                    </div>
                                </b-card>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <span v-if="isDesktop">{{ $t('labels.comments') }} ({{ commentCount }})</span>
                                    <b-icon v-else icon="chat-square-dots"></b-icon>
                                </template>
                                <b-card>
                                    <h3 v-if="!isDesktop" class="mb-4">
                                        {{ $t('labels.comments') }} ({{ commentCount }})
                                    </h3>
                                    <CommentSection
                                        v-if="this.event"
                                        post-type="event"
                                        :PostID="this.event.ID"
                                        :profileDisplayed="this.$store.state.me.Profile"
                                        :ShowComments="true"
                                        :highlightCommentID="$route.query.commentid"
                                        @comment-count-update="commentCount = $event"
                                        @show-comments="showComments = true"
                                    >
                                    </CommentSection>
                                </b-card>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <span v-if="isDesktop"
                                        >{{ $t('labels.attendees') }} ({{ numberOfParticipants }})</span
                                    >
                                    <b-icon v-else icon="person-check"></b-icon>
                                </template>
                                <b-card v-if="isThereParticipants">
                                    <h3 v-if="!isDesktop" class="mb-4">
                                        {{ $t('labels.attendees') }} ({{ numberOfParticipants }})
                                    </h3>
                                    <div
                                        v-for="participant in participants"
                                        :key="participant.ID"
                                        class="d-flex align-items-center mb-2 pointer rounded p-2"
                                        @click="$router.push(`/profile/${participant.Slug}`)"
                                    >
                                        <CoThumbnail
                                            :Link="`/profile/${participant.Slug}`"
                                            :ImageURL="participant.Profile.ImageURL"
                                            :Round="true"
                                            content-type="user"
                                            class="mr-2"
                                        />
                                        <CoHeadline :level="2" :trunc="true">
                                            {{ participant.Profile.Name }}
                                        </CoHeadline>
                                    </div>
                                </b-card>
                                <b-card class="text-center" v-else>
                                    <illustration type="community" class="my-0 illu-small"></illustration>
                                    <div class="mb-4">{{ $t('noattendees') }}</div>
                                </b-card>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <span v-if="isDesktop"
                                        >{{ $t('labels.organizers') }} ({{ organizers.length }})</span
                                    >
                                    <b-icon v-else icon="people"></b-icon>
                                </template>
                                <b-card v-if="isThereOrganizers">
                                    <h3 v-if="!isDesktop" class="mb-4">
                                        {{ $t('labels.organizers') }} ({{ organizers.length }})
                                    </h3>
                                    <div
                                        v-for="organizer in organizers"
                                        style="background: white"
                                        class="d-flex align-items-center mb-2 pointer rounded p-2"
                                        :key="organizer.ID"
                                        @click="$router.push(`/profile/${organizer.Slug}`)"
                                    >
                                        <CoThumbnail
                                            :Link="`/profile/${organizer.Slug}`"
                                            :ImageURL="organizer.Profile.ImageURL"
                                            :Round="true"
                                            content-type="user"
                                            class="mr-2"
                                        />
                                        <CoHeadline :level="2" :trunc="true">
                                            {{ organizer.Profile.Name }}
                                        </CoHeadline>
                                    </div>
                                </b-card>
                                <b-card class="text-center" v-else>
                                    <illustration type="community" class="my-0 illu-small"></illustration>
                                    <div class="mb-4">{{ $t('noorganizers') }}</div>
                                </b-card>
                            </b-tab>
                        </b-tabs>
                    </div>

                    <div class="d-none d-lg-block col-np-mobile col-12 col-lg-4 mb-3">
                        <b-card :header="$t('eventabout')" header-class="d-none d-lg-block">
                            <div class="mb-4" v-if="isThereParticipants">
                                <h3>{{ $t('labels.attendees') }}</h3>
                                <div class="d-flex align-items-center">
                                    <div
                                        class="avatars ml-3"
                                        v-bind:style="{
                                            'flex-direction': participants.length > 1 ? 'row-reverse' : '',
                                        }"
                                    >
                                        <div
                                            class="d-flex"
                                            v-for="participant in participants.length > 5
                                                ? participants.slice(0, 5)
                                                : participants"
                                        >
                                            <div class="small-icon multi-icons">
                                                <CoThumbnail
                                                    :ImageURL="participant.Profile.ImageURL"
                                                    :Round="true"
                                                    :Clickable="true"
                                                    :Link="`/profile/${participant.Slug}`"
                                                    content-type="user"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <span v-if="numberOfParticipants > 5">
                                            + {{ $tc('labels.morecount', numberOfParticipants - 5) }}
                                        </span>
                                    </div>
                                    <a
                                        class="ml-auto text-primary flex-shrink-0 text-nowrap"
                                        href="#"
                                        @click="tabIndex = 2"
                                        >{{ $t('labels.seeall') }}</a
                                    >
                                </div>
                            </div>

                            <div class="mb-4" v-if="isThereOrganizers || (!isThereOrganizers && isAdmin)">
                                <h3>{{ $t('labels.organizers') }}</h3>
                                <ul class="hfv-project-users-list">
                                    <transition-group name="slide-fade">
                                        <li v-for="org in organizers" :key="org.ID" class="hfv-project-users-list-item">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <ProfileCircle
                                                    :ImageURL="org.Profile.ImageURL"
                                                    :Slug="org.Slug"
                                                    :Name="org.Profile.Name"
                                                >
                                                </ProfileCircle>
                                                <span v-if="isAdmin" v-on:click="removeOrganizer(org.ID)">
                                                    <b-icon font-scale="1.2" icon="person-x"></b-icon>
                                                </span>
                                            </div>
                                        </li>
                                    </transition-group>
                                </ul>
                                <div v-if="isAdmin" class="hfv-project-users-list-item">
                                    <b-dropdown
                                        variant="outline-primary btn-round"
                                        class=""
                                        menu-class="round-unify-xs  mt-1 p-3 w-100"
                                        block
                                        no-caret
                                        no-flip
                                        @shown="
                                            $refs.organizersSearchInput.value = '';
                                            $refs.organizersSearchInput.focus();
                                        "
                                        id="add-owner-tooltip"
                                    >
                                        <template v-slot:button-content>
                                            <b-icon
                                                icon="person-plus"
                                                class="m-0"
                                                scale=".8"
                                                style="position: relative; bottom: 1px"
                                            >
                                            </b-icon>
                                        </template>

                                        <input
                                            type="text"
                                            ref="organizersSearchInput"
                                            @input="searchOrganizers"
                                            :placeholder="$t('placeholders.search')"
                                            class="form-control rounded-pill d-block"
                                        />
                                        <div class="user-select">
                                            <b-dropdown-item
                                                v-for="user in searchedOrganizers"
                                                @click="addOrganizer(user.ID)"
                                                :key="user.ID"
                                                link-class="p-0"
                                                class="my-2 round-unify-xs"
                                            >
                                                <ProfileCircle
                                                    :ImageURL="user.Profile.ImageURL"
                                                    :Name="user.Profile.Name"
                                                ></ProfileCircle>
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                    <b-tooltip target="add-owner-tooltip__BV_toggle_" bottom triggers="hover">
                                        {{ $t('labels.organizersadd') }}
                                    </b-tooltip>
                                </div>
                            </div>

                            <h3 class="">{{ $t('labels.eventtime') }}</h3>
                            <h2 class="mt-1">
                                <coDate :date="event.StartDate" />
                                <span> - </span>
                                <coDate v-if="!isMultiDay" :date="event.EndDate" format="time" />
                                <coDate v-else :date="event.EndDate" />
                            </h2>

                            <h3 v-if="this.event.Location" class="mt-4">{{ $t('labels.location') }}</h3>
                            <h2 v-if="this.event.Location" class="mt-1">
                                {{ this.event.Location }}
                            </h2>
                            <div v-if="event.FullURL">
                                <h3 class="mt-4">{{ $t('labels.link') }}</h3>
                                <LinkPreview :url="event.FullURL" class="mt-2"></LinkPreview>
                            </div>
                            <h3 class="mt-4" v-if="event.tags">
                                {{
                                    this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                        ? $t('labels.topics')
                                        : $t('labels.tags')
                                }}
                            </h3>
                            <div>
                                <span
                                    v-for="(tag, index) in event.Tags"
                                    :key="index"
                                    class="color-2-link mr-2 mb-2"
                                    @click.stop="$router.push('/search?term=' + tag)"
                                    >#{{ tag }}
                                </span>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
            <!-- remove-attendance-modal -->
            <b-modal
                id="remove-attendance-modal"
                size="md"
                :title="$t('cancelattendance')"
                @ok="removeParticipantattendance"
                :ok-title="$t('labels.confirm')"
                :cancel-title="$t('labels.cancel')"
                ok-variant="primary"
                centered
                :busy="deleting"
                no-fade
            >
                <b-overlay :show="deleting">
                    <p class="mb-1">
                        {{ $t('cancelmessage') }}
                    </p>
                </b-overlay>
            </b-modal>
            <b-modal
                v-if="isAdmin && !IAmOwner"
                id="deleteEventModal"
                ref="deleteEventModal"
                size="sm"
                :title="$t('labels.eventdelete')"
                centered
                @ok="deleteEventAdmin"
                :ok-title="$t('labels.delete')"
                :cancel-title="$t('labels.cancel')"
                ok-variant="primary"
                :busy="deleting"
            >
                <b-overlay :show="deleting">
                    <p class="mb-1">{{ $t('messages.deletewarning') }}</p>
                </b-overlay>
            </b-modal>
        </div>
    </b-overlay>
</template>

<style lang="less">
.nav-tabs .nav-item {
    overflow: hidden;

    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.multi-icons {
    margin-left: -11px;
}
</style>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.event-header-image {
    object-fit: cover;
}

.avatars {
    display: inline-flex;
}

.avatar {
    position: relative;
    overflow: hidden;
    width: 100px;
}

.avatar:not(:last-child) {
    margin-left: -40px;
}

.avatar img {
    width: 100%;
    display: block;
}

.dropdown-btn .dropdown-item {
    padding: 0.25rem 0.25rem !important;
}

.popover-no-margin .popover-content {
    padding: 0px;
    width: auto;
}
</style>

<script>
import axios from 'axios';
import Vue from 'vue';
import Router from 'vue-router';
import VueMoment from 'vue-moment';
import { locale } from 'moment';
import { PassThrough } from 'stream';
import linkify from 'vue-linkify';
import { BCarousel } from 'bootstrap-vue';
import { get } from 'lodash';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import i18n from 'vue-i18n';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoCardMember from '@/components/Molecules/co-card-member/CoCardMember.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';

const debounce = require('debounce');
const moment = require('moment');

Vue.component('BCarousel', BCarousel);

Vue.directive('linkified', linkify);

Vue.use(require('vue-moment'), {
    moment,
});

Vue.use(Router, VueMoment, {
    moment,
});

export default {
    components: { CoHeadline, CoText, CoCardMember, CoThumbnail },
    i18n: {
        messages: {
            en: {
                eventabout: 'About this event',
                noattendees: 'There are no @:labels.attendees for this @:(labels.event).',
                noorganizers: 'There are no @:labels.organizers for this @:(labels.event).',
                cancelattendance: 'Cancel attendance',
                cancelmessage: 'Are you sure about this? This event would be much nicer with you!',
            },
            de: {
                eventabout: 'Über dieses Event',
                noattendees: 'Es gibt noch keine @:labels.attendees für diese @:labels.event',
                noorganizers: 'Es gibt noch keine @:labels.organizers für diese @:labels.event',
                cancelattendance: 'Teilnahme absagen',
                cancelmessage:
                    'Bist du dir sicher, dass du das willst? Diese @:labels.event wäre viel schöner mit dir!',
            },
        },
    },
    name: 'ViewEvent',
    data() {
        return {
            isAdmin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),

            tabIndex: this.$route.query.commentid != null ? 1 : 0,
            event: {},
            calendarEvent: {},
            googleCalendarLink: '',
            icsCalendarLink: '',
            outlookCalendarLink: '',
            prevRoute: null,
            images: [],
            loaded: false,
            IAmOwner: false,
            currentYear: '',
            isTheParticipantGoing: false,
            participants: [],
            organizers: [],
            searchedOrganizers: [],
            deleting: false,
            windowWidth: 0,
            commentCount: 0,
        };
    },

    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    beforeCreate() {
        const { slug } = this.$route.params;
        this.$store
            .dispatch('getEventBySlug', slug)
            .then((response) => {
                this.$data.event = response;

                const start = new Date(this.event.StartDate * 1000);
                const end = new Date(this.event.EndDate * 1000);

                this.event.year = moment(start).format('YYYY');
                this.currentYear = moment().format('YYYY');

                this.event.DateStrings = {};

                this.event.DateStrings.StartDay = moment(start).format('DD.MM.YY');
                this.event.DateStrings.StartTime = moment(start).format('h:mm');

                this.event.DateStrings.EndDay = moment(end).format('DD.MM.YY');
                this.event.DateStrings.EndTime = moment(end).format('h:mm');

                this.updateParticipants(get(this.event, 'Participants', []));
                this.updateOrganizers(get(this.event, 'Organizers', []));
                this.loaded = true;
                if (this.event.Organizers && this.event.Organizers.includes(this.$store.state.me.ID)) {
                    this.IAmOwner = true;
                } else if (this.event.CreatedBy === this.$store.state.me.ID) {
                    this.IAmOwner = true;
                }

                if (this.event.SpaceID !== this.$store.state.me.SpaceID) {
                    this.isAdmin = false;
                }

                this.makeCalendarLink(this.event);
            })
            .catch((error) => {
                if (error.message.includes('404')) {
                    this.$router.replace('/feed/error404');
                }
            });
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
        this.windowWidth = window.innerWidth;
    },
    methods: {
        edit(slug) {
            this.$router.push(`/event/edit/${slug}`);
        },
        openUpdateTab() {
            this.tabIndex = 1;
        },
        removeAttendence() {},
        addAttendence() {},
        makeCalendarLink(event) {
            // create richtext version
            this.calendarEvent.richText = {
                title: event.Title,
                location: event.Location ? event.Location : null,
                description: event.Description,
                start: new Date(event.StartDate * 1000).toISOString(),
                end: new Date(event.EndDate * 1000).toISOString(),
            };
            // create plaintext version
            this.calendarEvent.plainText = {
                title: event.Title,
                location: event.Location ? event.Location : null,
                description: this.convertToPlain(event.Description),
                start: new Date(event.StartDate * 1000).toISOString(),
                end: new Date(event.EndDate * 1000).toISOString(),
            };

            // add event url to the description
            if (event.FullURL) {
                this.calendarEvent.richText.description += `<br><br>============<br><br><a href="${event.FullURL}" target="_blank">Click here to join this event</a>`;
                this.calendarEvent.plainText.description += ` | Click here to join this event: ${event.FullURL}`;
            }

            // Then fetch the link
            this.googleCalendarLink = google(this.calendarEvent.richText);
            this.outlookCalendarLink = outlook(this.calendarEvent.richText);
            this.icsCalendarLink = ics(this.calendarEvent.plainText);
        },
        convertToPlain(html) {
            const tempDivElement = document.createElement('div');
            tempDivElement.innerHTML = html;
            return tempDivElement.textContent || tempDivElement.innerText || '';
        },

        updateParticipantattendance() {
            if (this.isTheParticipantGoing) {
                this.$bvModal.show('remove-attendance-modal');
            } else {
                const that = this;
                that.$refs.calendarPopover.$emit('open');
                setTimeout(() => {
                    that.$refs.calendarPopover.$emit('close');
                }, 3500);
                const request = JSON.stringify({
                    EventID: this.event.ID,
                    ParticipantID: this.$store.state.me.ID,
                });
                this.$store.dispatch('addParticipant', request).then((response) => {
                    this.event = response;
                    this.updateParticipants(get(this.event, 'Participants', []));
                }, this);
            }
        },
        updateParticipants(userIds) {
            if (userIds.length > 0) {
                this.$store
                    .dispatch('listUsersByIDs', {
                        ids: { IDS: userIds.map((id) => ({ ID: id })) },
                    })
                    .then((response) => {
                        this.participants = response.Users;
                    });
                this.isTheParticipantGoing = userIds.filter((id) => id === this.$store.state.me.ID).length === 1;
            } else {
                this.isTheParticipantGoing = false;
                this.participants = [];
            }
        },
        updateOrganizers(userIds) {
            if (userIds.length > 0) {
                this.$store
                    .dispatch('listUsersByIDs', {
                        ids: { IDS: userIds.map((id) => ({ ID: id })) },
                    })
                    .then((response) => {
                        this.organizers = response.Users;
                    });
            } else {
                this.organizers = [];
            }
        },
        removeParticipantattendance() {
            const request = JSON.stringify({
                EventID: this.event.ID,
                ParticipantID: this.$store.state.me.ID,
            });
            this.deleting = true;
            this.$store
                .dispatch('removeParticipant', request)
                .then((response) => {
                    this.deleting = false;
                    this.event = response;
                    this.$bvModal.hide('remove-attendance-modal');
                    this.updateParticipants(get(this.event, 'Participants', []));
                }, this)
                .catch((error) => {
                    this.deleting = false;
                    this.$bvModal.hide('remove-attendance-modal');
                });
        },
        removeOrganizer(OrganizerID) {
            const request = JSON.stringify({
                EventID: this.event.ID,
                OrganizerID,
            });
            this.deleting = true;
            this.$store
                .dispatch('removeOrganizer', request)
                .then((response) => {
                    this.deleting = false;
                    this.event = response;
                    this.updateOrganizers(get(this.event, 'Organizers', []));
                }, this)
                .catch((error) => {
                    this.deleting = false;
                });
        },
        searchOrganizers(e) {
            if (e.target.value.length < 2) {
                this.searchedOrganizers = [];
                return;
            }
            this.$store
                .dispatch('searchUser', { input: e.target.value })
                .then((response) => {
                    this.searchedOrganizers = response.data.filter(
                        (us) => !get(this.event, 'Organizers', []).includes(us.ID)
                    );
                })
                .catch((error) => {});
        },
        addOrganizer(OrganizerID) {
            const request = JSON.stringify({
                EventID: this.event.ID,
                OrganizerID,
            });
            this.$store.dispatch('addOrganizer', request).then((response) => {
                this.event = response;
                this.searchedOrganizers = [];
                this.$refs.organizersSearchInput.value = '';
                this.updateOrganizers(get(this.event, 'Organizers', []));
            }, this);
        },
        deleteEventAdmin(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.deleting = true;
            const data = JSON.stringify(this.event);
            axios({
                method: 'DELETE',
                url: '/admin/event',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$router.push('/events');
                })
                .catch((error) => {
                    console.log(error);
                    this.deleting = false;
                    if (error && error.response && error.response.status == 404) {
                        EventBus.$emit('ERROR', {
                            Message:
                                'This event was already deleted. Refreshing the page will show correct event list.',
                            Details: '',
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Failed to delete event due to internal error. Please, contact support',
                            Details: '',
                        });
                    }
                });
        },
        filterPictures(imageurl) {
            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('../../assets/gradients/', true);
            if (!imageurl) {
                return images(`./Platform_Gradients-${randomItem}.jpg`);
            }
            return imageurl;
        },
        mapSpaceObject(spaceItem) {
            if (!spaceItem) {
                return null;
            }

            if (!spaceItem.ID) {
                return null;
            }

            if (spaceItem.ID === this.$store.state.space.ID) {
                return null;
            }
            const obj = {
                id: spaceItem.ID,
                name: spaceItem.Name,
                favicon: spaceItem.FaviconURL,
                primaryColor: spaceItem.PrimaryColor,
            };
            return obj;
        },
    },
    computed: {
        joinEventText() {
            return this.isTheParticipantGoing ? 'Cancel' : 'Join this event';
        },
        numberOfParticipants() {
            const total = this.participants.length;
            return total > 99 ? '99+' : `${total}`;
        },
        isThereParticipants() {
            return this.participants.length > 0;
        },
        isThereOrganizers() {
            return this.organizers.length > 0;
        },
        isDesktop() {
            const is = this.windowWidth >= 786;
            return is;
        },
        isMultiDay() {
            if (
                this.$t('datetime', { date: this.event.StartDate, format: 'date' }) !==
                this.$t('datetime', { date: this.event.EndDate, format: 'date' })
            ) {
                return true;
            }
            return false;
        },
    },
};
</script>
