<template>
    <div class="row m-0 px-2 px-md-3">
        <div class="col-12 col-md-3 col-lg-3 px-2 py-3" v-if="navigationVisibleOnMobile || isDesktop">
            <CoMenuItem
                to="/account"
                icon="person"
                :label="$t('labels.general')"
                @click="navigationVisibleOnMobile = false"
            />

            <CoMenuItem
                v-if="plans && $store.state.billingTurnedOn && !$store.state.isInMobile"
                to="/account/plan"
                icon="view-stacked"
                :label="$t('labels.plan')"
                @click="navigationVisibleOnMobile = false"
            />

            <CoMenuItem
                v-if="this.$unleash.isEnabled('frontend.time-pass') && !$store.state.isInMobile"
                to="/account/time-passes"
                icon="clock"
                :label="$t('labels.timepass')"
                @click="navigationVisibleOnMobile = false"
            />

            <CoMenuItem
                v-if="$store.state.billingTurnedOn && !$store.state.isInMobile"
                to="/account/billing/invoices"
                icon="credit-card"
                :label="$t('labels.billing')"
                @click="navigationVisibleOnMobile = false"
            />

            <CoMenuItem
                to="/account/notifications"
                icon="bell"
                :label="$t('labels.notifications')"
                @click="navigationVisibleOnMobile = false"
            />

            <CoMenuItem
                to="/legal/terms-and-conditions"
                icon="file-text"
                :label="$t('labels.termsandconditions')"
                openInNewTab
                @click="navigationVisibleOnMobile = false"
            />
        </div>

        <div class="col-12 col-md-9 col-lg-6 p-0" v-if="!navigationVisibleOnMobile || isDesktop">
            <div class="d-flex align-items-center d-md-none px-2">
                <CoRoundButton icon="chevron-left" variant="secondary" @click="back" />
                <CoText type="p1" variant="mute" class="mb-0 ml-2">{{ $t('labels.back') }}</CoText>
            </div>
            <div class="pb-5">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoMenuItem from '@/components/Molecules/co-menu-item/CoMenuItem.vue';

export default {
    name: 'AccountBase',
    components: {
        CoHeadline,
        CoRoundButton,
        CoText,
        CoMenuItem,
    },
    data() {
        return {
            plans: null,
            navigationVisibleOnMobile: true,
            windowWidth: 0,

            billingTurnedOn: false,
        };
    },
    computed: {
        isDesktop() {
            return this.windowWidth >= 768;
        },
    },
    mounted() {
        this.getListPlans();

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
        this.windowWidth = window.innerWidth;

        // if path is not just /account hide the mobile navigation
        if (this.$route.path !== '/account' && this.$route.path !== '/account/') {
            this.navigationVisibleOnMobile = false;
        }
    },
    methods: {
        back() {
            this.navigationVisibleOnMobile = true;
        },
        goto(path) {
            this.navigationVisibleOnMobile = false;
            this.$router.push(path);
        },
        getListPlans() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/space/plan/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.plans = response;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
};

//  test for the account page
</script>
