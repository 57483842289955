<!-- Intro Modal -->
<template>
    <div>
        <b-modal
            id="introModal"
            size="lg"
            scrollable
            centered
            hide-header
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
        >
            <template v-slot:default style="min-height: 600px">
                <b-overlay :show="saving">
                    <div id="steps">
                        <div v-if="steps[1] && step === 1">
                            <div class="step-description">
                                <h2>{{ $t(steps[1].Title) }}</h2>
                                <p>{{ $t(steps[1].Subtitle) }}</p>
                            </div>
                            <div class="step-image">
                                <img
                                    class="w-100"
                                    v-if="steps[1].ImageURL"
                                    :src="steps[1].ImageURL"
                                    alt="Welcome to the Community!"
                                />
                                <illustration v-else type="projects" class="my-0 illu"></illustration>
                            </div>
                        </div>
                        <div v-if="steps[2] && step === 2">
                            <div class="step-description">
                                <h2>{{ $t(steps[2].Title) }}</h2>
                                <p>{{ $t(steps[2].Subtitle) }}</p>
                            </div>
                            <div class="step-image overflow-hidden d-flex justify-content-center align-items-center">
                                <div class="profile-image">
                                    <img v-if="newImageURL" :src="newImageURL" alt="" />
                                    <img
                                        v-else-if="userObj && userObj.Profile && userObj.Profile.ImageURL"
                                        :src="userObj.Profile.ImageURL"
                                        alt=""
                                    />
                                    <div v-else class="user-img"></div>
                                    <div class="file text-center float-r text-light">
                                        <span>{{ $t('labels.imageadd') }}</span>
                                        <input type="file" name="file" @change="previewImage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="steps[3] && step === 3">
                            <div class="step-description">
                                <h2>{{ $t(steps[3].Title) }}</h2>
                                <p>{{ $t(steps[3].Subtitle) }}</p>
                            </div>
                            <div class="step-image">
                                <div class="form-group">
                                    <b-form-group
                                        id="input-group-featured"
                                        label-for="Featured"
                                        description="Your profile will be featured on hafven.de website. Please complete your profile before making it visible."
                                    >
                                        <label class="label-align">Public profile</label>

                                        <b-form-checkbox
                                            v-model="userObj.Profile.Featured"
                                            id="Featured"
                                            name=""
                                            switch
                                        >
                                            Feature my profile publicly on the Hafven website
                                        </b-form-checkbox>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div v-if="steps[4] && step === 4">
                            <div class="step-description">
                                <h2>{{ $t(steps[4].Title) }}</h2>
                                <p>{{ $t(steps[4].Subtitle) }}</p>
                            </div>
                            <div class="step-image">
                                <coUserTagsEdit
                                    ref="UserTagsEdit"
                                    :user="userObj"
                                    @saved="tagsSaved"
                                    @failed="tagsSaveFailed"
                                ></coUserTagsEdit>
                            </div>
                        </div>

                        <div v-if="steps[5] && step === 5">
                            <div class="step-description">
                                <h2>{{ $t(steps[5].Title) }}</h2>
                                <p>{{ $t(steps[5].Subtitle) }}</p>
                            </div>
                            <div class="step-image">
                                <coUserBioEdit
                                    :user="userObj"
                                    :placeholder="
                                        steps[5]['Placeholder'] ? steps[5]['Placeholder'] : $t('labels.aboutme')
                                    "
                                    :rows="5"
                                    :label="$t('labels.aboutme')"
                                    ref="UserBioEdit"
                                    @saved="bioSaved"
                                    @failed="bioSaveFailed"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="d-flex w-100 align-items-center justify-content-between mt-4">
                        <div class="">
                            <b-button v-b-modal.close-modal variant="outline-primary">{{
                                $t('labels.close')
                            }}</b-button>
                        </div>
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button
                                v-if="step > 1 && step < 5"
                                @click="goToNextStep"
                                variant="outline-primary"
                                class="mr-3"
                                >{{ $t('labels.skip') }}
                            </b-button>
                            <b-button @click="next" :disabled="saving" variant="primary">
                                <span>{{ actionTitle }}</span>
                            </b-button>
                        </div>
                    </div>
                </b-overlay>
            </template>
        </b-modal>
        <b-modal
            id="close-modal"
            size="sm"
            :title="$t('finishintro')"
            @ok="close"
            centered
            @cancel="closeForever"
            :ok-title="$t('labels.yes')"
            ok-variant="primary"
            :cancel-title="$t('labels.no')"
        >
            <p class="mb-1">
                {{ $t('introcontinue') }}
            </p>
        </b-modal>
    </div>
</template>
<script>
import EventBus from '@/eventBus';
import Vue from 'vue';
import VueIntercom from 'vue-intercom';
import coUserBioEdit from '../Organisms/co-UserBioEdit/CoUserBioEdit.vue';
import i18n from 'vue-i18n';

Vue.use(VueIntercom, { appId: 'kttwojyk' });

export default {
    i18n: {
        messages: {
            en: {
                finishintro: 'Finish intro?',
                introcontinue: "You didn't finish the intro. Do you want to finish it the next time you log in again?",
                "Let's set up your profile.": "Let's set up your profile.", //subtitle title step 1
                "Hi, nice that you're here!": "Hi, nice that you're here!", //title step 1
                'Show yourself to the community.': 'Show yourself to the community.', // subtitle step 2
                'Upload a picture of you.': 'Upload a picture of you.', // title step 2
                'What are topics you like to talk about?': 'What are topics you like to talk about?', // title step 4
                'Tell the community who you are.': 'Tell the community who you are.', // title step 5
            },
            de: {
                finishintro: 'Intro beenden?',
                introcontinue:
                    'Du hast das Intro nicht beendet. Willst du es beim nächsten Mal beenden, wenn du dich wieder einloggst?',
                "Let's set up your profile.": 'Lass uns dein Profil einrichten.', //subtitle title step 1
                "Hi, nice that you're here!": 'Hi, wie schön, dass du hier bist!', //title step 1
                'Show yourself to the community.': 'Zeige dich der Community.', // subtitle step 2
                'Upload a picture of you.': 'Lade ein Bild von dir hoch', // title step 2
                'What are topics you like to talk about?': 'Was sind Themen, die dich interessieren?', // title step 4
                'Tell the community who you are.': 'Sage der Community wer du bist.', // title step 5
            },
        },
    },
    name: 'IntroModal',
    props: {
        intro: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        coUserBioEdit,
    },
    data() {
        return {
            saving: false,
            changes: true,
            steps: {},
            userObj: {},
            step: 1,

            // step 2
            newImageURL: '',
            newImageFile: null,
        };
    },
    watch: {
        userObj(newValue, oldValue) {
            if (
                newValue &&
                newValue.ID &&
                newValue.Profile &&
                (newValue.Profile.Name || newValue.Profile.FirstName) &&
                !newValue.Intro
            ) {
                if (this.$route.name !== 'Booking') {
                    this.$bvModal.show('introModal');
                }
            }
        },
    },
    created() {
        this.getMe();
        // this.userObj = this.user;/
        this.intro.forEach((element, index) => {
            // TODO: Check if this line is necessary because it produces an error
            this.steps[element.Step] = element;
        });
    },
    computed: {
        actionTitle() {
            switch (this.step) {
                case 1:
                    return this.$t('labels.next');
                case 2:
                    return this.$t('labels.save');
                case 3:
                    return this.$t('labels.save');
                case 4:
                    return this.$t('labels.save');
                case 5:
                    return this.$t('labels.finish');
                default:
                    return this.$t('labels.next');
            }
        },
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },
    methods: {
        initIntercom() {
            if (this.userObj.Permissions && this.userObj.Permissions.includes('space_admin')) {
                this.$intercom.boot({
                    user_id: this.userObj.ID,
                    name: this.userObj.Profile.Name,
                    email: this.userObj.Email,
                    spaceID: this.$store.state.space.ID,
                    space: this.$store.state.space.Name,
                    intro: this.userObj.Intro,
                    hide_default_launcher: this.$isMobile || this.$store.state.isInMobile, // hide if Vue.prototype.$isMobile flag is set
                });
                this.$intercom.hide();
            }
        },
        previewImage(event) {
            const file = event.target.files[0];
            this.newImageURL = URL.createObjectURL(file);
            this.newImageFile = file;
            this.changes = true;
        },

        updateProfileImage() {
            if (!this.newImageFile) {
                this.goToNextStep();
                return;
            }
            this.saving = true;
            this.$store
                .dispatch('imageUpload', {
                    file: this.newImageFile,
                    url: '/upload/image/user-profile-image',
                })
                .then((response) => {
                    this.userObj.Profile.ImageURL = response;
                    this.$store
                        .dispatch('updateUser', this.userObj)
                        .then(() => {
                            // todo delete previuos file
                            this.newImageURL = null;
                            this.newImageFile = null;
                            const message = {
                                Message: this.$t('labels.profileupdated'),
                                Details: '',
                                Source: 'Profile Update',
                            };
                            EventBus.$emit('INFO', message);
                            this.goToNextStep();
                        })
                        .catch((error) => {
                            const message = {
                                Message: 'Could not update profile.',
                                Details: error,
                            };
                            EventBus.$emit('ERROR', message);
                        })
                        .finally(() => {
                            this.saving = false;
                        });
                })
                .catch((error) => {
                    this.url = null;
                    const message = {
                        Message: 'Could not upload',
                        Details: error.response.data,
                    };
                    if (error.status && error.status === 413) {
                        message.Message = 'The image file is too large to upload. Must be max. 10MB.';
                    } else if (error.status && error.status === 424) {
                        message.Message = 'Could not upload. Problem with dependency service. Please try again later.';
                    } else if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        message.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        updateVisability() {
            this.saving = true;
            this.$store
                .dispatch('updateUser', this.userObj)
                .then(() => {
                    this.saving = false;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.goToNextStep();
                    this.saving = false;
                });
        },

        updateTags() {
            this.saving = true;
            const tagsEdit = this.$refs.UserTagsEdit;
            if (tagsEdit) {
                tagsEdit.saveChanges();
            }
        },

        tagsSaved(tags) {
            this.saving = false;
            this.userObj.Profile.Tags = tags;
            this.goToNextStep();
        },
        tagsSaveFailed() {
            this.saving = false;
        },

        saveBIO() {
            this.saving = true;
            const bioEdit = this.$refs.UserBioEdit;
            if (bioEdit) {
                bioEdit.saveChanges();
            }
        },

        bioSaved(bio) {
            this.saving = false;
            this.userObj.Profile.Interests = bio;
            this.closeForever();
        },

        bioSaveFailed(bio) {
            this.saving = false;
            this.userObj.Profile.Interests = bio;
            this.closeForever();
        },

        getMe() {
            this.saving = true;
            this.$store
                .dispatch('getMe', null)
                .then((response) => {
                    this.userObj = response.data;
                    this.saving = false;
                    this.initIntercom();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.saving = false;
                });
        },

        inputChanged() {
            this.changes = true;
        },
        emitEventToIntercom(event) {
            if (this.userObj.Permissions && this.userObj.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent(`intro-modal-${event}`);
            }
        },

        prev() {
            this.$refs.carouselIntro.prev();
        },
        next() {
            switch (this.step) {
                case 1:
                    this.goToNextStep();
                    break;
                case 2:
                    this.updateProfileImage();
                    break;
                case 3:
                    this.updateVisability();
                    break;
                case 4:
                    this.updateTags();
                    break;
                case 5:
                    this.saveBIO();
                    break;
                default:
                    break;
            }
        },

        goToNextStep() {
            let nextStep = this.step + 1;

            while (!this.steps[nextStep] && nextStep < 5) {
                nextStep += 1;
            }
            this.step = nextStep;
        },
        close() {
            this.$bvModal.hide('introModal');
            this.$emit('close');
        },
        closeForever() {
            if (this.userObj) {
                this.userObj.Intro = true;
                this.$store
                    .dispatch('updateUser', this.userObj)
                    .then(() => {})
                    .catch((error) => {
                        console.log(error);
                    });
            }
            this.$bvModal.hide('introModal');
            this.$emit('close');
            EventBus.$emit('REFRESH_COMMUNITY');
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.profile-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.profile-image img,
.profile-image .user-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    image-orientation: from-image;
}

.profile-image .file {
    border: none;
    font-size: 15px;
    line-height: 200px;
    background: rgba(0, 0, 0, 0.2);
    width: 200px;
    position: absolute;
    padding: 0 !important;
    text-align: center;
    left: 0;
    top: 0;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    display: block;
    transition: background 0.3s ease-in-out;
}

.profile-image .loader {
    position: absolute;
    left: 85px;
    top: 85px;
    border-radius: 50%;
    display: block;
    cursor: pointer;
    color: rgb(255, 71, 19);
}

.profile-image:hover > .file {
    background: rgba(0, 0, 0, 0.5);
}

.profile-image .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.step-description {
    min-height: 100px;
    // text-align: center;
    overflow: hidden;
}

.step-image {
    // overflow: hidden;
    min-height: 250px;

    @media (min-width: 768px) {
        min-height: 400px;
    }

    img {
        height: 250px;

        @media (min-width: 768px) {
            height: 400px;
        }

        width: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 7px;
    }
}

.tips {
    p {
        font-size: 18px;
        line-height: 2;
    }

    .menu-icon {
        margin: 0px 10px;
        // display: inline-block;
        // border: 1px solid @purple;
        // border-radius: 50%;
        // width: 20px;
        // height: 20px;
        // padding: 2px;
    }
}

.modal-content {
    height: 50%;
}

@media (max-width: 575.98px) {
    .modal-content {
        height: 70%;
    }
}
</style>
