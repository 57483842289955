<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <vue-headful
            :title="'Login -' + this.$store.state.space.Name + 'Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />
        <!-- user login -->
        <div class="login-form">
            <div class="mt-4 mt-md-0 p-4 bg-white round-unify text-left">
                <div class="text-center">
                    <img
                        v-if="this.$store.state.space.LogoURL"
                        :src="this.$store.state.space.LogoURL"
                        width="145"
                        height="auto"
                        alt="Community Logo"
                        class="my-2"
                    />
                    <div v-else class="my-2">
                        <h2>{{ this.$store.state.space.Name }}</h2>
                    </div>
                </div>
                <h2 class="my-1 text-center">Change password</h2>

                <div class="w-100 py-4 my-3">
                    <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                        <p class="mb-0">Link is invalid or expired</p>
                    </div>

                    <b-form @submit.prevent="setPassword" v-if="step == 1">
                        <b-form-group id="input-group-2">
                            <div class="mb-1">
                                <span class="label-align">Password</span
                                ><span class="ml-2 clickable" @click="switchPasswordView"
                                    ><b-icon variant="primary" :icon="passwortViewIcon"></b-icon
                                ></span>
                            </div>
                            <b-form-input
                                id="input-2"
                                v-model="password"
                                :type="passwortInputType"
                                class="form-control rounded-pill"
                                required
                                :placeholder="this.$t('placeholders.password')"
                                :disabled="loading ? true : false"
                                :formatter="checkLength"
                            ></b-form-input>
                        </b-form-group>
                        <div class="text-right">
                            <input
                                :disabled="loading ? true : false"
                                :value="loading ? '... logging in' : 'Change password'"
                                class="btn btn-primary"
                                type="submit"
                                id="login-btn"
                            />
                        </div>
                    </b-form>
                    <div v-else>
                        <p>New password set successfully.</p>
                    </div>
                </div>
                <div class="mb-4 mt-0">
                    <h3 class="my-1">
                        <a href="/login" class="text-primary" title="Sign Up">Back to Login</a>
                    </h3>
                </div>
            </div>
        </div>
        <!-- user login -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

// login view
.login-form {
    width: 100%;
    max-width: 400px;
}

input[type='text'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

.bg {
    background-color: #fff;
    @media (min-width: 768px) {
        background-color: #dedfde;
    }
}

.hfv-red-btn {
    margin-top: 35px;
}

.d-block {
    margin-left: 0.8em !important;
    font-weight: 600 !important;
    color: black !important;
    font-size: 18px !important;
}

.card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
</style>

<script>
import Axios from 'axios';
import Vuei18n from 'vue-i18n';
import EventBus from '@/eventBus';
import { debounce } from 'lodash';

export default {
    i18n: {
        messages: {
            en: {
                messages: {
                    passwordlength: 'Password can not be longer than 56 characters',
                },
            },
            de: {
                messages: {
                    passwordlength: 'Passwort darf nicht länger als 56 Zeichen sein',
                },
            },
        },
    },
    data() {
        return {
            code: this.$route.params.code,
            password: null,
            error: null,
            loading: false,
            passwortInputType: 'password',
            passwortViewIcon: 'eye-slash',
            step: 1,
        };
    },

    created() {
        this.debouncedCheckLength = debounce(this.notifyPasswordLength, 500);
    },

    methods: {
        switchPasswordView() {
            if (this.passwortInputType === 'password') {
                this.passwortInputType = 'text';
                this.passwortViewIcon = 'eye';
            } else {
                this.passwortInputType = 'password';
                this.passwortViewIcon = 'eye-slash';
            }
        },

        setPassword() {
            this.loading = true;
            this.error = null;
            const data = JSON.stringify({
                Password: this.password,
                OTP: this.code,
            });
            Axios({
                method: 'POST',
                url: '/user/set-new-password',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.step = 2;
                })
                .catch((error) => {
                    this.error = error;
                    this.loading = false;
                    console.log(error);
                });
        },
        notifyPasswordLength() {
            EventBus.$emit(`ERROR`, {
                Message: this.$t('messages.passwordlength'),
            });
        },
        checkLength(value) {
            if (value.length > 56) {
                this.debouncedCheckLength();
                return value.substring(0, 56);
            }
            return value;
        },
    },
};
</script>
