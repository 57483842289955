<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.user {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
}

.user img,
.user .user-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    image-orientation: from-image;
}

.user .file {
    border: none;
    font-size: 15px;
    line-height: 200px;
    background: rgba(0, 0, 0, 0.2);
    width: 200px;
    position: absolute;
    padding: 0 !important;
    text-align: center;
    left: 0;
    top: 0;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    transition: background 0.3s ease-in-out;
}

.user .loader {
    position: absolute;
    left: 85px;
    top: 85px;
    border-radius: 50%;
    display: block;
    cursor: pointer;
    color: rgb(255, 71, 19);
}

.user:hover > .file {
    background: rgba(0, 0, 0, 0.5);
}

.user .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.modal {
    display: block;
}

.description {
    border-color: rgb(225, 225, 225) !important;
}

.overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #00000094;
    z-index: 999;
    transition: opacity 0.2s ease;
}
</style>

<template>
    <b-overlay :show="showOverlay && !hideButton">
        <div v-if="!hidePicture" class="row w-100 justify-content-center my-4">
            <div class="user">
                <img v-if="url" :src="url" alt="" />
                <img v-else-if="form.Profile.ImageURL" :src="form.Profile.ImageURL" alt="" />
                <div v-else class="user-img" />
                <div class="file text-center float-r text-light">
                    <span>{{ $t('labels.imageadd') }}</span>
                    <input type="file" name="file" @change="preview" />
                </div>
            </div>
        </div>
        <div
            v-if="
                !hideVisibility &&
                this.$store.state.space.ID === 'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5'
            "
            class="form-group my-4"
        >
            <b-form-group
                id="input-group-featured"
                label-for="Featured"
                description="Your profile will be featured on hafven.de website. Please complete your profile before making it visible."
            >
                <label class="label-align">{{ $t('labels.profilepublish') }}</label>

                <b-form-checkbox
                    v-model="form['Profile']['Featured']"
                    @change="changedInput()"
                    id="Featured"
                    name=""
                    switch
                >
                    {{ $t('infopublish') }}
                </b-form-checkbox>
                <b-popover target="input-group-featured" triggers="hover" placement="lefttop">
                    <template v-slot:title>Profile almost ready</template>
                    Make sure to fill all fields and upload your best photo before you make the profile visible! ;)
                </b-popover>
            </b-form-group>
        </div>

        <div v-if="introField" class="form-group my-4">
            <b-form-group
                id="input-group-headline"
                :label="$t('labels.headline')"
                label-for="input-headline"
                aria-describedby="headline-description"
                label-class="label-align"
            >
                <b-form-input
                    id="input-name"
                    v-model="form.Profile.Bio"
                    type="text"
                    :placeholder="$t('placeholders.optional')"
                    class="rounded-pill"
                ></b-form-input>
                <b-form-text id="headline-description" class="ml-3">{{ $t('infoheadline') }}</b-form-text>
            </b-form-group>
            <b-form-group
                id="input-group-company"
                :label="$t('labels.company')"
                label-for="input-company"
                label-class="label-align"
            >
                <b-form-input
                    id="input-name"
                    v-model="form.Profile.Company"
                    type="text"
                    :placeholder="$t('placeholders.optional')"
                    class="rounded-pill"
                ></b-form-input>
            </b-form-group>
        </div>

        <div v-if="!hideBio" class="form-group my-4">
            <label for="Interests" class="label-align">{{ $t('labels.aboutme') }}</label>
            <textarea
                @change="changedInput()"
                v-model="form.Profile['Interests']"
                min-rows="5"
                max-rows="10"
                name="Interests"
                class="w-100 p-2 hfv-textarea"
                :placeholder="$t('infoaboutme')"
            >
            </textarea>
        </div>
        <div v-if="!hideTags" class="form-group my-4">
            <label for="tags" class="label-align">{{ $t('labels.letstalkabout') }}</label
            ><br />
            <div>
                <vue-tags-input
                    id="tags"
                    class="mt-0 border"
                    v-model="tag"
                    :tags="tags"
                    :placeholder="$t('placeholders.tags')"
                    :separators="separators"
                    :autocomplete-items="autocompleteItems"
                    :add-only-from-autocomplete="false"
                    @tags-changed="updateTags"
                    @before-adding-tag="addTag"
                />
            </div>
            <small v-if="this.$store.state.space['StrongTags']"
                >{{ $t('infotags', { strongtags: this.$store.state.space['StrongTags'] }) }}
            </small>
        </div>
        <div class="d-flex justify-content-end">
            <b-button v-if="!hideButton" class="my-4" variant="primary" @click="saveChanges">{{
                $t('labels.save')
            }}</b-button>
        </div>
    </b-overlay>
</template>

<script>
import EventBus from '@/eventBus';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                infoaboutme: 'Example: "I like talking about the big questions in life. Also: Yoga and great food!"',
                infoheadline: 'Will be displayed under your name on posts and comments. Keep it short.',
                infopublish: 'Feature my profile publicly on the Hafven website',
                infotags: 'Examples for strong tags: {strongtags}',
            },
            de: {
                infoaboutme:
                    'Beispiel: "Ich unterhalte mich gerne über die großen Fragen des Lebens. Außerdem: Yoga und gutes Essen!"',
                infoheadline: 'Wird unter deinem Namen in allen Posts und Kommentaren angezeigt. Halte dich kurz.',
                infopublish: 'Veröffentliche mein Profil auf der Hafven-Website',
                infotags: 'Beispiel für starke Tags: {strongtags}',
            },
        },
    },
    name: 'ProfileEdit',
    components: {
        VueTagsInput,
    },
    data() {
        return {
            description: '',
            form: {},
            url: null,
            file: null,
            showOverlay: false,

            tag: '',
            tags: [],
            autocompleteItems: [],
            debounce: null,
            separators: [';', ',', '#', ', #'],
        };
    },
    props: {
        placeholder: null,
        inIntro: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
        },
        hidePicture: {
            type: Boolean,
            default: false,
        },
        hideVisibility: {
            type: Boolean,
            default: false,
        },
        hideBio: {
            type: Boolean,
            default: false,
        },
        introField: {
            type: Boolean,
            default: false,
        },
        hideTags: {
            type: Boolean,
            default: false,
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        /** isProfileCompelete
         *  completenessLevel  of isProfileCompelete calculated for Featuring on hafven.de,
         *  its for 16 percent lower, while `Featured` does not count
         */
        isProfileComplete() {
            let level = 36;
            if (
                this.$store.state.me.Profile.ImageURL &&
                this.$store.state.me.Profile.ImageURL != '' &&
                this.$store.state.me.Profile.ImageURL !=
                    'https://s3.eu-central-1.amazonaws.com/hafven-community-app-uploads/uploads/avatars/-medium.jpg'
            ) {
                level += 16;
            }

            if (this.$store.state.me.Profile.Interests && this.$store.state.me.Profile.Interests != '') {
                level += 16;
            }
            if (this.$store.state.me.Profile.Tags && this.$store.state.me.Profile.Tags.length != 0) {
                level += 16;
            }
            if (this.$store.state.me.Profile.Published && this.$store.state.me.Profile.Published) {
                level += 16;
            }
            if (level == 100) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.form = this.user;
        this.loadTags();
    },
    mounted() {},
    watch: {
        tag: 'loadAutocompleteItems',
    },
    methods: {
        changedInput() {
            this.$emit('input-changed');
        },
        addTag(obj) {
            console.log('obj', obj);
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tag = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tag);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },

        updateTags(newTags) {
            this.changedInput();
            this.autocompleteItems = [];
            this.tags = newTags;
        },
        loadAutocompleteItems() {
            this.autocompleteItems = [];
            if (this.tag.length < 2) return;
            this.$store
                .dispatch('searchTag', this.tag)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Slug,
                            slug: entry.Slug,
                        };
                        this.autocompleteItems.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },

        preview(event) {
            this.changedInput();
            const file = event.target.files[0];
            this.url = URL.createObjectURL(file);
            this.file = file;
        },

        loadTags() {
            if (this.form.Profile.Tags == null) {
                if (
                    this.inIntro &&
                    this.$store.state.space.PreFilledTags &&
                    this.$store.state.space.PreFilledTags.length != 0
                ) {
                    const preFillTags = this.$store.state.space.PreFilledTags;
                    preFillTags.forEach(function (entry) {
                        const tag = {
                            text: entry,
                            slug: entry,
                        };
                        this.tags.push(tag);
                    }, this);
                    return true;
                }
                return false;
            }
            const newArray = this.form.Profile.Tags.filter((el) => el != '');
            const IDs = JSON.stringify({ IDs: newArray });
            this.$store
                .dispatch('getTagsByIDs', IDs)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Value,
                            slug: entry.Slug,
                        };
                        this.tags.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
            return false;
        },

        saveChanges() {
            this.showOverlay = true;

            const hashtags = [];
            this.tags.forEach((entry) => {
                hashtags.push(entry.slug);
            }, hashtags);
            this.form.Profile.Tags = hashtags;
            if (this.file) {
                this.$store
                    .dispatch('imageUpload', {
                        file: this.file,
                        url: '/upload/image/user-profile-image',
                    })
                    .then((response) => {
                        this.form.Profile.ImageURL = response;
                        this.$store
                            .dispatch('updateUser', this.form)
                            .then((response) => {
                                // todo delete previuos file
                                this.url = null;
                                const message = {
                                    Message: this.$t('labels.profileupdated'),
                                    Details: '',
                                    Source: 'Profile Update',
                                };
                                EventBus.$emit('INFO', message);
                                this.$emit('saved');
                            })
                            .catch((error) => {
                                const message = {
                                    Message: 'Could not update profile.',
                                    Details: error,
                                };
                                EventBus.$emit('ERROR', message);
                                this.url = null;
                            });
                    })
                    .catch((error) => {
                        this.url = null;
                        const message = {
                            Message: 'Could not upload',
                            Details: error.response.data,
                        };
                        if (error.status && error.status === 413) {
                            message.Message = 'The image file is too large to upload. Must be max. 2MB.';
                        } else if (error.status && error.status === 424) {
                            message.Message = 'Could not upload. Problem with dependency service.';
                        } else if (
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'Request Entity Too Large'
                        ) {
                            message.Message = 'The file you tried to upload is too large. Max 10 MB.';
                        }
                        EventBus.$emit('ERROR', message);
                    });
            } else {
                this.$store
                    .dispatch('updateUser', this.form)
                    .then((response) => {
                        // todo delete previuos file
                        this.url = null;
                        const message = {
                            Message: this.$t('labels.profileupdated'),
                            Details: '',
                            Source: 'Profile Update',
                        };
                        EventBus.$emit('INFO', message);
                        this.$emit('saved');
                    })
                    .catch((error) => {
                        const message = {
                            Message: `Could update profile. Error: ${error.response.data}`,
                            Details: error.response.data,
                        };
                        EventBus.$emit('ERROR', message);
                        this.url = null;
                    });
            }
        },
    },
};
</script>
