


























import axios from 'axios';
import { User } from '@/components/Molecules/co-card-member/models.ts';
import CoCardMember from '../../components/Molecules/co-card-member/CoCardMember.vue';
import CoCard from '../../components/Molecules/co-card/CoCard.vue';

export default {
    name: 'CommunityMembers',
    components: { CoCardMember, CoCard },
    data() {
        return {
            feed: [],
            feedNextPage: null,
            loading: true,
            nextParams: null,

            isCirclesOn: this.$store.state.circlesOn,
        };
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();
        if (this.isCirclesOn) {
            this.nextParams = null;
            this.getCommunityForCircle();
        } else {
            this.feedNextPage = null;
            this.getCommunity();
        }
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        isDesktop() {
            return window.innerWidth >= 768;
        },
    },
    methods: {
        getCommunityForCircle() {
            this.loading = true;
            const data = {
                Params: this.nextParams,
            };

            axios({
                method: 'POST',
                url: '/user/profile/list/circles',
                data: JSON.stringify(data),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Objects) {
                        let objs = response.data.Objects;
                        // sort objs by Score
                        objs = objs.sort((a, b) => b.object.LastActivity - a.object.LastActivity);

                        // map feed items to Page type
                        const tmp = objs.map((item) => {
                            const u: User = {
                                id: item.object.ID,
                                name: item.object.Profile.Name,
                                headline: item.object.Profile.Bio,
                                image: item.object.Profile.ImageURL,
                                slug: item.object.Slug,
                            };

                            if (item && item.space && item.space.ID && item.space.ID !== this.$store.state.space.ID) {
                                u.space = {
                                    id: item.space.ID,
                                    name: item.space.Name,
                                    favicon: item.space.FaviconURL,
                                    primaryColor: item.space.PrimaryColor,
                                };
                            }
                            return u;
                        });

                        this.feed = [...this.feed, ...tmp];

                        if (response.data.NextParams) {
                            this.nextParams = response.data.NextParams;
                        } else {
                            this.nextParams = null;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getCommunity() {
            this.loading = true;
            let url = '/user/profile/list';
            if (this.feedNextPage) {
                url = this.feedNextPage;
            }
            axios({
                method: 'GET',
                url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.feedNextPage = response.data.next ? response.data.next : (this.feedNextPage = null);
                    if (!response.data.objects) return false;
                    // map feed items to Page type
                    response.data.objects.map((item) => {
                        const u: User = {
                            id: item.object.ID,
                            name: item.object.Profile.Name,
                            headline: item.object.Profile.Bio,
                            image: item.object.Profile.ImageURL,
                            slug: item.object.Slug,
                        };

                        if (item && item.space && item.space.ID) {
                            u.space = {
                                id: item.space.ID,
                                name: item.space.Name,
                                favicon: item.space.FaviconURL,
                                primaryColor: item.space.PrimaryColor,
                            };
                        }
                        this.feed.push(u);
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 1) {
                    if (this.isCirclesOn && this.nextParams != null && !this.loading) {
                        this.getCommunityForCircle();
                    } else if (this.feedNextPage != null && !this.loading) {
                        this.getCommunity();
                    }
                }
            };
        },
        sendMessage(userObj) {
            if (!userObj || !userObj.id) return;
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.id],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$router.push(`/messenger/${response.data.ID}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
