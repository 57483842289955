<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.navbar.navbar-light.bg-gray {
    background-color: @co-lightgrey !important;
}
</style>
<template>
    <div>
        <!-- top bar -->
        <b-navbar variant="gray" type="light" fixed="top">
            <b-navbar-brand tag="h1" class="mb-0" to="/">
                <img
                    v-if="this.$store.state.space.LogoURL"
                    :src="this.$store.state.space.LogoURL"
                    height="36px"
                    alt="logo"
                />
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto d-none d-sm-flex align-content-center">
                <b-nav-item>
                    <b-nav-text class="text-dark">{{ $t('messages.accountexists') }}</b-nav-text>
                </b-nav-item>
                <b-nav-item>
                    <b-button to="/login" variant="outline-primary">{{ $t('labels.login') }}</b-button>
                </b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav
                class="list-unstyled ml-auto d-flex d-sm-none p-0"
                v-if="this.$store.state.newMembership.Plan"
            >
                <b-nav-item class="align-self-right" link-classes="p-0">
                    <b-button variant="outline-primary btn-round" class="align-content-center" v-b-modal.cart-modal>
                        <b-icon icon="cart3" class="m-0" scale="1" style="position: relative; bottom: 2px"></b-icon>
                    </b-button>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>

        <!-- content section -->
        <div class="row m-0 d-flex justify-content-center align-items-center" style="height: 100vh">
            <!-- plan upgrades -->
            <div v-if="step == 1" class="col-12 col-xs-7 col-md-6 col-lg-6" style="height: 70vh">
                <Upgrades></Upgrades>
            </div>

            <!-- user account -->
            <div v-if="step == 2" class="col-12 col-xs-7 col-md-6 col-lg-6" style="height: 70vh">
                <Account></Account>
            </div>

            <!-- billing address -->
            <div v-if="step == 3" class="col-12 col-xs-7 col-md-6 col-lg-6" style="height: 70vh">
                <BillingAddress></BillingAddress>
            </div>

            <!-- billing  -->
            <div v-if="step == 4" class="col-12 col-xs-7 col-md-6 col-lg-6" style="height: 70vh">
                <PaymentCredentials></PaymentCredentials>
            </div>

            <!-- Review  -->
            <div v-if="step == 5" class="col-12 col-xs-7 col-md-6 col-lg-6" style="height: 70vh">
                <Review></Review>
            </div>

            <!-- membership state -->
            <div
                class="d-none d-md-block col-md-5 col-lg-4"
                style="height: 70vh"
                v-if="this.$store.state.newMembership.Plan"
            >
                <Membership></Membership>
            </div>
        </div>

        <b-modal id="cart-modal" hide-footer>
            <Membership></Membership>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'Register',
    data() {
        return {
            planID: null,
        };
    },
    computed: {
        step() {
            // step: 3, // 0 plan upgrades, 1 account, 2 billing address
            return this.$store.state.newMembership.Step;
        },
    },
    watch: {
        step(newStep) {
            if (newStep === 0) {
                this.$router.push('/plans');
            }
        },
    },
    created() {
        const now = Math.round(new Date().getTime() / 1000);
        if (now >= this.$store.state.newMembership.ExpriresAt) {
            this.$store.commit('RESET_NEW_MEMBERSHIP');
            this.$router.push('/plans');
        }

        if (this.step === 0) {
            this.$router.push('/plans');
        }

        if (
            this.$store.state.newMembership &&
            this.$store.state.newMembership.Plan &&
            (!this.$store.state.newMembership.Plan.Extras || this.$store.state.newMembership.Plan.Extras.length === 0)
        ) {
            this.$store.commit('SET_EXTRAS', []);
        }
    },
    methods: {},
};
</script>
