import { format as formatter, setDefaultOptions, formatRelative, formatDistanceToNowStrict } from 'date-fns';

// find a list of community maintained date-fns locales
// https://github.com/date-fns/date-fns/blob/9bb51691f201c3ec05ab832acbc5d478f2e5c47a/docs/i18nLocales.md
import { enGB, enUS, de } from 'date-fns/locale';

//customize the locale for formatRelative to show the time as well
//enUS = default
const enRelative = {
    lastWeek: "'last' eeee 'at' p",
    yesterday: "'yesterday at' p",
    today: "'today at' p",
    tomorrow: "'tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: 'Pp', // Difference: Add time to the date
};
// relative date without time
const enRelativeDate = {
    lastWeek: "'last' eeee",
    yesterday: "'yesterday",
    today: "'today",
    tomorrow: "'tomorrow",
    nextWeek: 'eeee',
    other: 'P', // Difference: Add time to the date
};
//de
const deRelative = {
    lastWeek: "'letzten' eeee 'um' p",
    yesterday: "'gestern um' p",
    today: "'heute um' p",
    tomorrow: "'morgen um' p",
    nextWeek: "eeee 'um' p",
    other: 'Pp', // Difference: Add time to the date
};
// relative date without time
const deRelativeDate = {
    lastWeek: "'letzten' eeee",
    yesterday: "'gestern",
    today: "'heute",
    tomorrow: "'morgen",
    nextWeek: 'eeee',
    other: 'P',
};

// always use lower case keys for locale variants like 'en-us'!
const locales = {
    de: {
        datetime: {
            ...de,
            formatRelative: (token) => deRelative[token],
        },
        date: {
            ...de,
            formatRelative: (token) => deRelativeDate[token],
        },
    },
    en: {
        datetime: {
            ...enGB,
            formatRelative: (token) => enRelative[token],
        },
        date: {
            ...enGB,
            formatRelative: (token) => enRelativeDate[token],
        },
    },
    'en-us': {
        datetime: {
            ...enUS,
            formatRelative: (token) => enRelative[token],
        },
        date: {
            ...enUS,
            formatRelative: (token) => enRelativeDate[token],
        },
    },
};

const formattedDate = function (date: number | string | Date, format: string, locale: string, customFormat: string) {
    /*
        converts parsable date Strings and Numbers to locale date/time string
        - 10-digit numbers will be interpreted as epoch time and will be multiplied by 1000 before
        converting them into Date type
        - unparsable input will return an empty String '' and console warning
    */
    //check if date is defined
    if (!date) return '';

    //set the locale and fallback behaviour
    if (!locale) locale = '';
    locale = locale.toLocaleLowerCase();
    let localeObj;

    if (locales[locale]) {
        localeObj = locales[locale];
    } else if (locales[locale.substring(0, 2)]) {
        localeObj = locales[locale.substring(0, 2)];
    } else {
        localeObj = locales.en; // use en as default locale
    }

    setDefaultOptions({ locale: localeObj.datetime });
    //parse 'date' input to Date type
    let dt: Date;

    if (date instanceof Date) dt = date;

    switch (typeof date) {
        case 'string':
            if (Date.parse(date)) {
                dt = new Date(date);
                break;
            } else if (parseInt(date)) {
                date = parseInt(date);
            } else {
                dt = new Date(date);
                break;
            }
        case 'number':
            date.toString().length === 10 ? (dt = new Date(date * 1000)) : (dt = new Date(date));
            break;
    }

    try {
        // for a list of date-fns formats (i.e. 'Pp') please see docs here
        // https://date-fns.org/v2.30.0/docs/format
        if (customFormat) {
            return formatter(dt, customFormat);
        }
        //
        switch (format) {
            case 'date':
                return formatter(dt, 'P');
            case 'time':
                return formatter(dt, 'p');
            case 'exact':
                return formatter(dt, 'Pp');
            case 'relative':
                return formatRelative(dt, new Date());
            case 'relativedate':
                setDefaultOptions({ locale: localeObj.date });
                return formatRelative(dt, new Date());
            case 'relativetime':
                setDefaultOptions({ locale: localeObj.date });
                return formatDistanceToNowStrict(dt, { addSuffix: true });
            case 'dayname':
            case 'daynameshort':
                // assign dates from monday - sunday (Jan 1 1990 was a monday)
                // to return names of the week
                switch (date) {
                    case 1:
                    case 'mon':
                    case 'monday':
                        dt = new Date('1990-01-01');
                        break;
                    case 2:
                    case 'tue':
                    case 'tuesday':
                        dt = new Date('1990-01-02');
                        break;
                    case 3:
                    case 'wed':
                    case 'wednesday':
                        dt = new Date('1990-01-03');
                        break;
                    case 4:
                    case 'thu':
                    case 'thursday':
                        dt = new Date('1990-01-04');
                        break;
                    case 5:
                    case 'fri':
                    case 'friday':
                        dt = new Date('1990-01-05');
                        break;
                    case 6:
                    case 'sat':
                    case 'saturday':
                        dt = new Date('1990-01-06');
                        break;
                    case 7:
                    case 'sun':
                    case 'sunday':
                        dt = new Date('1990-01-07');
                        break;
                }

                if (format === 'dayname') {
                    //full localised name of the day
                    return formatter(dt, 'EEEE');
                } else {
                    //short localised name of the day
                    return formatter(dt, 'EE');
                }
            default:
                return formatter(dt, 'eeee PPp');
        }
    } catch (err) {
        // warn in console
        if (console && console.warn) {
            console.warn(
                `'datetime' error : '${JSON.stringify(date)}' is not parsable timestamp. Error.mesage: ${err.message}`
            );
        }
        return '';
    }
};

export { formattedDate };
