




































































import Vue from 'vue';
import VuePortal from '@linusborg/vue-simple-portal';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoHeading from '../../Atoms/co-heading/CoHeading.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';

Vue.use(VuePortal);

export default {
    components: { CoThumbnail, CoHeading, CoRoundButton },
    name: 'CoModal',
    props: {
        title: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            required: false,
            default: 'md',
            validator(value: string) {
                return ['sm', 'md', 'lg', 'xl'].includes(value);
            },
        },
        background: {
            type: String,
            required: false,
            default: 'white',
            validator(value: string) {
                return ['default', 'white', 'grey'].includes(value);
            },
        },
        footer: {
            type: Boolean,
            required: false,
            default: true,
        },
        bodyClass: {
            type: [String, Object, Array],
            required: false,
            default: '',
        },
        titleImage: {
            type: String,
            required: false,
            default: '',
        },

        overflowY: {
            // if true, the modal will have overflow-y: auto
            // and the content will be scrollable
            type: String,
            required: false,
            default: 'auto',
            validator(value: string) {
                return ['auto', 'visible', 'hidden'].includes(value);
            },
        },
        maxHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
        useAvailableHeight: {
            type: Boolean,
            required: false,
            default: false,
        },
        transparentBackground: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        closeOnClickAway: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    model: {
        event: 'close',
    },
    data() {
        return {
            showModal: false,
        };
    },
    computed: {
        classes() {
            return {
                [`${this.size}`]: true,
                [`${this.background}`]: true,
                'max-height': this.maxHeight,
                useAvailableHeight: this.useAvailableHeight,
                transparentBackground: this.transparentBackground,
                fullScreen: this.fullScreen,
            };
        },
        hasHeader() {
            return !!this.$slots.header;
        },
        hasBody() {
            return !!this.$slots.body;
        },
        hasFooter() {
            return !!this.$slots.footer;
        },
    },
    mounted() {
        this.$emit('shown', this);
    },
    watch: {
        showModal(val) {
            //disable body scroll when modal is open
            if (val) {
                document.documentElement.classList.add('prevent-scroll');
                document.body.classList.add('prevent-scroll');
            } else {
                document.documentElement.classList.remove('prevent-scroll');
                document.body.classList.remove('prevent-scroll');
            }
        },
    },
    methods: {
        clickAway(event) {
            this.closeOnClickAway ? this.discard() : null;
        },
        discard() {
            this.showModal = false;
            this.$emit('close');
        },

        show() {
            this.showModal = true;
            this.$emit('show', this);
        },

        hide() {
            this.$emit('close');
            this.showModal = false;
        },
    },
};
