<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.picture {
    width: 96px;
    height: 96px;
    object-fit: cover;
    @media (min-width: 768px) {
        width: 144px;
        height: 144px;
        object-fit: cover !important;
    }
}

.navbar.navbar-light.bg-gray {
    background-color: @co-lightgrey !important;
}
</style>
<template>
    <div>
        <!-- top bar -->
        <b-navbar variant="gray" type="light" fixed="top">
            <b-navbar-brand tag="h1" class="mb-0" to="/">
                <img
                    v-if="this.$store.state.space.LogoURL"
                    :src="this.$store.state.space.LogoURL"
                    height="36px"
                    alt="coapp logo"
                />
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto d-none d-sm-flex align-content-center">
                <b-nav-item>
                    <b-nav-text class="text-dark">{{ $t('messages.accountexists') }}</b-nav-text>
                </b-nav-item>
                <b-nav-item>
                    <b-button to="/login" variant="outline-primary">{{ $t('labels.login') }}</b-button>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
        <!-- content section -->
        <div class="row m-0 d-flex justify-content-center align-items-center" style="height: 100vh">
            <!-- plans -->
            <div class="col-12 col-md-10 col-lg-8" style="height: 70vh">
                <CoHeadline class="mb-4 px-3" :level="1">
                    {{ $t('messages.jointhecommunity', { space: this.$store.state.space.Name }) }}
                </CoHeadline>
                <div class="py-3">
                    <CoButton @onClick="$router.push('/plans')" type="text" class="pl-0">
                        <CoIcon name="arrow-left" fill="co-darkgrey" />
                        <CoText variant="mute" class="ml-1 mb-0">{{ $t('labels.back') }}</CoText>
                    </CoButton>
                </div>

                <coPlanDetail :plan="plan" class="pb-5">
                    <template v-slot:header-button>
                        <coButton @click="choosePlan" variant="primary" :label="$t('labels.planchoose')" />
                    </template>
                </coPlanDetail>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vuei18n from 'vue-i18n';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoPlanDetail from '@/components/Organisms/co-plan-detail/CoPlanDetail.vue';

export default {
    name: 'PlanDetails',
    i18n: {
        messages: {
            en: {
                messages: {
                    accountexists: 'Already have an account?',
                    jointhecommunity: 'Join the {space} Community',
                    noplanavailable: 'There are currently no plans available for you.',
                },
            },
            de: {
                messages: {
                    accountexists: 'Hast du schon einen Account?',
                    jointhecommunity: 'Trete der {space} Community bei',
                    noplanavailable: 'Derzeit sind keine Tarife verfügbar.',
                },
            },
        },
    },
    data() {
        return {
            plan: null,
        };
    },
    mounted() {
        this.getPlan(this.$route.params.id);
    },
    methods: {
        calculatePrice(priceInCents, taxRate) {
            if (taxRate && taxRate.Inclusive) {
                const price = priceInCents / 100.0;
                return price.toFixed(2);
            }
            const price = priceInCents / 100.0;
            const totalPrice = (price * (taxRate.Percentage / 100 + 1)).toFixed(2);
            return totalPrice;
        },
        choosePlan() {
            this.$store.commit('SET_PLAN', this.plan);
            this.$router.push(`/registerv2`);
        },
        getPlan(id) {
            this.loading = true;

            axios({
                method: 'GET',
                url: `/space/plan/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plan = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
};
</script>
