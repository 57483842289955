<template>
    <div class="d-flex mb-3 feedpost">
        <b-card header-tag="header" footer-tag="footer" class="d-inline w-100">
            <template v-slot:header>
                <div v-if="$store.state.space.ID != object.SpaceID" class="d-flex align-items-center mb-2">
                    <CoThumbnail
                        :ImageURL="space.FaviconURL"
                        :Clickable="false"
                        :color="space.PrimaryColor"
                        size="sm"
                        class="mr-2"
                    ></CoThumbnail>
                    <CoText type="p3" variant="mute" class="mb-0">{{ space.Name }}</CoText>
                </div>
                <div v-if="object" class="d-flex align-items-top">
                    <div v-if="author" class="">
                        <ProfileCircle
                            v-if="author"
                            :ImageURL="author.Profile.ImageURL"
                            :Slug="author.Slug"
                        ></ProfileCircle>
                    </div>
                    <div v-if="author" class="ml-3">
                        <router-link :to="'/profile/' + author.Slug">{{ author.Profile.Name }}</router-link>
                        <span class="my-1 my-xl-0">
                            {{ $t('eventcreated') }}
                            <router-link :to="'/event/' + object.Slug">{{ object.Title }}</router-link></span
                        >
                        <small class="d-flex text-body mt-1 align-items-center"
                            ><b-icon class="mr-1" icon="clock"></b-icon>
                            <coDate :date="object.StartDate" format="relative" />
                            <span> - </span>
                            <coDate v-if="!isMultiDay" :date="object.EndDate" format="time" />
                            <coDate v-else :date="object.EndDate" format="relative" />
                        </small>
                    </div>
                </div>
            </template>

            <div class="m-0">
                <template>
                    <b-card-text
                        v-if="object.Description"
                        ref="postText"
                        v-html="object.Description"
                        v-linkifytiptap
                        v-bind:class="{ textNotVisible: textNotVisible }"
                    ></b-card-text>
                </template>
            </div>
            <div class="mt-1 mb-3 readbtn">
                <router-link class="m-0 p-0" :to="'/event/' + object.Slug">{{ $t('viewevent') }}</router-link>
            </div>

            <div class="photos mt-3" v-if="object['Images'] && object['Images'][0]">
                <a @click="showSlider(0)" class="d-block">
                    <LazyLoadImg
                        v-if="filterOutOldImagePlaceHolders() && filterOutOldImagePlaceHolders()[0]"
                        :src="filterOutOldImagePlaceHolders()[0]"
                        :relativeHeight="65"
                    >
                    </LazyLoadImg>
                </a>
            </div>

            <template v-slot:footer v-if="object">
                <div class="card-footer-actions d-flex flex-wrap text-muted">
                    <div class="d-flex align-items-center" id="reactions">
                        <Reactions class="ml-1" :object="object" object-type="event"></Reactions>
                    </div>
                </div>

                <CommentSection
                    v-if="object.ID"
                    post-type="event"
                    :PostID="object.ID"
                    :profileDisplayed="me.Profile"
                    :ShowComments="showComments"
                    :highlightCommentID="highlightCommentID"
                    @comment-count-update="commentCount = $event"
                    @show-comments="showComments = true"
                >
                </CommentSection>
            </template>
        </b-card>

        <!-- slider modal -->
        <b-modal
            :id="'slider' + object.ID"
            size="xl"
            class="w-100"
            title=""
            hide-footer
            hide-headser
            centered
            no-fade
            content-class="bg-transparent gallery-modal"
        >
            <img class="w-100" :src="object.ImageURL" />
        </b-modal>
        <!-- slider modal -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.textNotVisible {
    overflow: hidden;
    max-height: 130px;
    -webkit-mask-image: linear-gradient(to bottom, black 65%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 65%, transparent 100%);
}

.photos {
    margin: 0px -1.25rem;
}

.photo-container {
    padding-bottom: 65%;
    position: relative;
    overflow: hidden;
    flex-grow: 1;

    &.more-photos {
        padding-bottom: 32%;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}
</style>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import moment from 'moment';
import linkify from 'vue-linkify';
import i18n from 'vue-i18n';

Vue.directive('linkified', linkify);

export default {
    i18n: {
        messages: {
            en: {
                eventcreated: 'created the @:labels.event',
                viewevent: 'view the @:labels.event ',
            },
            de: {
                eventcreated: 'erstellte die @:labels.event',
                viewevent: '@:labels.event ansehen',
            },
        },
    },
    name: 'FeedEvent',
    props: ['object', 'highlightCommentID', 'space'],
    data() {
        return {
            author: null,
            me: this.$store.state.me,
            deleting: false,
            comments: [],
            showComments: false,
            commentCount: 0,
            slide: 0,
            currentYear: '',
            textNotVisible: true,
        };
    },
    beforeCreate() {},

    created() {
        this.getAuthor(this.object.CreatedBy);
    },

    mounted() {
        const start = new Date(this.object.StartDate * 1000);
        const end = new Date(this.object.EndDate * 1000);

        this.object.year = moment(start).format('YYYY');
        this.currentYear = moment().format('YYYY');

        this.hideTextPart();
    },
    computed: {
        isMultiDay() {
            if (
                this.$t('datetime', { date: this.object.StartDate, format: 'date' }) !==
                this.$t('datetime', { date: this.object.EndDate, format: 'date' })
            ) {
                return true;
            }
            return false;
        },
    },
    methods: {
        filterOutOldImagePlaceHolders() {
            //  remove from this.object.ImageURL array urls containing '/img/Platform_Gradient'
            const ImageURLs = this.object.Images.filter((url) => !url.includes('/img/Platform_Gradient'));
            return ImageURLs;
        },
        hideTextPart() {
            const eventDescr = this.$refs.postText;
            if (eventDescr) {
                // assuming font-size is equal to 1rem
                const rem = parseInt(getComputedStyle(eventDescr).fontSize);

                const delta = eventDescr.scrollHeight - eventDescr.clientHeight;
                if (delta > 2 * rem) {
                    this.textNotVisible = true;
                } else {
                    this.textNotVisible = false;
                }
            }
        },

        showSlider(index) {
            this.slide = index;
            this.$bvModal.show(`slider${this.object.ID}`);
        },

        getAuthor(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response && response.Users) {
                        this.author = response.Users[0];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
    },
};
</script>
