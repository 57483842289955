<template>
    <div class="d-flex mb-3 feedpost">
        <b-card header-tag="header" footer-tag="footer" class="d-inline w-100">
            <template v-slot:header>
                <div v-if="$store.state.space.ID != object.SpaceID" class="d-flex align-items-center mb-2">
                    <CoThumbnail
                        :ImageURL="space.FaviconURL"
                        :Clickable="false"
                        :color="space.PrimaryColor"
                        size="sm"
                        class="mr-2"
                    />
                    <CoText type="p3" variant="mute" class="mb-0">{{ space.Name }}</CoText>
                </div>
                <div v-if="object" class="d-flex align-items-top">
                    <div v-if="author" class="">
                        <ProfileCircle
                            v-if="author"
                            :ImageURL="author.Profile.ImageURL"
                            :Slug="author.Slug"
                        ></ProfileCircle>
                    </div>
                    <div v-if="author" class="ml-3">
                        <router-link :to="'/profile/' + author.Slug">{{ author.Profile.Name }}</router-link>
                        <span class="my-1 my-xl-0">
                            {{ $t('itemcreated') }}
                            <router-link :to="'/job/' + object.Slug">{{ object.Title }}</router-link></span
                        >
                        <div class="d-flex align-items-baseline">
                            <small class="text-body mt-1">
                                <b-icon class="mr-1" icon="clock"></b-icon>
                                <coDate :date="object.CreatedAt" format="relative" />
                            </small>
                            <small class="ml-2 mt-1">| {{ category }}</small>
                        </div>
                    </div>
                </div>
            </template>

            <b-card-text style="white-space: pre-wrap" class="m-0"
                >{{ object.Summary }}
                <router-link :to="'/job/' + object.Slug"><br />{{ $t('labels.readmore') }}</router-link></b-card-text
            >

            <div class="photos" v-if="object.ImageURL">
                <a @click="showSlider(0)" class="d-block">
                    <LazyLoadImg
                        v-if="filterOutOldImagePlaceHolders()"
                        :src="object.ImageURL"
                        :relativeHeight="65"
                    ></LazyLoadImg>
                </a>
            </div>

            <template v-slot:footer v-if="object">
                <div class="card-footer-actions d-flex flex-wrap text-muted">
                    <div class="d-flex align-items-center" id="reactions">
                        <Reactions class="ml-1" :object="object" object-type="job"></Reactions>
                    </div>
                </div>

                <CommentSection
                    v-if="object.ID"
                    post-type="job"
                    :PostID="object.ID"
                    :profileDisplayed="me.Profile"
                    :ShowComments="showComments"
                    :highlightCommentID="highlightCommentID"
                    @comment-count-update="commentCount = $event"
                    @show-comments="showComments = true"
                >
                </CommentSection>
            </template>
        </b-card>

        <!-- slider modal -->
        <b-modal
            :id="'slider' + object.ID"
            size="xl"
            class="w-100"
            title=""
            hide-footer
            hide-headser
            centered
            no-fade
            content-class="bg-transparent gallery-modal"
        >
            <img class="w-100" :src="object.ImageURL" />
        </b-modal>
        <!-- slider modal -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.photos {
    margin: 0px -1.25rem;
}

.photo-container {
    padding-bottom: 65%;
    position: relative;
    overflow: hidden;
    flex-grow: 1;

    &.more-photos {
        padding-bottom: 32%;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}
</style>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import moment from 'moment';
import linkify from 'vue-linkify';
import i18n from 'vue-i18n';

Vue.directive('linkified', linkify);

export default {
    i18n: {
        messages: {
            en: {
                itemcreated: 'created the @:labels.marketitem',
            },
            de: {
                itemcreated: 'erstellte den @:labels.marketitem',
            },
        },
    },
    name: 'FeedJob',
    props: ['object', 'objectIndex', 'highlightCommentID', 'space'],
    data() {
        return {
            author: null,
            me: this.$store.state.me,
            deleting: false,
            comments: [],
            showComments: false,
            commentCount: 0,
            slide: 0,
        };
    },

    created() {
        this.getAuthor(this.object.AuthorID);
    },
    computed: {
        category() {
            if (this.object.Category == 2) {
                return 'Product';
            }
            if (this.object.Category == 1) {
                return 'Service';
            }
            return 'Job';
        },
    },
    mounted() {},
    methods: {
        filterOutOldImagePlaceHolders() {
            //  remove from this.object.ImageURL urls containing '/img/Platform_Gradient'
            if (this.object.ImageURL.includes('/img/Platform_Gradient')) {
                return '';
            }
            return this.object.ImageURL;
        },
        showSlider(index) {
            this.slide = index;
            this.$bvModal.show(`slider${this.object.ID}`);
        },

        getAuthor(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response && response.Users) {
                        this.author = response.Users[0];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
    },
};
</script>
