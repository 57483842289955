<template>
    <div class="dashboard-container-small col-np-mobile mb-5">
        <b-overlay :show="loading">
            <b-card>
                <h2>{{ $t('labels.eventcreate') }}</h2>
                <b-form @submit.stop.prevent="create">
                    <b-form-group
                        id="input-group-title"
                        :label="$t('labels.title')"
                        label-for="input-title"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="title-input-description"
                    >
                        <b-form-input
                            id="input-title"
                            v-model="form['Title']"
                            type="text"
                            required
                            :placeholder="$t('labels.title')"
                            class="rounded-pill"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="description"
                        :label="$t('labels.description')"
                        label-for="textarea-description"
                        aria-describedby="details-input-description"
                        label-class="label-align"
                    >
                        <Editor
                            id="textarea-default"
                            :placeholder="$t('labels.description')"
                            taclass="m-0 comment-input"
                            rows="5"
                            class="customTiptap"
                            ref="tiptap"
                            v-model="form.Description"
                            expanded
                        ></Editor>
                        <b-form-text id="details-input-description" class="ml-3"
                            >{{ $t('info.description') }}
                        </b-form-text>
                    </b-form-group>

                    <b-form-group
                        v-if="locations.length != 0"
                        id="input-group-location"
                        :label="$t('labels.location')"
                        label-for="input-location"
                        label-class="label-align"
                    >
                        <b-form-select v-model="form['Location']" class="rounded-pill">
                            <option :value="null" selected>{{ $t('placeholders.select') }}</option>
                            <option
                                v-for="(selectOption, indexOpt) in locations"
                                :key="indexOpt"
                                :value="selectOption.Name"
                            >
                                {{ selectOption.Name }}
                            </option>
                        </b-form-select>
                        <coInput
                            v-if="form['Location'] === $t('addaddress')"
                            :placeholder="$t('labels.name')"
                            v-model="newLocation['Name']"
                            class="rounded-pill mt-3"
                        ></coInput>
                        <coInput
                            v-if="form['Location'] === $t('addaddress')"
                            :placeholder="$t('labels.address')"
                            v-model="newLocation['Address']"
                            class="rounded-pill mt-3"
                        ></coInput>
                        <coInput
                            v-if="form['Location'] === $t('addaddress')"
                            :placeholder="$t('labels.postalcode')"
                            v-model="newLocation['ZipCode']"
                            class="rounded-pill mt-3"
                        ></coInput>
                        <coInput
                            v-if="form['Location'] === $t('addaddress')"
                            :placeholder="$t('labels.city')"
                            v-model="newLocation['City']"
                            class="rounded-pill mt-3"
                        ></coInput>
                        <coInput
                            v-if="form['Location'] === $t('addaddress')"
                            :placeholder="$t('labels.country')"
                            v-model="newLocation['Country']"
                            class="rounded-pill mt-3"
                        ></coInput>
                    </b-form-group>

                    <b-form-group
                        id="input-group-organizers"
                        :label="$t('labels.organizers')"
                        label-for="input-organizers"
                        label-class="label-align"
                    >
                        <div class="d-inline-block mb-2" v-if="organizers.length > 0">
                            <b-form-tag
                                v-on:click.stop
                                v-for="organizer in organizers"
                                @click="$event.preventDefault()"
                                @remove="removeOrganizer(organizer)"
                                :key="organizer.ID"
                                variant="primary"
                                class="mr-1"
                            >
                                <ProfileCircleWithoutRedirect
                                    :ImageURL="organizer.Profile.ImageURL"
                                    :Slug="organizer.Slug"
                                    :Name="organizer.Profile.Name"
                                ></ProfileCircleWithoutRedirect>
                            </b-form-tag>
                        </div>
                        <input
                            type="text"
                            ref="usersSearchInput"
                            @input="searchUsers"
                            :placeholder="$t('placeholders.searchmembers')"
                            class="form-control rounded-pill d-block"
                        />
                        <b-list-group class="mt-2">
                            <b-list-group-item
                                href="#"
                                @click="addOrganizer(user)"
                                v-for="user in searchedUsers"
                                :key="user.ID"
                            >
                                <ProfileCircle
                                    :ImageURL="user.Profile.ImageURL"
                                    :Name="user.Profile.Name"
                                ></ProfileCircle>
                            </b-list-group-item>
                        </b-list-group>
                    </b-form-group>

                    <b-form-group
                        id="input-group-action-url"
                        :label="$t('labels.ticketurl')"
                        label-for="input-action-url"
                        label-class="label-align"
                        aria-describedby="ticket-input-description"
                    >
                        <b-form-input
                            id="input-action-url"
                            v-model="form['FullURL']"
                            type="text"
                            :placeholder="$t('ticketurlplaceholder')"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="ticket-input-description" class="ml-3">{{ $t('info.ticketurl') }}</b-form-text>
                    </b-form-group>
                    <b-form-group
                        id="input-group-tags"
                        :label="
                            this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                ? $t('labels.topics')
                                : $t('labels.tags')
                        "
                        label-for="input-tags"
                        label-class="label-align"
                    >
                        <vue-tags-input
                            id="input-tags"
                            class="mt-0"
                            v-model="tag"
                            :tags="tags"
                            :placeholder="$t('placeholders.tags')"
                            :separators="separators"
                            :autocomplete-items="autocompleteItems"
                            :add-only-from-autocomplete="false"
                            @tags-changed="updateTags"
                            @before-adding-tag="addTag"
                        />
                    </b-form-group>

                    <b-form-group
                        v-if="eventTypes.length != 0"
                        id="input-group-event-type"
                        label="Type"
                        label-for="select-type"
                        label-class="label-align"
                    >
                        <b-form-select
                            id="select-type"
                            v-model="selectedType"
                            :options="eventTypes"
                            class="rounded-pill"
                        >
                            <b-form-select-option :value="null">{{ $t('placeholders.select') }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>

                    <b-form-group
                        v-if="this.$unleash.isEnabled('frontend.public-event')"
                        id="input-group-public"
                        label="Visibility"
                        label-class="label-align"
                        label-for="input-public"
                    >
                        <b-form-checkbox
                            id="input-public"
                            v-model="isPublic"
                            name="public"
                            :value="true"
                            :unchecked-value="false"
                        >
                            <p class="mb-0">{{ $t('makeeventaccessible') }}</p>
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                        v-if="eventCategories.length != 0"
                        id="input-group-event-category"
                        label="Category"
                        label-for="select-category"
                        label-class="label-align"
                    >
                        <b-form-select
                            id="select-category"
                            v-model="selectedCategory"
                            :options="eventCategories"
                            class="rounded-pill"
                        >
                            <b-form-select-option :value="null">{{ $t('placeholders.select') }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <div class="d-flex">
                        <b-form-group
                            id="input-group-event-start"
                            :label="$t('labels.startdate')"
                            label-for="select-start"
                            label-class="label-align"
                        >
                            <b-form-datepicker
                                :label-no-date-selected="$t('labels.dateempty')"
                                :placeholder="$t('placeholders.date')"
                                id="select-start"
                                v-model="startDate"
                                @input="selectStartDate"
                                class="mb-2 rounded-pill"
                            ></b-form-datepicker>
                        </b-form-group>
                        <b-form-group
                            id="input-group-start-time"
                            :label="$t('labels.starttime')"
                            label-for="input-start-time"
                            label-class="label-align"
                            class="ml-2"
                        >
                            <b-form-input
                                id="input-start-time"
                                v-model="startTime"
                                type="time"
                                required
                                class="rounded-pill"
                            >
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="d-flex">
                        <b-form-group
                            id="input-group-event-end"
                            :label="$t('labels.enddate')"
                            label-for="select-end"
                            label-class="label-align"
                        >
                            <b-form-datepicker
                                :label-no-date-selected="$t('labels.dateempty')"
                                :placeholder="$t('placeholders.date')"
                                id="select-end"
                                v-model="endDate"
                                class="mb-2 rounded-pill"
                            ></b-form-datepicker>
                        </b-form-group>
                        <b-form-group
                            id="input-group-end-time"
                            :label="$t('labels.endtime')"
                            label-for="input-end-time"
                            label-class="label-align"
                            class="ml-2"
                        >
                            <b-form-input
                                id="input-end-time"
                                v-model="endTime"
                                type="time"
                                required
                                class="rounded-pill"
                            >
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <b-form-group
                        id="input-group-image"
                        :label="$t('labels.image')"
                        label-for="input-image"
                        label-class="label-align"
                    >
                        <b-form-file
                            accept="image/*"
                            id="input-image"
                            :placeholder="$t('placeholders.file')"
                            @change="previewFiles"
                            ref="image"
                        />
                        <div class="mb-1">
                            <small>
                                {{ $t('messages.imageguidelines') }}
                            </small>
                        </div>
                    </b-form-group>

                    <div class="align-content-top d-flex position-relative" v-if="imageURL">
                        <b-img class="preview-img p-0" thumbnail fluid width="150" height="150" :src="imageURL"></b-img>
                        <div class="delete">
                            <b-icon
                                icon="x-circle"
                                variant="danger pointer"
                                scale="1.75"
                                v-on:click="deletePreview(index)"
                            ></b-icon>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between mt-4">
                        <div class="mr-auto">
                            <b-button variant="outline-primary" @click="cancel">{{ $t('labels.cancel') }}</b-button>
                        </div>
                        <div>
                            <b-button
                                v-if="this.$unleash.isEnabled('frontend.draft-event')"
                                variant="outline-primary"
                                @click="saveDraft"
                                class="mr-3"
                                >{{ $t('saveasdraft') }}
                            </b-button>
                            <b-button variant="primary" type="submit">{{ $t('saveandpublish') }}</b-button>
                        </div>
                    </div>
                </b-form>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import { McWysiwyg } from '@mycure/vue-wysiwyg';
import VueMoment from 'vue-moment';
import VueIntercom from 'vue-intercom';
import i18n from 'vue-i18n';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import EventBus from '../../eventBus';

Vue.use(VueIntercom, { appId: 'kttwojyk' });

const moment = require('moment');
Vue.use(require('vue-moment'), {
    moment,
});

export default {
    i18n: {
        messages: {
            en: {
                info: {
                    description: 'Detailed info about your Event and how to participate.',
                    summary: "What's great about your event in one line.",
                    ticketurl: 'If a ticket needs to be booked for this event, provide a link to the booking page.',
                },
                addaddress: 'Add a new address',
                ticketurlplaceholder: 'External ticket booking url (optional)',
                saveasdraft: 'Save as Draft',
                saveandpublish: 'Save and Publish',
                makeeventaccessible: 'Make event accessible to everyone outside of the space',
            },
            de: {
                info: {
                    description: 'Detaillierte Informationen über das Event und wie man teilnehmen kann.',
                    summary: 'Was ist das Tolle an deiner Veranstaltung in einer Zeile.',
                    ticketurl:
                        'Wenn für diese Veranstaltung ein Ticket gebucht werden muss, gib einen Link zur Buchungsseite an.',
                },
                addaddress: 'Neue Adresse hinzufügen',
                ticketurlplaceholder: 'Link zu externer Ticketbuchung (optional)',
                saveasdraft: 'Als Entwurf speichern',
                saveandpublish: 'Speichern und veröffentlichen',
                makeeventaccessible: 'Machen Sie die Veranstaltung für alle außerhalb des Raums zugänglich',
            },
        },
    },
    name: 'EventCreate',
    components: {
        CoInput,
        VueTagsInput,
        McWysiwyg,
    },
    data() {
        return {
            loading: false,
            startDate: null,
            startTime: '10:00',
            endDate: null,
            endTime: '11:00',
            form: {
                Location: null,
            },
            isPublic: false,
            newLocation: {
                Name: '',
                Address: '',
                ZipCode: '',
                City: '',
                Country: '',
            },

            eventTypes: [],
            eventCategories: [],
            selectedType: null,
            selectedCategory: null,
            date: null,

            // variables for tag input
            tag: '',
            tags: [],
            autocompleteItems: [],
            separators: [';', ',', '#', ', #'],
            debounce: null,
            // ---

            // images
            image: null,
            imageURL: '',

            locations: [],
            searchedUsers: [],
            organizers: [],
            organizersIDs: [],
        };
    },
    computed: {
        isVisible() {
            return this.$intercom.visible;
        },
        isAdmin() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin') === true) {
                return true;
            }
            return false;
        },
        isUserAllowedToCreateEvent() {
            return this.isAdmin || !this.$store.state.permissions.EventsCreateAdminsOnly;
        },
    },
    created() {
        if (!this.isUserAllowedToCreateEvent) {
            this.$router.push('/events');
        }
        this.getTypes();
        this.getCategories();
        this.listLocations();
    },
    mounted() {
        if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
            this.$intercom.boot({
                user_id: this.$store.state.me.ID,
                name: this.$store.state.me.Profile.Name,
                email: this.$store.state.me.Email,
                spaceID: this.$store.state.space.ID,
                space: this.$store.state.space.Name,
                intro: this.$store.state.me.Intro,
                hide_default_launcher: this.$isMobile || this.$store.state.isInMobile, // hide if Vue.prototype.$isMobile flag is set
            });

            this.$intercom.hide();
        }
    },
    watch: {
        tag: 'loadAutocompleteItems',
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },
    methods: {
        emitEventToIntercom() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent('created-event');
            }
        },
        cancel() {
            if (this.prevRoute) {
                this.$router.push(this.prevRoute);
            } else {
                this.$router.push('/events');
            }
        },
        selectStartDate() {
            this.endDate = this.startDate;
        },
        // method load categories
        getCategories(next) {
            this.$store
                .dispatch('listEventCategories', next)
                .then((response) => {
                    const category = '';
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            const tag = {
                                text: entry.Value,
                                slug: entry.Slug,
                                value: entry.Slug,
                            };

                            this.eventCategories.push(tag);
                        }, this);
                        if (response.next !== '' && response.next != null) {
                            this.getCategories(response.next);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },

        // method load types
        getTypes(next) {
            this.$store
                .dispatch('listEventTypes', next)
                .then((response) => {
                    const category = '';
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            const tag = {
                                text: entry.Value,
                                slug: entry.Slug,
                                value: entry.Slug,
                            };

                            this.eventTypes.push(tag);
                        }, this);
                        if (response.next !== '' && response.next != null) {
                            this.getTypes(response.next);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },

        listLocations() {
            axios({
                method: 'GET',
                url: '/admin/location/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Locations.length !== 0) {
                        this.locations = response.data.Locations;
                        this.locations = [...this.locations, { Name: this.$t('addaddress') }];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.locations = [{ Name: this.$t('addaddress') }];
                });
        },

        // methode for images
        uploadImages(callback) {
            this.$store
                .dispatch('imageUpload', {
                    file: this.image,
                    url: '/dashboard/image/events',
                })
                .then((response) => {
                    this.form.ImageURL = response;
                    this.form.Images = [response];
                    callback();
                })
                .catch((error) => {
                    console.log(error);
                    const errorMsg = {
                        Message: 'Could not upload one or more images, please try again or check details',
                        Details: '',
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        // method for event
        create() {
            this.loading = true;

            if (this.image) {
                this.uploadImages(this.postEvent);
            } else {
                this.postEvent();
            }
        },

        saveDraft() {
            this.form.Draft = true;
            this.create();
        },
        postEvent() {
            const object = this.form;

            if (object.Description) {
                object.Description = this.form.Description.replace(/<p><\/p>/g, '<br>');
            }

            const hashtags = [];
            this.tags.forEach((entry) => {
                hashtags.push(entry.slug);
            }, hashtags);

            object.Tags = hashtags;
            if (this.selectedCategory) {
                object.EventCategory = [this.selectedCategory];
            }

            if (this.selectedType) {
                object.EventType = [this.selectedType];
            }

            if (object.Location === this.$t('addaddress')) {
                object.Location = '';
                if (this.newLocation.Name) {
                    object.Location += `${this.newLocation.Name}, `;
                }
                if (this.newLocation.Address) {
                    object.Location += `${this.newLocation.Address}, `;
                }
                if (this.newLocation.ZipCode) {
                    object.Location += `${this.newLocation.ZipCode}, `;
                }
                if (this.newLocation.City) {
                    object.Location += `${this.newLocation.City}, `;
                }
                if (this.newLocation.Country) {
                    object.Location += `${this.newLocation.Country}`;
                } else {
                    object.Location = object.Location.slice(0, -2);
                }
            }

            let startDate = moment(this.startDate);
            let endDate = moment(this.endDate);
            const startTime = this.startTime.split(':');
            const endTime = this.endTime.split(':');

            startDate.hours(parseInt(startTime[0], 10));
            startDate.minutes(parseInt(startTime[1], 10));

            startDate = startDate.unix().toString();

            object.StartDate = startDate;

            endDate.hours(parseInt(endTime[0], 10));
            endDate.minutes(parseInt(endTime[1], 10));

            endDate = endDate.unix().toString();

            object.EndDate = endDate;
            object.Organizers = this.organizersIDs;

            if (this.isPublic) {
                object.Visibility = 1;
            } else {
                object.Visibility = 0;
            }

            this.$store
                .dispatch('createEvent', object)
                .then((response) => {
                    this.emitEventToIntercom();
                    const message = {
                        Message: this.$t('labels.eventcreated'),
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.form = response;
                    setTimeout(() => {
                        this.loading = false;
                        this.$router.push(`/event/${response.Slug}`);
                    }, 300);
                })
                .catch((error) => {
                    const message = {
                        Message: 'Failed to create event',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },

        // methods for images
        previewFiles(event) {
            // set file preview
            (this.image = event.target.files[0]), (this.imageURL = URL.createObjectURL(event.target.files[0]));
        },
        deletePreview(index) {
            this.image = null;
            this.imageURL = '';
        },

        // methods for tag input
        loadTags(slugs) {
            if (slugs == null) {
                return false;
            }
            const newArray = slugs.filter((el) => el !== '');
            const IDs = JSON.stringify({ IDs: newArray });
            this.$store
                .dispatch('getTagsByIDs', IDs)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Value,
                            slug: entry.Slug,
                        };
                        this.tags.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
            return false;
        },

        addTag(obj) {
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tag2 = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tag2);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },

        updateTags(newTags) {
            this.autocompleteItems = [];
            this.tags = newTags;
        },

        loadAutocompleteItems() {
            this.autocompleteItems = [];
            if (this.tag.length < 2) return;
            this.$store
                .dispatch('searchTag', this.tag)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Slug,
                            slug: entry.Slug,
                        };
                        this.autocompleteItems.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },
        searchUsers(e) {
            if (e.target.value.length < 2) {
                this.searchedUsers = [];
                return;
            }
            this.$store
                .dispatch('searchUser', { input: e.target.value })
                .then((response) => {
                    this.searchedUsers = response.data.filter((us) => !this.organizersIDs.includes(us.ID));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        addOrganizer(user) {
            this.organizers = [...this.organizers, user];
            this.organizersIDs = [...this.organizersIDs, user.ID];
            this.searchedUsers = [];
            this.$refs.usersSearchInput.value = '';
        },
        removeOrganizer(organizer) {
            this.organizers = this.organizers.filter((o) => o.ID !== organizer.ID);
            this.organizersIDs = this.organizersIDs.filter((id) => id !== organizer.ID);
        },
    },
};
</script>
