<template>
    <div>
        <CoLabel style="margin-left: 0.5em" :text="$t('labels.letstalkabout')" />
        <coTagList
            :deletable="true"
            ref="userTagList"
            :tags="tags"
            editable
            :placeholder="$t('placeholders.tags')"
            @tagsChanged="tagsChanged"
        ></coTagList>
        <div style="padding: 0.5em">
            <small v-if="this.$store.state.space['StrongTags']"
                >{{ $t('infotags', { strongtags: this.$store.state.space['StrongTags'] }) }}
            </small>
        </div>
    </div>
</template>

<script>
import EventBus from '@/eventBus';
import { clone } from 'lodash';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                infotags: 'Examples for strong tags: {strongtags}',
            },
            de: {
                infotags: 'Beispiel für starke Tags: {strongtags}',
            },
        },
    },
    name: 'CoUserTagsEdit',
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            changedTags: Array,
        };
    },
    computed: {
        tags() {
            if (!this.user.Profile.Tags || !this.user.Profile.Tags.length) {
                return this.$store.state.space.PreFilledTags || [];
            }
            return this.user.Profile.Tags;
        },
    },
    created() {
        this.changedTags = this.tags;
    },
    methods: {
        tagsChanged(tags) {
            this.changedTags = tags;
        },
        saveChanges() {
            // clone the user object
            const user = clone(this.user);
            // update the tags
            user.Profile.Tags = this.changedTags;
            this.$store
                .dispatch('updateUser', user)
                .then((response) => {
                    const message = {
                        Message: this.$t('labels.profileupdated'),
                        Details: '',
                        Source: 'Profile Update',
                    };
                    EventBus.$emit('INFO', message);
                    this.$emit('saved', this.changedTags);
                })
                .catch((error) => {
                    const message = {
                        Message: `Could not update profile. Error: ${error.response.data}`,
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.url = null;
                    this.$emit('failed');
                });
        },
    },
};
</script>

<style></style>
