<template>
    <div class="co tag d-inline-flex">
        <CoIcon v-if="leadingIcon" :name="leadingIcon" class="align-self-center"></CoIcon>
        <span class="align-self-center flex-shrink-1">{{ value }}</span>
        <CoIcon v-if="trailingIcon" :name="trailingIcon" class="align-self-center flex-shrink-0"></CoIcon>
    </div>
</template>
<style lang="less" scoped>
@import '../../../assets/less/variables.less';
@import './CoTag.less';
</style>
<script>
import CoIcon from '../co-icon/CoIcon.vue';

export default {
    name: 'CoTag',
    components: {
        CoIcon,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        leadingIcon: {
            type: String,
            default: '',
        },
        trailingIcon: {
            type: String,
            default: '',
        },
    },
};
</script>
