import { render, staticRenderFns } from "./RegisterV2.vue?vue&type=template&id=6ee47b4b&scoped=true&"
import script from "./RegisterV2.vue?vue&type=script&lang=js&"
export * from "./RegisterV2.vue?vue&type=script&lang=js&"
import style0 from "./RegisterV2.vue?vue&type=style&index=0&id=6ee47b4b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee47b4b",
  null
  
)

export default component.exports