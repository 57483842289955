



























































import Vue, { PropType } from 'vue';
import { get } from 'lodash';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoTippy from '../../Atoms/co-tippy/CoTippy.vue';
import CoLoadingIndicator from '../../Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoDropdownItem from '../co-dropdown-item/CoDropdownItem.vue';

export default {
    name: 'CoDropdown',
    components: {
        CoButton,
        CoTippy,
        CoLoadingIndicator,
        CoDropdownItem,
    },
    props: {
        text: {
            type: String,
            default: null,
        },
        variant: {
            type: String,
            default: 'primary',
            validator: (value) =>
                ['default', 'primary', 'secondary', 'danger', 'warning', 'success', 'blacksecondary'].indexOf(value) !==
                -1,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        noChevron: {
            type: Boolean,
            default: false,
            required: false,
        },
        toRight: {
            type: Boolean,
            default: false,
            required: false,
        },
        top: {
            type: Boolean,
            default: false,
            required: false,
        },
        disablePropagation: {
            type: Boolean,
            default: false,
            required: false,
        },
        tippyOptions: {
            type: Object,
            default: () => ({}),
            required: false,
        },
    },
    data() {
        return {
            show: false,
        };
    },

    computed: {
        placement() {
            if (this.top) {
                return this.toRight ? 'top-end' : 'top-start';
            }
            return this.toRight ? 'bottom-end' : 'bottom-start';
        },
        menuClasses() {
            return {
                show: this.show,
                right: this.toRight,
            };
        },
        iconright() {
            return this.noChevron ? '' : 'chevron-down';
        },
        hasButton() {
            return get(this, '$slots.button', null) || (this.text && this.text.length > 0) ? true : false;
        },
    },
    methods: {
        propogationControl(event: Event) {
            if (this.disablePropagation) {
                event.stopPropagation();
            }
        },
        showDropdown() {
            this.show = true;
            this.$refs['tippy'] ? this.$refs['tippy'].show() : null;
        },
        hideDropdown() {
            this.show = false;
            this.$refs['tippy'] ? this.$refs['tippy'].hide() : null;
        },
    },
};
