<template>
    <transition name="post-fade">
        <div v-if="!deleted" class="d-flex mb-3 feedpost">
            <b-card :title="updateObject.Title" header-tag="header" footer-tag="footer" class="d-inline w-100">
                <template v-slot:header>
                    <div v-if="$store.state.space.ID != object.SpaceID" class="d-flex align-items-center mb-2">
                        <CoThumbnail
                            :ImageURL="space.FaviconURL"
                            :Clickable="false"
                            :color="space.PrimaryColor"
                            size="sm"
                            class="mr-2"
                        ></CoThumbnail>
                        <CoText type="p3" variant="mute" class="mb-0">{{ space.Name }}</CoText>
                    </div>
                    <div v-if="project" class="d-flex align-items-top">
                        <div v-if="author" class="">
                            <ProfileCircle
                                v-if="author"
                                :ImageURL="author.Profile.ImageURL"
                                :Slug="author.Slug"
                            ></ProfileCircle>
                        </div>
                        <div v-if="author" class="ml-3">
                            <router-link :to="'/profile/' + author.Slug">{{ author.Profile.Name }}</router-link>
                            <span class="my-1 my-xl-0">
                                {{ $t('pageupdated') }}
                                <router-link :to="'/project/' + project.Slug">{{ project.Title }}</router-link>
                            </span>
                            <small class="d-block text-body mt-1">
                                <b-icon class="mr-1" icon="clock" />
                                <coDate :date="updateObject.Created" format="relative" />
                            </small>
                        </div>
                        <div class="flex-grow-1"></div>
                        <div v-if="(author != null && me != null && me.ID == author.ID) || (admin && author != null)">
                            <b-dropdown
                                variant="ml-2 px-2"
                                menu-class="mt-3 border-1"
                                no-caret
                                right
                                style="position: relative; bottom: 7px; left: 12px"
                            >
                                <template v-slot:button-content>
                                    <b-icon icon="three-dots-vertical" class="m-0" scale="1.2"></b-icon>
                                </template>
                                <b-dropdown-item
                                    v-if="admin && updateObject['SentAnnouncement'] != true"
                                    v-b-modal="'announcement-modal' + updateObject.ID"
                                >
                                    <b-icon scale="0.8" icon="bell"></b-icon>
                                    {{ $t('labels.announcementmake') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-if="admin && updateObject['SentAnnouncement'] === true" disabled>
                                    <b-icon scale="0.8" icon="bell"></b-icon>
                                    {{ $t('labels.announcementsentby', { name: updateObject['AnnouncedBy'] }) }}
                                </b-dropdown-item>
                                <b-dropdown-item v-if="me.ID === author.ID" @click="showEditModal">
                                    <b-icon scale="0.8" icon="pencil-square"></b-icon>
                                    {{ $t('labels.edit') }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                    v-if="me.ID === author.ID || (admin && me.SpaceID === updateObject.SpaceID)"
                                    v-b-modal="'deleting-modal' + updateObject.ID"
                                >
                                    <b-icon scale="0.8" icon="trash"></b-icon>
                                    {{ $t('labels.delete') }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </template>

                <template v-if="updateObject.Version == 1">
                    <b-card-text
                        style="white-space: pre-wrap"
                        v-if="updateObject.Text"
                        v-html="updateObject.Text"
                        v-linkifytiptap
                    ></b-card-text>
                    <div v-if="updateObject['Links'] && updateObject['Links'].length != 0">
                        <iframe
                            class="w-100"
                            height="500"
                            :src="updateObject['Links'][0]"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                </template>
                <template v-else>
                    <b-card-text
                        style="white-space: pre-wrap"
                        v-if="updateObject.Text"
                        v-html="updateObject.Text"
                    ></b-card-text>
                </template>

                <div class="photos" v-if="updateObject['ImageURLs'] && updateObject['ImageURLs'][0]">
                    <a @click="showSlider(0)" class="d-block">
                        <LazyLoadImg
                            v-if="updateObject.ImageURLs && updateObject.ImageURLs[0]"
                            :src="updateObject.ImageURLs[0]"
                            :relativeHeight="65"
                        >
                        </LazyLoadImg>
                    </a>
                    <div class="d-flex mt-1px" v-if="updateObject['ImageURLs'] && updateObject.ImageURLs.length > 1">
                        <a
                            v-for="(item, index) in updateObject.ImageURLs.slice(1, 5)"
                            :key="index"
                            @click="showSlider(index + 1)"
                            class="flex-grow-1"
                            :class="{
                                'mr-1px': index < updateObject.ImageURLs.slice(1, 5).length - 1,
                            }"
                        >
                            <LazyLoadImg :src="item" :relativeHeight="100"></LazyLoadImg>
                        </a>
                    </div>
                </div>

                <!-- first link preview -->
                <LinkPreview v-if="links != null && links.length != 0" :url="links[0]" class="mt-2"></LinkPreview>

                <template v-slot:footer v-if="project">
                    <div class="card-footer-actions d-flex flex-wrap">
                        <div class="d-flex align-items-center" id="reactions">
                            <Reactions class="ml-1" :object="object" object-type="project-update"></Reactions>
                        </div>
                    </div>

                    <CommentSection
                        v-if="updateObject.ID"
                        post-type="ProjectUpdate"
                        :PostID="updateObject.ID"
                        :profileDisplayed="me.Profile"
                        :ShowComments="showComments"
                        :highlightCommentID="highlightCommentID"
                        @comment-count-update="commentCount = $event"
                        @show-comments="showComments = true"
                    >
                    </CommentSection>
                </template>
            </b-card>

            <!-- editing modal -->
            <!-- EditProjectUpdate -->

            <b-modal
                :id="'EditProjectUpdateModal' + updateObject.ID"
                :ref="'EditProjectUpdateModal' + updateObject.ID"
                size="lg"
                :title="$t('labels.updateedit')"
                no-fade
                modal-class="high-z-index"
                hide-footer
                centered
            >
                <template v-slot:default>
                    <EditProjectUpdate v-bind:projectID="project.ID" :oldUpdate="updateObject" :hide="hideEditModal">
                    </EditProjectUpdate>
                </template>
            </b-modal>
            <!-- editing modal -->

            <!-- deleting modal -->
            <b-modal
                :id="'deleting-modal' + updateObject.ID"
                size="sm"
                :title="$t('labels.updatedelete')"
                @ok="deleteUpdate"
                :ok-title="$t('labels.delete')"
                ok-variant="primary"
                :cancel-title="$t('labels.cancel')"
                centered
                :busy="deleting || deleted"
            >
                <b-overlay :show="deleting || deleted">
                    <p class="mb-1">{{ $t('messages.deletewarning') }}</p>
                </b-overlay>
                <!-- <div class="my-2" v-if="deleting">
          <span><b-spinner label="Spinning"></b-spinner></span>
        </div> -->
            </b-modal>
            <!-- deleting modal -->

            <!-- announcement modal -->
            <b-modal
                :id="'announcement-modal' + object.ID"
                size="md"
                title="Notify everyone in community?"
                centered
                @ok="makeAnnouncement"
                ok-title="Send"
                ok-variant="primary"
                :busy="announcing || announced"
            >
                <b-overlay :show="announcing || announced">
                    <p class="mb-1">
                        This will send an email notification to every community member. Are you sure you want to send
                        this?
                    </p>
                </b-overlay>
            </b-modal>
            <!-- announcement modal -->

            <!-- slider modal -->
            <b-modal
                v-if="updateObject['ImageURLs']"
                :id="'slider' + updateObject.ID"
                size="xl"
                class="w-100"
                title=""
                hide-footer
                hide-headers
                centered
                no-fade
                content-class="bg-transparent gallery-modal"
            >
                <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    :controls="updateObject.ImageURLs.length > 1"
                    ref="imageslider"
                >
                    <b-carousel-slide :img-src="item" v-for="(item, index) in updateObject.ImageURLs" :key="index">
                    </b-carousel-slide>
                </b-carousel>
            </b-modal>
            <!-- slider modal -->
        </div>
    </transition>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.mt-1px {
    margin-top: 1px;
}

.mr-1px {
    margin-right: 1px;
}

.photos {
    margin: 0px -1.25rem;
}

.photo-container {
    padding-bottom: 65%;
    position: relative;
    overflow: hidden;
    flex-grow: 1;

    &.more-photos {
        padding-bottom: 32%;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}
</style>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import moment from 'moment';
import linkify from 'vue-linkify';
import axios from 'axios';

import i18n from 'vue-i18n';

Vue.directive('linkified', linkify);

export default {
    i18n: {
        messages: {
            en: {
                pageupdated: 'updated the @:labels.page',
            },
            de: {
                pageupdated: 'aktualisierte die @:labels.page',
            },
        },
    },
    name: 'ProjectUpdate',
    props: ['object', 'objectIndex', 'highlightCommentID', 'space'],
    data() {
        return {
            timer: null,
            currentIndex: 0,
            imageURL: '',
            project: null,
            author: null,
            me: this.$store.state.me,
            admin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),

            deleting: false,
            deleted: false,

            announcing: false,
            announced: false,

            comments: [],
            showComments: false,
            commentCount: 0,
            slide: 0,
            updateObject: null,
            links: [],
        };
    },

    created() {
        this.updateObject = JSON.parse(JSON.stringify(this.object));
        this.getProject(this.object.ProjectID);
        this.getAuthor(this.object.UserID);
        this.findAllLinks();
    },

    mounted() {
        const that = this;
        EventBus.$on(
            'UPDATEEDITSAVED',
            (payLoad) => {
                that.getProjectUpdate();
            },
            that
        );
    },
    methods: {
        findAllLinks() {
            if (!this.updateObject.Text) {
                return;
            }

            const regex =
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
            const str = this.updateObject.Text;
            let m;

            while ((m = regex.exec(str)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    this.links.push(m[0]);
                });
            }
        },

        makeAnnouncement(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            this.announcing = true;

            axios({
                method: 'POST',
                url: `/admin/community/project/update/send-announcement/${this.updateObject.ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.announcing = false;
                    this.announced = true;
                    this.$bvModal.hide(`announcement-modal${this.updateObject.ID}`);
                    const message = {
                        Message: 'Announcement was successfuly sent',
                        Details: null,
                    };
                    EventBus.$emit('INFO', message);
                    this.updateObject = response.data;
                })
                .catch((error) => {
                    this.announcing = false;
                    console.log(error);
                });
        },
        getProjectUpdate() {
            axios({
                method: 'GET',
                url: `/project/update/by-id/${this.object.ID}`, // its an id not slug
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.updateObject = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                });
        },
        showEditModal() {
            this.$refs[`EditProjectUpdateModal${this.updateObject.ID}`].show();
        },
        hideEditModal() {
            this.$refs[`EditProjectUpdateModal${this.updateObject.ID}`].hide();
        },

        showSlider(index) {
            this.slide = index;
            this.$bvModal.show(`slider${this.updateObject.ID}`);
        },

        getProject(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listProjectsByIDs', { ids })
                .then((response) => {
                    this.project = response.Projects[0];
                })
                .catch((error) => {
                    console.log('Project not found\n');
                });
        },

        getAuthor(id) {
            if (id == null || id === '') {
                return;
            }
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response && response.Users) {
                        this.author = response.Users[0];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },

        deleteUpdate(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();

            this.deleting = true;
            const req = JSON.stringify(this.object);
            const action = this.admin ? 'adminDeleteProjectUpdate' : 'deleteProjectUpdate';
            this.$store
                .dispatch(action, req)
                .then((response) => {
                    setTimeout(() => {
                        this.deleting = false;
                        this.deleted = true;
                        const message = {
                            Message: 'Update deleted',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        // EventBus.$emit('PURGEUPDATE', {"objectIndex": this.objectIndex});
                        this.$bvModal.hide(`deleting-modal${this.object.ID}`);

                        // check here what the current route is and handle accordingly!
                        if (this.$router.currentRoute.name.indexOf('DetailView') > -1) this.$router.push('/feed');
                    }, 2000);
                })
                .catch((error) => {
                    this.deleting = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not delete update. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
    },
};
</script>
