<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.project-header-image {
    object-fit: cover;
}

.user-select {
    // border-radius: 36px;
    // border: 1px solid @ngrey;
    // margin-left: 16px;
    // margin-top: 5px;
}

.overflow_visible {
    overflow: visible !important;
}

.round-unify {
    border-radius: 8px;
}

// TODO

// test on mobile
// finish style, fix that user-select disappears when lcicking on "plus" again and input should be cleared and focused when clicking on plus
// fix: Contributors + Owners kann man mehrfach mit einer Person besetzen
// add user image to the left
</style>

<template>
    <div class="px-2">
        <div class="d-lg-none">
            <b-aspect class="d-lg-none" :aspect="2">
                <div class="position-relative h-100">
                    <img
                        class="project-header-image round-unify position-absolute w-100 d-block img-fluid h-100"
                        :src="this.project.ImageURL"
                        img-blank-color="#ffffff"
                        alt
                    />
                </div>
            </b-aspect>
        </div>
        <div class="container">
            <div class="d-none d-lg-block">
                <b-aspect :aspect="4">
                    <div class="position-relative h-100">
                        <img
                            class="project-header-image round-unify position-absolute w-100 d-block img-fluid h-100"
                            :src="this.project.ImageURL"
                            img-blank-color="#ffffff"
                            alt
                        />
                    </div>
                </b-aspect>
            </div>
            <nav class>
                <div class="row mt-4 mb-2 ml-1 mb-lg-4">
                    <div class="row col-12 col-sm align-items-center ml-1">
                        <CoTippy
                            v-if="!isLoading && !project.Published"
                            :text="
                                $unleash.isEnabled('admin.hidden_pages')
                                    ? $t('unpublishedAdmin')
                                    : $t('unpublishedDefault')
                            "
                        >
                            <div
                                v-if="!isLoading && !project.Published"
                                class="bg-midgrey round-unify my-2 mr-2 flex-shrink-0"
                                @click="this.$router.go(-1)"
                            >
                                <CoIcon name="lock" />
                            </div>
                        </CoTippy>
                        <h1>{{ project.Title }}</h1>
                    </div>
                    <div class="order-2 order-lg-0 col col-auto mt-2 mb-2">
                        <b-button
                            v-if="
                                IAmOwner ||
                                ($unleash.isEnabled('admin.hidden_pages') &&
                                    !IAmOwner &&
                                    $store.state.me.Permissions &&
                                    $store.state.me.Permissions.includes('space_admin') &&
                                    $store.state.me.SpaceID === project.SpaceID)
                            "
                            v-b-modal="'editProjectModal'"
                            variant="outline-primary"
                            class="btn-round mr-2 mt-1 mt-lg-0"
                            alt="Edit"
                        >
                            <b-icon
                                icon="pencil"
                                class="m-0"
                                style="position: relative; bottom: 1px"
                                scale="1"
                            ></b-icon>
                        </b-button>
                        <b-button
                            v-if="
                                !IAmOwner &&
                                $store.state.me.Permissions &&
                                $store.state.me.Permissions.includes('space_admin') &&
                                $store.state.me.SpaceID === project.SpaceID
                            "
                            v-b-modal="'deleteProjectModal'"
                            variant="outline-danger"
                            class="btn-round mr-2 mt-1 mt-lg-0"
                            alt="Delete"
                        >
                            <b-icon icon="trash" class="m-0" style="position: relative; bottom: 1px" scale="1"></b-icon>
                        </b-button>
                        <b-button v-if="this.FollowedByMe === true" v-on:click="unfollow" variant="outline-primary"
                            >{{ $t('labels.following') }}
                        </b-button>
                        <b-button v-else-if="this.FollowedByMe === false" v-on:click="follow" variant="primary"
                            >{{ $t('labels.follow') }}
                        </b-button>
                    </div>
                    <div class="col-12 mb-2" v-if="owners[0]">
                        {{ $t('labels.by') }} {{ owners[0].Profile.Name }}
                        <span v-if="amountContributors > 1" class="project m-0">
                            {{ $t('labels.and') }} {{ $tc('labels.others', amountContributors - 1) }}
                        </span>
                    </div>
                    <div class="col-12 my-2">
                        <a class="pointer" @click="tabIndex = 3" v-if="followers && followers.length > 0">
                            {{ $tc('labels.follower', followers.length) }}
                        </a>
                    </div>
                </div>
            </nav>

            <div class="row">
                <div class="col-np-mobile col-12 col-lg-8">
                    <!-- <b-card no-body> -->
                    <b-tabs justified v-model="tabIndex">
                        <b-tab>
                            <template #title>
                                <span v-if="isDesktop">{{ $t('labels.information') }}</span>
                                <b-icon v-else icon="gem" :title="$t('labels.information')"></b-icon>
                            </template>
                            <b-card>
                                <div>
                                    <div
                                        v-if="project"
                                        class="mb-1"
                                        style="white-space: pre-wrap"
                                        v-html="project.Description"
                                    ></div>
                                </div>
                                <div class="mt-2 d-none d-lg-block">
                                    <!-- <h3 class="mt-6">Tags</h3> -->
                                    <div class="">
                                        <span
                                            v-for="(tag, index) in project.Hashtag"
                                            :key="index"
                                            class="color-2-link mr-2 mb-2"
                                            @click.stop="$router.push('/search?term=' + tag)"
                                            >#{{ tag }}
                                        </span>
                                    </div>

                                    <div>
                                        <!-- first link preview -->
                                        <LinkPreview
                                            v-for="(link, index) in project['Links']"
                                            :key="index"
                                            :url="link"
                                            class="mb-2"
                                        ></LinkPreview>
                                    </div>

                                    <div class="d-none d-lg-block mt-2">
                                        <p v-if="project && project.LastModifyed" class="mb-0">
                                            {{ $t('labels.updatedon') }}
                                            {{
                                                $t('datetime', {
                                                    date: project.LastModifyed,
                                                    format: 'relative',
                                                })
                                            }}
                                        </p>
                                        <p v-if="project && project.Created">
                                            {{ $t('labels.createdon') }}
                                            {{
                                                $t('datetime', {
                                                    date: project.Created,
                                                    format: 'relative',
                                                })
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </b-card>
                        </b-tab>
                        <b-tab title="Updates">
                            <template #title>
                                <span v-if="isDesktop">{{ $t('labels.updates') }}</span>
                                <b-icon v-else icon="newspaper"></b-icon>
                            </template>
                            <!-- <b-card> -->
                            <!-- update create input -->
                            <div class="card mb-3" v-if="IAmOwner || IAmContributor">
                                <div
                                    class="
                                        border-secondary
                                        bg-white
                                        p-3
                                        d-flex
                                        w-100
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <div class="mr-3">
                                        <img
                                            v-if="this.$store.state.me.Profile.ImageURL"
                                            v-bind:src="this.$store.state.me.Profile.ImageURL"
                                            alt=""
                                            class="hfn-user-avatar-img"
                                            v-on:click="viewUser(this.$store.state.me.Profile.Slug)"
                                        />
                                        <span
                                            v-else
                                            class="hfn-user-avatar-img white-bg"
                                            v-on:click="viewUser(this.$store.state.me.Profile.Slug)"
                                        ></span>
                                    </div>
                                    <b-form-input
                                        class="rounded-pill"
                                        :placeholder="$t('placeholders.updatecreate')"
                                        @click="showModal"
                                    ></b-form-input>
                                </div>
                            </div>
                            <!-- update create input -->

                            <CoPost
                                v-for="(post, key) in updates"
                                v-bind:key="key"
                                :post="{
                                    ID: post.object.ID,
                                    Type: 'project-update',
                                    AuthorID: post.object.UserID,
                                    Author: post.object.Author,
                                    Text: post.object.Text,
                                    Slug: post.object.Slug,
                                    CreatedAt: post.object.Created,
                                    UpdatedAt: post.object.Updated,
                                    AnnouncedBy: post.space,
                                    SentAnnouncement: post.object.SentAnnouncement,
                                    Images: map(get(post, 'object.ImageURLs', []), (i) => ({ ImageURL: i })),
                                    SpaceID: post.object.SpaceID,
                                    Space: post.space,
                                    ParentContent: post.object.ParentContent,
                                    Parent: post.object.Parent,
                                    FeaturedContent: post.object.FeaturedContent,
                                    Featured: post.object.Featured,
                                    Reactions: post.object.Reactions,
                                    ProjectID: post.object.ProjectID,
                                }"
                                class="mb-3"
                                :me="{
                                    ID: me.ID,
                                    Name: get(me, 'Profile.Name', ''),
                                    Slug: me.Slug,
                                    ImageURL: get(me, 'Profile.ImageURL', null),
                                }"
                                @editPost="editPost(post)"
                            />

                            <b-card v-if="updates.length === 0"> {{ $t('noupdates') }}</b-card>

                            <!-- </b-card> -->
                        </b-tab>
                        <b-tab title="Comments">
                            <template #title>
                                <span v-if="isDesktop">{{ $t('labels.comments') }}</span>
                                <b-icon v-else icon="chat-square-dots"></b-icon>
                            </template>
                            <b-card>
                                <!-- post comments -->
                                <CoComments
                                    v-if="project.ID"
                                    class="px-3 mt-2"
                                    :Me="{
                                        ID: me.ID,
                                        Name: get(me, 'Profile.Name', ''),
                                        Slug: me.Slug,
                                        ImageURL: get(me, 'Profile.ImageURL', null),
                                    }"
                                    :PostID="project.ID"
                                    post-type="project"
                                    :PostSlug="project.Slug"
                                />
                            </b-card>
                        </b-tab>
                        <b-tab title="Followers">
                            <template #title>
                                <span v-if="isDesktop">{{ $t('labels.followers') }}</span>
                                <b-icon v-else icon="person-check"></b-icon>
                            </template>
                            <b-card v-if="followers && followers.length !== 0">
                                <div v-for="(follower, i) in followers" :key="i">
                                    <CommunityCard
                                        :object="{ object: follower, type: 'user' }"
                                        class="mb-0"
                                    ></CommunityCard>
                                </div>
                            </b-card>
                            <b-card class="text-center" v-else>
                                <illustration type="community" class="my-0 illu-small"></illustration>
                                <div class="mb-4">{{ $t('nofollowers') }}</div>
                            </b-card>
                        </b-tab>
                    </b-tabs>
                    <!-- </b-card> -->
                </div>

                <!-- here starts project side menu -->
                <div class="col-np-mobile col-12 col-lg-4 mb-4 mt-4">
                    <b-card :header="$t('aboutthepage')" header-class="d-none d-lg-block" style="overflow: visible">
                        <div>
                            <h3>{{ $t('labels.owners') }}</h3>
                            <small v-if="IAmOwner" class="my-1">{{ $t('ownersexpl') }}</small>
                            <ul v-if="owners" class="hfv-project-users-list">
                                <transition-group name="slide-fade">
                                    <li v-for="owner in owners" :key="owner.ID" class="hfv-project-users-list-item">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <ProfileCircle
                                                :ImageURL="owner.Profile.ImageURL"
                                                :Slug="owner.Slug"
                                                :Name="owner.Profile.Name"
                                            ></ProfileCircle>
                                            <span
                                                v-if="owner.ID !== project.UserID && IAmOwner"
                                                v-on:click="removeOwner(owner)"
                                                ><b-icon font-scale="1.2" icon="person-x"></b-icon
                                            ></span>
                                        </div>
                                    </li>
                                </transition-group>
                            </ul>
                            <span v-else>{{ $t('ownerishidden') }}</span>

                            <div v-if="IAmOwner" class="hfv-project-users-list-item">
                                <b-dropdown
                                    variant="outline-primary btn-round"
                                    menu-class="round-unify-xs  mt-1 p-3 w-100"
                                    block
                                    no-caret
                                    no-flip
                                    @shown="ownersSearchStart"
                                    id="add-owner-tooltip"
                                >
                                    <template v-slot:button-content>
                                        <b-icon
                                            icon="person-plus"
                                            class="m-0"
                                            scale=".8"
                                            style="position: relative; bottom: 1px"
                                        ></b-icon>
                                    </template>

                                    <input
                                        type="text"
                                        ref="ownersSearchInput"
                                        @input="usersSearchResult"
                                        placeholder="search name"
                                        class="form-control rounded-pill d-block"
                                    />
                                    <div class="user-select" v-if="users.length > 0">
                                        <b-dropdown-item
                                            v-for="user in users"
                                            @click="selectOwner(user)"
                                            :key="user.ID"
                                            link-class="p-0"
                                            class="my-2 round-unify-xs"
                                        >
                                            <ProfileCircle
                                                :ImageURL="user.Profile.ImageURL"
                                                :Name="user.Profile.Name"
                                            ></ProfileCircle>
                                        </b-dropdown-item>
                                    </div>
                                </b-dropdown>
                                <b-tooltip target="add-owner-tooltip__BV_toggle_" bottom triggers="hover">
                                    {{ $t('labels.ownersadd') }}
                                </b-tooltip>
                            </div>
                        </div>
                        <div>
                            <h3 style="margin-top: 35px">{{ $t('labels.contributers') }}</h3>
                            <small v-if="IAmOwner" class="my-1">{{ $t('contributersexpl') }}</small>

                            <ul v-if="owners" class="hfv-project-users-list">
                                <transition-group name="slide-fade">
                                    <li
                                        v-for="contributor in contributors"
                                        :key="contributor.ID"
                                        class="hfv-project-users-list-item"
                                    >
                                        <div class="d-flex justify-content-between align-items-center">
                                            <ProfileCircle
                                                :ImageURL="contributor.Profile.ImageURL"
                                                :Slug="contributor.Slug"
                                                :Name="contributor.Profile.Name"
                                            ></ProfileCircle>
                                            <span
                                                v-if="contributor.ID !== project.UserID && IAmOwner"
                                                v-on:click="removeContributor(contributor)"
                                                ><b-icon font-scale="1.2" icon="person-x"></b-icon
                                            ></span>
                                        </div>
                                    </li>
                                </transition-group>
                            </ul>

                            <div v-if="IAmOwner" class="hfv-project-users-list-item">
                                <b-dropdown
                                    variant="outline-primary btn-round"
                                    menu-class="round-unify-xs  mt-1 p-3 w-100"
                                    block
                                    no-caret
                                    no-flip
                                    @shown="contributorsSearchStart"
                                    id="add-contributor-tooltip"
                                >
                                    <template v-slot:button-content>
                                        <b-icon
                                            icon="person-plus"
                                            class="m-0"
                                            scale=".8"
                                            style="position: relative; bottom: 1px"
                                        ></b-icon>
                                    </template>

                                    <input
                                        type="text"
                                        ref="contributorsSearchInput"
                                        @input="usersSearchResult"
                                        placeholder="search name"
                                        class="form-control rounded-pill d-block"
                                    />
                                    <div class="user-select" v-if="users.length > 0">
                                        <b-dropdown-item
                                            v-for="user in users"
                                            @click="selectContributor(user)"
                                            :key="user.ID"
                                            link-class="p-0"
                                            class="my-2 round-unify-xs"
                                        >
                                            <ProfileCircle
                                                :ImageURL="user.Profile.ImageURL"
                                                :Name="user.Profile.Name"
                                            ></ProfileCircle>
                                        </b-dropdown-item>
                                    </div>
                                </b-dropdown>
                                <b-tooltip target="add-contributor-tooltip__BV_toggle_" bottom triggers="hover">
                                    {{ $t('labels.contributersadd') }}
                                </b-tooltip>
                            </div>
                        </div>

                        <div class="d-none d-lg-block mt-4">
                            <h3 class="" v-if="project.Description">{{ $t('labels.description') }}</h3>
                            <p v-if="project && project.Description" class="mt-2 mb-1">
                                {{ project.Description.substring(0, 120)
                                }}<span v-if="project.Description.length > 120">...</span>
                                <a href="#" @click="tabIndex = 0">&nbsp;{{ $t('labels.readmore') }}</a>
                            </p>
                        </div>
                        <div class="mt-4" v-if="project.Hashtag">
                            <h3 class="">
                                {{
                                    this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                        ? $t('labels.topics')
                                        : $t('labels.tags')
                                }}
                            </h3>
                            <div class="">
                                <span
                                    v-for="(tag, index) in project.Hashtag"
                                    :key="index"
                                    class="color-2-link mr-2 mb-2"
                                    @click.stop="$router.push('/search?term=' + tag)"
                                    >#{{ tag }}
                                </span>
                            </div>
                        </div>
                        <div class="mt-4">
                            <h3 v-if="project && project['Links']" class="mt-4">{{ $t('labels.links') }}</h3>
                            <div v-if="project && project['Links']" class="">
                                <LinkPreviewMini
                                    v-for="(link, index) in project['Links']"
                                    :key="index"
                                    :url="link"
                                    class="mb-2"
                                ></LinkPreviewMini>
                            </div>
                        </div>
                        <div class="d-lg-none mt-4">
                            <p v-if="project && project.LastModifyed" class="mb-0">
                                {{ $t('labels.updatedon') }}
                                {{
                                    $t('datetime', {
                                        date: project.LastModifyed,
                                        format: 'relative',
                                    })
                                }}
                            </p>
                            <p v-if="project && project.Created">
                                {{ $t('labels.createdon') }}
                                {{
                                    $t('datetime', {
                                        date: project.Created,
                                        format: 'relative',
                                    })
                                }}
                            </p>
                        </div>
                    </b-card>
                </div>
                <!-- <div class="col-1"></div> -->
            </div>
        </div>

        <!-- modals -->

        <b-modal
            v-if="
                IAmOwner ||
                ($unleash.isEnabled('admin.hidden_pages') &&
                    !IAmOwner &&
                    $store.state.me.Permissions &&
                    $store.state.me.Permissions.includes('space_admin') &&
                    $store.state.me.SpaceID === project.SpaceID)
            "
            id="editProjectModal"
            ref="editProjectModal"
            size="lg"
            :title="$t('labels.pageedit')"
            hide-footer
        >
            <template v-slot:default>
                <ProjectEdit :project="project" @saved="saveProjectEdit"></ProjectEdit>
            </template>
        </b-modal>

        <b-modal
            v-if="isAdmin"
            id="deleteProjectModal"
            ref="deleteProjectModal"
            size="sm"
            title="Delete project"
            centered
            @ok="deleteProjectAdmin"
            ok-title="Delete"
            ok-variant="primary"
            :busy="deleting"
        >
            <b-overlay :show="deleting">
                <p class="mb-1">Once you delete a page, there is no going back. Please be certain.</p>
            </b-overlay>
        </b-modal>

        <b-modal
            id="createProjectUpdateModal"
            ref="createProjectUpdateModal"
            size="lg"
            :title="$t('labels.updatecreate')"
            no-fade
            modal-class="high-z-index"
            hide-footer
            centered
            class="overflow_visible"
        >
            <template v-slot:default>
                <CoAlert
                    v-if="!project.Published"
                    variant="grey"
                    icon="lock"
                    :size="$isMobile ? 'md' : 'sm'"
                    class="mb-3"
                    :text="$unleash.isEnabled('admin.hidden_pages') ? $t('unpublishedAdmin') : $t('unpublishedDefault')"
                />
                <CreateProjectUpdate v-bind:projectID="project.ID"></CreateProjectUpdate>
            </template>
        </b-modal>

        <!-- modals -->
        <CoPostEditor
            ref="posteditor"
            :user="{
                ...get($store, 'state.me', {}),
                ...get($store, 'state.me.Profile', {}),
            }"
            @updated="editedPost"
            editMode
        />
    </div>
</template>
<style></style>
<script>
import Vue from 'vue';
import { cloneDeep, get, map, uniq } from 'lodash';
import Router from 'vue-router';
import VueMoment from 'vue-moment';
import axios from 'axios';
import linkify from 'vue-linkify';
import { BCarousel } from 'bootstrap-vue';
import { debug } from 'velocity-animate';
import i18n from 'vue-i18n';
import ProjectEdit from '@/components/old-components/ProjectEdit.vue';
import LinkPreviewMini from '@/components/old-components/LinkPreviewMini.vue';
import CommentSection from '@/components/old-components/CommentSection.vue';
import CommunityCard from '@/components/cards/CommunityCard.vue';
import Illustration from '@/components/old-components/Illustration.vue';
import ProfileCircle from '@/components/old-components/ProfileCircle.vue';
import CreateProjectUpdate from '@/components/old-components/CreateProjectUpdate.vue';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoPostEditor from '@/components/Organisms/co-post-editor/coPostEditor.vue';

import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoComments from '@/components/Organisms/co-comments/coComments.vue';
import EventBus from '../../eventBus';

const debounce = require('debounce');
const moment = require('moment');

Vue.component('BCarousel', BCarousel);

Vue.directive('linkified', linkify);

Vue.use(require('vue-moment'), {
    moment,
});

Vue.use(Router, VueMoment, {
    moment,
});

export default {
    components: {
        CoPost,
        CoIcon,
        CreateProjectUpdate,
        ProfileCircle,
        Illustration,
        CommunityCard,
        CommentSection,
        LinkPreviewMini,
        ProjectEdit,
        CoAlert,
        CoTippy,
        CoComments,
        CoPostEditor,
    },
    i18n: {
        messages: {
            en: {
                aboutthepage: 'About the page',
                contributersexpl: 'Contributors can only create updates',
                nofollowers: 'No followers yet. Be the first one.',
                noupdates: 'No updates posted yet.',
                ownersexpl: 'Owners can add/remove owners and contributors, and create updates',
                ownerishidden: "Owner's profile is hidden",
                unpublishedAdmin: 'Only admins and page members see this.',
                unpublishedDefault: 'Only page members see this.',
            },
            de: {
                aboutthepage: 'Über diese @:labels.page',
                contributersexpl: '@:labels.contributers können nur @:labels.updates erstellen',
                nofollowers: 'Noch keine Follower. Mach den Anfang.',
                noupdates: 'Noch keine @:labels.updates veröffentlicht.',
                ownersexpl:
                    '@:labels.owners können @:labels.owners und @:labels.contributers hinzufügen/entfernen und @:labels.updates erstellen',
                ownerishidden: 'Das Profil des @:labels.owner ist nicht sichtbar.',
                unpublishedAdmin: 'Nur Admins und Seitenmitglieder sehen dies.',
                unpublishedDefault: 'Nur Seitenmitglieder sehen dies.',
            },
        },
    },
    name: 'ViewProject',
    data() {
        return {
            tabIndex: this.$route.query.commentid != null ? 2 : 0,
            project: {},
            FollowedByMe: false,
            IAmOwner: false,
            IAmContributor: false,
            followers: [],
            owners: [],
            contributors: [],
            display: 'description',
            updates: [],
            updatesNextPage: null,
            authors: {},
            loadedAuthors: false,
            update: {},
            imageURL: '',
            isLoading: true,
            attachment: null,
            preview: null,
            deleting: false,
            prevRoute: null,
            isAdmin: false,
            projectHeaderUrl: null,
            users: [],
            contributorsSearch: false,
            contributorsSearchResult: [],
            currentFilteredList: null,

            windowWidth: 0,
            amountContributors: 1,
            isCirclesOn: this.$store.state.circlesOn,
            nextParams: null,

            me: this.$store.state.me,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    beforeCreate() {
        const { slug } = this.$route.params;
        this.$store
            .dispatch('getProjectBySlug', slug)
            .then((response) => {
                this.project = response;

                // const created = new Date(this.project.Created * 1000);
                // const updated = new Date(this.project.LastModifyed * 1000);
                // this.project.Created = moment(created).format('DD.MM.YY');
                // this.project.LastModifyed = moment(updated).format('DD.MM.YY');

                if (this.project.FollowedBy) {
                    this.FollowedByMe = this.project.FollowedBy.indexOf(this.$store.state.me.ID) > -1;
                }
                if (this.project.Owner != null) {
                    this.IAmOwner = this.project.Owner.indexOf(this.$store.state.me.ID) > -1;
                }
                if (this.project.Contributor != null) {
                    this.IAmContributor = this.project.Contributor.indexOf(this.$store.state.me.ID) > -1;
                }
                this.getFollowers();
                this.getOwners();
                this.getContributors();
                this.getUpdates();
            })
            .catch((error) => {
                if (error.message.includes('404')) {
                    this.$router.replace('/feed/error404');
                }
            });
    },

    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
        this.windowWidth = window.innerWidth;

        this.isAdmin = this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin');

        this.scroll();
        const that = this;
        EventBus.$on('NEWUPDATE', (payLoad) => {
            const createModal = that.$refs.createProjectUpdateModal;
            if (createModal) {
                createModal.hide();
            }

            const editModal = that.$refs.editProjectModal;
            if (editModal) {
                editModal.hide();
            }
            that.updatesNextPage = null;
            that.updates = [];
            that.getUpdates();
        });

        EventBus.$on('PURGEUPDATE', (payLoad) => {
            that.updates = [];
            that.getUpdates();
        });

        EventBus.$on('CancelProjectEdit', (payLoad) => {
            that.$refs.editProjectModal.hide();
        });
    },
    computed: {
        isDesktop() {
            const is = this.windowWidth >= 786;
            return is;
        },
    },
    methods: {
        map,
        get,
        editPost(post = {}) {
            if (!post) return;
            // map post structure to editor structure
            const postToEdit = {
                ...get(post, 'object', {}),
                Files: map(get(post, 'object.ImageURLs', []), (i) => ({ url: i })),
                Type: 'project-update',
                wasUpdated: true,
            };
            this.$refs.posteditor.show(postToEdit, true);
        },
        editedPost(post = {}) {
            const that = this;
            if (post && this.updates) {
                post['wasUpdated'] = true;
                // find the post in the feed by id and update it
                const index = this.updates.findIndex((p) => get(p, 'object.ID') === get(post, 'object.ID'));
                if (index > -1) {
                    that.updates.splice(index, 1, post);
                }
            }
        },
        contributorsSearchStart() {
            this.$refs.contributorsSearchInput.value = '';
            this.$refs.contributorsSearchInput.focus();
            this.users = [];
            this.currentFilteredList = this.contributors;
        },
        ownersSearchStart() {
            this.$refs.ownersSearchInput.value = '';
            this.$refs.ownersSearchInput.focus();
            this.users = [];
            this.currentFilteredList = this.owners;
        },
        usersSearchResult: debounce(function (e) {
            if (e.target.value === '') {
                this.users = [];
                return false;
            }

            axios({
                method: 'GET',
                url: `/search/user/circles/${e.target.value}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.users = [];
                        response.data.forEach(function (entry) {
                            if (this.$store.state.me.ID === entry.ID) return;

                            const match = this.currentFilteredList.filter((element) => element.ID === entry.ID, this);
                            if (match.length > 0) return;

                            this.users.push(entry);
                        }, this);
                    }
                })
                .catch((error) => {});
        }, 200),

        selectOwner(owner) {
            this.$data.owners.push(owner);
            let newOwner = {
                ProjectID: this.project.ID,
                UserID: owner.ID,
            };
            newOwner = JSON.stringify(newOwner);

            this.$store.dispatch('addOwner', newOwner).then((response) => {
                this.project = response;
                // this.getOwners();
                this.countAmountContributors();
                this.searchInput = '';
                this.users = [];
            });
        },
        countAmountContributors() {
            let contributorArray = this.contributors;
            const ownerArray = this.owners;
            // concat without duplicates
            contributorArray = contributorArray.concat(ownerArray);
            const uniqueArray = contributorArray.filter((item, index) => {
                const _item = JSON.stringify(item);
                return index === contributorArray.findIndex((obj) => JSON.stringify(obj) === _item);
            });
            this.amountContributors = uniqueArray.length;
        },
        selectContributor(contributor) {
            this.$data.contributors.push(contributor);
            let newContributor = {
                ProjectID: this.project.ID,
                UserID: contributor.ID,
            };
            newContributor = JSON.stringify(newContributor);

            this.$store.dispatch('addContributor', newContributor).then((response) => {
                this.project = response;
                // this.getContributors();
                this.countAmountContributors();
                this.searchInput = '';
                this.users = [];
            });
        },

        removeOwner(owner) {
            let newOwner = {
                ProjectID: this.project.ID,
                UserID: owner.ID,
            };
            newOwner = JSON.stringify(newOwner);

            this.owners = this.owners.filter((entry) => entry.ID !== owner.ID);

            this.$store.dispatch('removeOwner', newOwner).then((response) => {
                this.project = response;
                // this.getOwners();
                this.countAmountContributors();
            });
        },
        removeContributor(contributor) {
            let newContributor = {
                ProjectID: this.project.ID,
                UserID: contributor.ID,
            };
            newContributor = JSON.stringify(newContributor);

            this.contributors = this.contributors.filter((entry) => entry.ID !== contributor.ID);

            this.$store.dispatch('removeContributor', newContributor).then((response) => {
                this.project = response;
                // this.getContributors();
                this.countAmountContributors();
            });
        },

        saveProjectEdit() {
            this.$store.dispatch('getProjectBySlug', this.project.Slug).then((response) => {
                this.project = response;
                this.$refs.editProjectModal.hide();
            });
            this.$refs.editProjectModal.hide();
        },

        prev() {
            this.$refs.myCarousel.prev();
        },
        next() {
            this.$refs.myCarousel.next();
        },
        viewUser(id) {
            this.$router.push(`/profile/${id}`);
        },
        getFollowers() {
            const ids = [];
            if (this.project.FollowedBy) {
                this.project.FollowedBy.forEach((entry) => {
                    ids.push({ ID: entry });
                }, ids);
                let ownersIDs = { IDS: ids };
                ownersIDs = JSON.stringify(ownersIDs);
                this.$store.dispatch('listUsersByIDs', { ids: ownersIDs }).then((response) => {
                    this.followers = response.Users;
                });
            }
        },
        getOwners() {
            const ids = [];
            this.project.Owner.forEach((entry) => {
                ids.push({ ID: entry });
            }, ids);
            let ownersIDs = { IDS: ids };
            ownersIDs = JSON.stringify(ownersIDs);
            this.$store.dispatch('listUsersByIDs', { ids: ownersIDs }).then((response) => {
                this.owners = response.Users;
                const that = this;
                this.owners.sort((a, b) => {
                    if (that.$store.state.me.ID === a.ID) return -2;

                    return 0;
                });

                this.countAmountContributors();
            });
        },
        getContributors() {
            const ids = [];
            this.project.Contributor.forEach((entry) => {
                ids.push({ ID: entry });
            }, ids);
            let ownersIDs = { IDS: ids };
            ownersIDs = JSON.stringify(ownersIDs);
            this.$store.dispatch('listUsersByIDs', { ids: ownersIDs }).then((response) => {
                this.contributors = response.Users;
                this.countAmountContributors();
            });
        },
        getUpdates() {
            this.isLoading = true;
            if (this.isCirclesOn) {
                const data = {
                    ProjectID: this.project.ID,
                    Params: this.nextParams,
                };

                this.$store
                    .dispatch('getUpdatesCircle', data)
                    .then(async (response) => {
                        if (response.Objects) {
                            response.Objects.forEach(function (entry, index) {
                                this.updates.push(entry);
                            }, this);

                            if (response.Objects.NextParams) {
                                this.nextParams = response.data.NextParams;
                            } else {
                                this.nextParams = null;
                            }
                            // sort by date
                            this.updates.sort((a, b) => b.object.Created - a.object.Created);

                            // remove duplicates by object.id
                            this.updates = this.updates.filter(
                                (thing, index, self) => index === self.findIndex((t) => t.object.ID === thing.object.ID)
                            );

                            const fetchUsers = [];
                            this.updates.forEach((update) => {
                                fetchUsers.push(update.object.UserID);
                            });
                            let users = null;
                            try {
                                users = await axios({
                                    method: 'POST',
                                    url: '/user/listbyids',
                                    data: { IDS: map(uniq(fetchUsers), (id) => ({ ID: id })) },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });

                                users = map(users.data.Users, (user) => ({
                                    ID: get(user, 'ID'),
                                    Name: get(user, 'Profile.Name'),
                                    Slug: get(user, 'Slug'),
                                    ImageURL: get(user, 'Profile.ImageURL'),
                                    Bio: get(user, 'Profile.Bio'),
                                }));
                            } catch (error) {
                                console.log(error);
                            }

                            this.updates.forEach((update) => {
                                if (!update.object) return;

                                if (update.object.AuthorID || update.object.CreatedBy || update.object.UserID) {
                                    update.object.Author = users.find(
                                        (user) =>
                                            user.ID === update.object.AuthorID ||
                                            user.ID === update.object.CreatedBy ||
                                            user.ID === update.object.UserID
                                    );
                                }

                                update.object.CreatedAt = update.object.Created;
                            }, this);
                            // because updates are present, open the update tab by default
                            this.openUpdateTab();
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        if (this.$route.query.commentid) {
                            this.tabIndex = 2;
                        }
                        this.isLoading = false;
                        console.log(error);
                    });
            } else {
                let params = '';
                if (this.updatesNextPage == null && this.updates.length === 0) {
                    params = `pid=${this.project.ID}`;
                } else if (this.updatesNextPage != null) {
                    params = this.updatesNextPage;
                } else {
                    return;
                }
                this.$store
                    .dispatch('getUpdates', params)
                    .then(async (response) => {
                        if (response.objects) {
                            response.objects.forEach(function (entry, index) {
                                this.updates.push(entry);
                            }, this);

                            if (response.next !== '' && response.next != null) {
                                this.updatesNextPage = response.next;
                            } else {
                                this.updatesNextPage = null;
                            }
                            // sort by date
                            this.updates.sort((a, b) => b.object.Created - a.object.Created);

                            // remove duplicates by object.id
                            this.updates = this.updates.filter(
                                (thing, index, self) => index === self.findIndex((t) => t.object.ID === thing.object.ID)
                            );
                            const fetchUsers = [];
                            this.updates.forEach((update) => {
                                fetchUsers.push(update.object.UserID);
                            });
                            let users = null;

                            try {
                                users = await axios({
                                    method: 'POST',
                                    url: '/user/listbyids',
                                    data: { IDS: map(uniq(fetchUsers), (id) => ({ ID: id })) },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                }).catch((error) => {
                                    console.log(error);
                                });

                                users = map(users.data.Users, (user) => ({
                                    ID: get(user, 'ID'),
                                    Name: get(user, 'Profile.Name'),
                                    Slug: get(user, 'Slug'),
                                    ImageURL: get(user, 'Profile.ImageURL'),
                                    Bio: get(user, 'Profile.Bio'),
                                }));
                            } catch (error) {
                                console.log(error);
                            }

                            this.updates.forEach((update) => {
                                // skip if entry.object does not exist
                                if (!update.object) return;
                                if (update.object.AuthorID || update.object.CreatedBy || update.object.UserID) {
                                    update.object.Author = users.find(
                                        (user) =>
                                            user.ID === update.object.AuthorID ||
                                            user.ID === update.object.CreatedBy ||
                                            user.ID === update.object.UserID
                                    );
                                }
                                update.object.CreatedAt = update.object.Created;
                            }, this);

                            // because updates are present, open the update tab by default
                            this.openUpdateTab();
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        if (this.$route.query.commentid) {
                            this.tabIndex = 2;
                        }
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        },
        openUpdateTab() {
            if (!this.$route.query.commentid) {
                this.tabIndex = 1;
            } else {
                this.tabIndex = 2;
            }
        },

        follow() {
            const subs = JSON.stringify({ ProjectID: this.project.ID });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.project.FollowedBy = response.data.FollowedBy;
                    this.FollowedByMe = true;
                    if (this.followers) {
                        this.followers.push(this.$store.state.me);
                    } else {
                        this.followers = [this.$store.state.me];
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        unfollow() {
            const subs = JSON.stringify({ ProjectID: this.project.ID });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.project.FollowedBy = response.data.FollowedBy;
                    this.FollowedByMe = false;
                    this.followers = this.followers.filter((item) => item.ID !== this.$store.state.me.ID);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        showModal(event) {
            event.target.blur();
            this.$refs.createProjectUpdateModal.show();
        },

        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 30) {
                    if (this.isCirclesOn && this.nextParams != null && !this.loading) {
                        this.getUpdates();
                    } else if (this.updatesNextPage != null && !this.isLoading) {
                        this.getUpdates();
                    }
                }
            };
        },

        deleteProjectAdmin(bvModalEvt) {
            // Prevent modal from closing
            // bvModalEvt.preventDefault()

            this.showOverlay = true;
            this.deleting = true;
            const req = JSON.stringify({
                ProjectID: this.project.ID,
            });
            let slug = '';
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        this.channelList = response.data.objects.map((channel) => channel.object);
                    }
                    if (!this.channelList) {
                        return slug;
                    }
                    const channelList = JSON.parse(JSON.stringify(this.channelList));
                    const channel = channelList.find((c) => c.ID === this.project.ChannelID);
                    if (channel && channel.Slug) {
                        slug = channel.Slug;
                    }
                    return slug;
                })
                .catch((error) => {
                    console.log(error);
                });
            this.$store
                .dispatch('deleteProjectAdmin', req)
                .then(() => {
                    const route = `/channels/${slug}`;
                    setTimeout(() => {
                        this.deleting = false;
                        const message = {
                            Message: 'Project deleted',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        this.$router.push(route);
                        this.$bvModal.hide('deleteProjectModal');
                    }, 2000);
                })
                .catch((error) => {
                    this.deleting = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not delete project. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
    },
    directives: {
        focusAndClear: {
            // directive definition
            inserted(el) {
                el.focus();
                el.innerHTML = '';
            },
        },
    },
};
</script>
