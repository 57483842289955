<template>
    <div v-if="!deleted" class="d-flex mb-2 mb-md-3 feedpost">
        <b-card header-tag="header" footer-tag="footer" class="d-inline w-100">
            <template v-slot:header>
                <div v-if="$store.state.space.ID != object.SpaceID" class="d-flex align-items-center mb-2">
                    <CoThumbnail
                        :ImageURL="space.FaviconURL"
                        :Clickable="false"
                        :color="space.PrimaryColor"
                        size="sm"
                        class="mr-2"
                    ></CoThumbnail>
                    <CoText type="p3" variant="mute" class="mb-0">{{ space.Name }}</CoText>
                </div>

                <div v-if="author" class="d-flex align-items-top">
                    <div v-if="author" class="">
                        <ProfileCircle
                            v-if="author"
                            :ImageURL="author.Profile.ImageURL"
                            :Slug="author.Slug"
                        ></ProfileCircle>
                    </div>
                    <div v-if="author" class="ml-2 d-flex flex-column justify-content-center">
                        <span class="d-flex align-items-center">
                            <p2>
                                <router-link :to="'/profile/' + author.Slug">{{ author.Profile.Name }}</router-link>
                            </p2>
                            <p3 class="ml-2">
                                <coDate :date="object.CreatedAt" format="relative" />
                            </p3>
                        </span>
                        <p3 v-if="author.Profile.Bio">{{ author.Profile.Bio }}</p3>
                    </div>
                    <div class="flex-grow-1"></div>
                    <div v-if="(author != null && me != null && me.ID == author.ID) || admin">
                        <b-dropdown
                            variant="ml-2 px-2"
                            menu-class="mt-3 border-1"
                            no-caret
                            right
                            style="position: relative; bottom: 7px; left: 12px"
                        >
                            <template v-slot:button-content>
                                <b-icon icon="three-dots-vertical" class="m-0" scale="1.2"></b-icon>
                            </template>
                            <b-dropdown-item
                                v-if="admin && object['SentAnnouncement'] != true"
                                v-b-modal="'announcement-modal' + object.ID"
                            >
                                <b-icon scale="0.8" icon="bell"></b-icon>
                                Make announcement
                            </b-dropdown-item>
                            <b-dropdown-item v-if="admin && object['SentAnnouncement'] === true" disabled>
                                <b-icon scale="0.8" icon="bell"></b-icon>
                                Announcement sent by
                                {{ object['AnnouncedBy'] }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="me.ID == author.ID" @click="editPost">
                                <b-icon scale="0.8" icon="pencil-square"></b-icon>
                                Edit
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="me.ID == author.ID || me.Permissions.includes('space_admin')"
                                v-b-modal="'deleting-modal' + object.ID"
                            >
                                <b-icon scale="0.8" icon="trash"></b-icon>
                                Delete
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </template>
            <div v-if="object.type == 'post'">
                <h5>{{ object.Title }}</h5>
            </div>

            <div class="m-0">
                <template v-if="object.Version == 1">
                    <b-card-text
                        v-if="object.Text"
                        ref="postText"
                        v-html="object.Text"
                        v-linkifytiptap
                        v-bind:class="{ textNotVisible: textNotVisible }"
                    ></b-card-text>
                </template>
                <template v-else>
                    <b-card-text
                        v-if="object.Text"
                        ref="postText"
                        v-html="object.Text"
                        v-bind:class="{ textNotVisible: textNotVisible }"
                    ></b-card-text>
                </template>
            </div>
            <div v-if="toggleReadMore" class="mt-1 readbtn">
                <a class="m-0 p-0" v-on:click="readMoreText()">{{ $t('labels.readmore') }}</a>
            </div>
            <div v-if="toggleReadLess" class="readbtn">
                <a class="m-0 p-0" v-on:click="readLessText()">{{ $t('labels.readless') }}</a>
            </div>

            <div class="photos mt-3" v-if="object['Images'] && object['Images'][0]">
                <a @click="showSlider(0)" class="d-block pointer">
                    <LazyLoadImg v-if="object.Images && object.Images[0]" :src="object.Images[0]" :relativeHeight="65">
                    </LazyLoadImg>
                </a>
                <div class="d-flex mt-1px" v-if="object.Images && object.Images.length > 1">
                    <a
                        v-for="(item, index) in object.Images.slice(1, 5)"
                        :key="index"
                        @click="showSlider(index + 1)"
                        class="flex-grow-1"
                        :class="{
                            'mr-1px': index < object.Images.slice(1, 5).length - 1,
                        }"
                    >
                        <LazyLoadImg :src="item" :relativeHeight="100"></LazyLoadImg>
                    </a>
                </div>
            </div>

            <!-- first link preview -->
            <LinkPreview v-if="links != null && links.length != 0" :url="links[0]" class="mt-2"></LinkPreview>

            <template v-slot:footer v-if="author" class="py-1">
                <div class="card-footer-actions d-flex flex-wrap">
                    <div class="d-flex align-items-center" id="reactions">
                        <Reactions class="ml-1" :object="object" object-type="post"></Reactions>
                    </div>
                </div>

                <CommentSection
                    v-if="postObject.ID"
                    post-type="post"
                    :PostID="postObject.ID"
                    :profileDisplayed="me.Profile"
                    :ShowComments="showComments"
                    :highlightCommentID="highlightCommentID"
                    @comment-count-update="commentCount = $event"
                    @show-comments="showComments = true"
                >
                </CommentSection>
            </template>
        </b-card>

        <!-- deleting modal -->
        <b-modal
            :id="'deleting-modal' + object.ID"
            size="sm"
            title="Delete post"
            centered
            @ok="deletePost"
            ok-title="Delete"
            ok-variant="primary"
            :busy="deleting || deleted"
        >
            <b-overlay :show="deleting || deleted">
                <p class="mb-1">Once you delete a post, there is no going back. Please be certain.</p>
            </b-overlay>
        </b-modal>
        <!-- deleting modal -->

        <!-- announcement modal -->
        <b-modal
            :id="'announcement-modal' + object.ID"
            size="md"
            title="Notify everyone in community?"
            centered
            @ok="makeAnnouncement"
            ok-title="Send"
            ok-variant="primary"
            :busy="announcing || announced"
        >
            <b-overlay :show="announcing || announced">
                <p class="mb-1">
                    This will send an email notification to every community member. Are you sure you want to send this?
                </p>
            </b-overlay>
        </b-modal>
        <!-- announcement modal -->

        <!-- slider modal -->
        <b-modal
            v-if="object.Images"
            :id="'slider' + object.ID"
            size="xl"
            class="w-100"
            title=""
            hide-footer
            hide-headers
            centered
            no-fade
            content-class="bg-transparent gallery-modal"
        >
            <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                :controls="object.Images.length > 1"
                ref="imageslider"
            >
                <b-carousel-slide :img-src="item" v-for="(item, index) in object.Images" :key="index">
                </b-carousel-slide>
            </b-carousel>
        </b-modal>
        <!-- slider modal -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.textNotVisible {
    overflow: hidden;
    max-height: 130px;
    -webkit-mask-image: linear-gradient(to bottom, black 65%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 65%, transparent 100%);
}

.btn {
    color: #443c89;
}

.readbtn {
    cursor: pointer;
}

.mt-1px {
    margin-top: 1px;
}

.mr-1px {
    margin-right: 1px;
}

.photos {
    margin: 0px -1.25rem;
}

.photo-container {
    padding-bottom: 65%;
    position: relative;
    overflow: hidden;
    flex-grow: 1;

    &.more-photos {
        padding-bottom: 32%;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.3s;

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }
    }
}
</style>

<script>
import Vue from 'vue';
import axios from 'axios';
import EventBus from '@/eventBus';
import linkify from 'vue-linkify';
import Reactions from '../Reactions.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';

Vue.directive('linkified', linkify);

export default {
    name: 'FeedPost',
    components: {
        Reactions,
        CoText,
    },
    props: ['postObject', 'highlightCommentID', 'space'],
    data() {
        return {
            search: '',
            links: [],
            timer: null,
            currentIndex: 0,
            imageURL: '',
            author: null,
            me: this.$store.state.me,
            admin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),
            deleting: false,
            deleted: false,
            toggleReadMore: false,
            toggleReadLess: false,
            textNotVisible: true,

            announcing: false,
            announced: false,
            commentCount: 0,
            showComments: false,
            slide: 0,
            object: null,
        };
    },
    mounted() {
        this.addReadButton();
    },
    created() {
        this.object = this.postObject;
        this.getAuthor(this.object.AuthorID);
        this.findAllLinks();
    },
    methods: {
        readMoreText() {
            this.textNotVisible = false;
            this.toggleReadMore = false;
            this.toggleReadLess = true;
        },
        readLessText() {
            this.textNotVisible = true;
            this.toggleReadMore = true;
            this.toggleReadLess = false;
        },
        addReadButton() {
            const post = this.$refs.postText;
            if (post && post.scrollHeight > post.clientHeight) {
                this.toggleReadMore = true;
            } else {
                this.toggleReadMore = false;
                this.textNotVisible = false;
            }
        },
        findAllLinks() {
            if (!this.postObject.Text) {
                return;
            }

            const regex =
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
            const str = this.postObject.Text;
            let m;

            while ((m = regex.exec(str)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    this.links.push(m[0]);
                });
            }
        },

        makeAnnouncement(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            this.announcing = true;

            axios({
                method: 'POST',
                url: `/admin/community/post/send-announcement/${this.object.ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.announcing = false;
                    this.announced = true;
                    this.$bvModal.hide(`announcement-modal${this.object.ID}`);
                    const message = {
                        Message: 'Announcement was successfuly sent',
                        Details: null,
                    };
                    EventBus.$emit('INFO', message);
                    this.object = response.data;
                })
                .catch((error) => {
                    this.announcing = false;
                    console.log(error);
                });
        },

        showSlider(index) {
            this.slide = index;
            this.$bvModal.show(`slider${this.object.ID}`);
        },

        getAuthor(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response.Users != null) {
                        this.author = response.Users[0];
                    } else {
                        this.author = {
                            ID: '',
                            Profile: { ImageURL: '', Name: 'User with hidden profile' },
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
        charCount(str, letter) {
            let letterCount = 0;
            for (let position = 0; position < str.length; position++) {
                if (str.charAt(position) == letter) {
                    letterCount += 1;
                }
            }
            return letterCount;
        },
        viewPost() {
            this.$router.push(`/post/${this.object.Slug}`);
        },
        editPost() {
            this.$router.push(`/post/edit/${this.object.Slug}`);
        },

        deletePost(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();

            this.deleting = true;
            const req = JSON.stringify(this.object);

            if (this.me.ID == this.author.ID) {
                this.$store
                    .dispatch('deletePost', req)
                    .then((response) => {
                        setTimeout(() => {
                            this.deleting = false;
                            this.deleted = true;
                            const message = {
                                Message: 'Post deleted',
                                Details: null,
                            };
                            EventBus.$emit('INFO', message);
                            // EventBus.$emit('RELOADFEED', null);
                            // EventBus.$emit('unblur');
                            this.$bvModal.hide(`deleting-modal${this.object.ID}`);

                            // check here what the current route is and handle accordingly!
                            if (this.$router.currentRoute.name.indexOf('DetailView') > -1) this.$router.push('/feed');
                        }, 300);
                    })
                    .catch((error) => {
                        this.deleting = false;
                        if (error.response.data && error.response.data.message) {
                            EventBus.$emit('ERROR', {
                                Message: error.response.data.message,
                                Details: error.response.data.details,
                            });
                        } else {
                            EventBus.$emit('ERROR', {
                                Message: 'Could not delete post. Please try again later.',
                                Details: null,
                            });
                        }
                    });
            } else if (this.me.Permissions.includes('space_admin')) {
                this.$store
                    .dispatch('deletePostAdmin', req)
                    .then((response) => {
                        setTimeout(() => {
                            this.deleting = false;
                            this.deleted = true;
                            const message = {
                                Message: 'Post deleted',
                                Details: null,
                            };
                            EventBus.$emit('INFO', message);
                            // EventBus.$emit('RELOADFEED', null);
                            // EventBus.$emit('unblur');
                            this.$bvModal.hide(`deleting-modal${this.object.ID}`);

                            // check here what the current route is and handle accordingly!
                            if (this.$router.currentRoute.name.indexOf('DetailView') > -1) this.$router.push('/feed');
                        }, 2000);
                    })
                    .catch((error) => {
                        this.deleting = false;
                        if (error.response.data && error.response.data.message) {
                            EventBus.$emit('ERROR', {
                                Message: error.response.data.message,
                                Details: error.response.data.details,
                            });
                        } else {
                            EventBus.$emit('ERROR', {
                                Message: 'Could not delete post. Please try again later.',
                                Details: null,
                            });
                        }
                    });
            }
        },
    },
};
</script>
