var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModal)?_c('transition',{attrs:{"name":"modal"}},[_c('portal',{attrs:{"selector":"#portal-target-for-modal"}},[_c('div',{staticClass:"co-modal-outer"},[_c('div',{staticClass:"co-modal",class:Object.assign({}, _vm.classes),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickAway($event)}}},[_c('div',{staticClass:"co-modal__wrapper",class:Object.assign({}, _vm.classes,
                        {'align-items-stretch': _vm.useAvailableHeight}),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickAway($event)}}},[_c('div',{staticClass:"co-modal__content",class:Object.assign({}, _vm.classes)},[_c('div',{staticClass:"co-modal__header"},[_vm._t("header",[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.titleImage)?_c('CoThumbnail',{attrs:{"imageURL":_vm.titleImage}}):_vm._e(),_c('CoHeading',{staticClass:"co-modal-header__title",attrs:{"text":_vm.title,"type":"h2"}}),_c('CoRoundButton',{attrs:{"icon":"x-lg","variant":"light"},on:{"click":_vm.discard}})],1)])],2),(_vm.hasBody)?_c('div',{staticClass:"co-modal__body",class:{
                                hasHeader: _vm.hasHeader,
                                hasBody: _vm.hasBody,
                                hasFooter: _vm.hasFooter,
                                'overflow-visible': _vm.overflowY === 'visible',
                                bodyClass: _vm.bodyClass,
                            }},[_vm._t("body")],2):_vm._e(),(_vm.hasFooter)?_c('div',{staticClass:"co-modal__footer"},[_vm._t("footer")],2):_vm._e()])])])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }