<template>
    <transition name="post-fade">
        <div class="d-flex mb-3 feedpost">
            <b-card header-tag="header" footer-tag="footer" class="d-inline w-100">
                <template v-slot:header>
                    <div class="d-flex align-items-top">
                        <div>
                            <b-skeleton type="avatar" class="profile-avatar"></b-skeleton>
                        </div>
                        <div class="ml-3 flex-grow-1">
                            <b-skeleton animation="wave" width="75%"></b-skeleton>

                            <b-skeleton animation="wave" width="55%"></b-skeleton>
                        </div>
                        <div class="flex-grow-1"></div>
                        <div>
                            <b-dropdown
                                variant="ml-2 px-2"
                                menu-class="mt-3 border-1"
                                no-caret
                                right
                                style="position: relative; bottom: 7px; left: 12px"
                            >
                                <template v-slot:button-content>
                                    <b-icon icon="three-dots-vertical" class="m-0" scale="1.2"></b-icon>
                                </template>
                                <div class="px-2">
                                    <b-skeleton animation="wave" width="75%"></b-skeleton>
                                    <b-skeleton animation="wave" width="65%"></b-skeleton>
                                </div>
                            </b-dropdown>
                        </div>
                    </div>
                </template>
                <b-skeleton animation="wave" width="85%"></b-skeleton>

                <template class="mb-3">
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="75%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="65%"></b-skeleton>
                </template>
                <div class="mt-4">
                    <b-skeleton-img></b-skeleton-img>
                </div>
            </b-card>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'FeedPostSkeleton',

    data() {
        return {};
    },

    created() {},

    mounted() {},
};
</script>
