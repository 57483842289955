











import axios from 'axios';
import { get, map } from 'lodash';
import SinglePostPage from '@/components/Templates/User/Post/SinglePostPage/SinglePostPage.vue';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';

export default {
    name: 'CoSinglePost',
    components: {
        SinglePostPage,
        CoPost,
    },
    data() {
        return {
            post: {},
            loading: false,
        };
    },
    mounted() {
        const { slug } = this.$route.params;

        if (this.$route.name === 'Single Post View') {
            this.fetchPost(slug);
        } else if (this.$route.name === 'Single Page Update View') {
            this.fetchPageUpdate(slug);
        } else {
            this.$router.replace('/feed/error404');
        }
    },
    methods: {
        fetchPost(slug) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/post/by-slug/${slug}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        this.post = {
                            object: response.data,
                            wasUpdated: false,
                            type: 'post',
                            id: response.data.ID,
                        };

                        this.post.object.Type = 'post';
                        let users = null;
                        try {
                            users = await axios({
                                method: 'POST',
                                url: '/user/listbyids',
                                data: {
                                    IDS: [
                                        {
                                            ID:
                                                response.data.AuthorID ||
                                                response.data.CreatedBy ||
                                                response.data.UserID,
                                        },
                                    ],
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            users = map(users.data.Users, (user) => ({
                                ID: get(user, 'ID'),
                                Name: get(user, 'Profile.Name'),
                                Slug: get(user, 'Slug'),
                                ImageURL: get(user, 'Profile.ImageURL'),
                                Bio: get(user, 'Profile.Bio'),
                            }));
                        } catch (error) {
                            console.log(error);
                        }

                        if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                            this.post.object.Author = users.find(
                                (user) =>
                                    user.ID === this.post.object.AuthorID ||
                                    user.ID === this.post.object.CreatedBy ||
                                    user.ID === this.post.object.UserID
                            );
                        }

                        const tmpimages =
                            get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                        this.post.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchPageUpdate(slug) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/project/update/by-id/${slug}`, // its an id not slug
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (response && response.data) {
                        this.post = {
                            object: response.data,
                            wasUpdated: false,
                            type: 'project-update',
                        };

                        this.post.object.Type = 'project-update';
                        let users = null;
                        try {
                            users = await axios({
                                method: 'POST',
                                url: '/user/listbyids',
                                data: {
                                    IDS: [
                                        {
                                            ID:
                                                response.data.AuthorID ||
                                                response.data.CreatedBy ||
                                                response.data.UserID,
                                        },
                                    ],
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            });
                            users = map(users.data.Users, (user) => ({
                                ID: get(user, 'ID'),
                                Name: get(user, 'Profile.Name'),
                                Slug: get(user, 'Slug'),
                                ImageURL: get(user, 'Profile.ImageURL'),
                                Bio: get(user, 'Profile.Bio'),
                            }));
                        } catch (error) {
                            console.log(error);
                        }

                        if (response.data.AuthorID || response.data.CreatedBy || response.data.UserID) {
                            this.post.object.Author = users.find(
                                (user) =>
                                    user.ID === this.post.object.AuthorID ||
                                    user.ID === this.post.object.CreatedBy ||
                                    user.ID === this.post.object.UserID
                            );
                        }

                        this.post.object.ParentContent = {};
                        this.post.object.ParentContent.Fetch = {
                            ID: this.post.object.ProjectID,
                            Type: 'project',
                        };
                        this.post.object.CreatedAt = this.post.object.Created;

                        const tmpimages =
                            get(this.post, 'object.Images', null) || get(this.post, 'object.ImageURLs', []);
                        this.post.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
