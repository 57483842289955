<style lang="less" scoped>
.view-switcher .btn {
    background-color: white;

    &.active {
        background-color: var(--c-purple);
        color: #ffffff;
    }
}
</style>
<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div>
            <div class="d-md-flex align-items-center justify-content-between p-0">
                <h2>Events</h2>
                <CoButton variant="primary" @click="createEvent">create new event</CoButton>
            </div>
            <div class="mt-4 d-md-flex align-items-center justify-content-between">
                <div class="filter-items">
                    <CoInput
                        type="search"
                        icon="search"
                        placeholder="Search an event"
                        v-model="searchText"
                        @input="
                            loadingSearch = true;
                            onSearch = true;
                            searchEvents($event);
                        "
                        @blur="searchText && searchText.trim().length === 0 ? searchEvents : null"
                    />
                </div>
                <div
                    v-if="!onSearch"
                    class="btn-group mt-2 mt-md-0 view-switcher"
                    role="group"
                    aria-label="Basic example"
                >
                    <a
                        href="#"
                        title="all events"
                        v-bind:class="{ active: view == 1 }"
                        @click="switchView(1)"
                        class="btn"
                        >all</a
                    >
                    <a
                        href="#"
                        title="upcoming events"
                        v-bind:class="{ active: view == 2 }"
                        @click="switchView(2)"
                        class="btn"
                        >upcoming</a
                    >
                    <a
                        href="#"
                        title="past events"
                        v-bind:class="{ active: view == 3 }"
                        @click="switchView(3)"
                        class="btn"
                        >past</a
                    >
                </div>
            </div>
            <CoTable
                :loading="requestLoading || loadingSearch"
                :itemsPerPage="50"
                :items="visibleObjects"
                resizableColumns
                :columns="[
                    {
                        key: 'object.Title',
                        title: 'Title',
                        style: 'width:200px;',
                    },
                    {
                        key: 'object.StartDate',
                        title: 'Start',
                    },
                    {
                        key: 'object.EndDate',
                        title: 'End',
                    },
                    {
                        key: 'object.ParticipantsCount',
                        title: 'Participants',
                        style: 'width:125px;',
                    },
                    {
                        key: 'object.OrganizerProfiles',
                        title: 'Organizers',
                        notSortable: true,
                        style: 'width:125px;',
                    },
                    {
                        key: 'actions',
                        notSortable: true,
                        style: 'text-align:right; width: 150px;',
                    },
                ]"
            >
                <template v-slot:object\.Title="slotProps">
                    <CoLink
                        styles="primary truncate"
                        style="min-width: 0"
                        :to="`/event/${slotProps.item.object.Slug}`"
                        newTab
                        >{{ slotProps.item.object.Title }}
                    </CoLink>
                </template>
                <template v-slot:object\.StartDate="slotProps">
                    <CoDate :date="slotProps.item.object.StartDate" customFormat="E, Pp" />
                </template>
                <template v-slot:object\.EndDate="slotProps">
                    <CoDate :date="slotProps.item.object.EndDate" customFormat="E, Pp" />
                </template>
                <template v-slot:object\.OrganizerProfiles="slotProps">
                    <div v-if="loadingUsers">
                        <CoSkelleton width="66%" height="1rem" style="height: 1rem" />
                    </div>
                    <div v-else>
                        <CoLink
                            v-for="(Organizer, index) in get(slotProps, 'item.object.OrganizerProfiles', [])"
                            :key="get(Organizer, 'ID', 'unknownID') + index"
                            :title="get(Organizer, 'Profile.Name', 'Unknown')"
                            :to="`/admin/community/member/view/${get(Organizer, 'ID')}`"
                            styles="co-link primary truncate"
                            newTab
                        />
                    </div>
                </template>
                <template v-slot:actions="slotProps">
                    <!-- edit action -->
                    <CoTippy text="Edit">
                        <CoLink styles="primary" variant="text" :to="`/admin/event/edit/${slotProps.item.object.Slug}`">
                            <CoIcon name="pencil" />
                        </CoLink>
                    </CoTippy>
                    <!-- duplicate action -->
                    <CoTippy text="Duplicate">
                        <CoLink
                            styles="primary"
                            variant="text"
                            isExternalLink
                            @click.native.prevent="createDuplicate(slotProps.item.object)"
                        >
                            <CoIcon name="copy" />
                        </CoLink>
                    </CoTippy>
                    <!-- delete action -->
                    <CoTippy text="Delete">
                        <CoConfirmation
                            confirmLabel="Delete"
                            confirmVariant="danger"
                            cancelLabel="Cancel"
                            @confirm="deleteEvent(slotProps.item.object)"
                            :message="`Delete '${slotProps.item.object.Title}'?`"
                        >
                            <CoLink styles="primary" variant="text" isExternalLink :to="null">
                                <CoIcon name="trash" />
                            </CoLink>
                            <template v-slot:messageBody>
                                <p>Once you delete an event, there is no going back. Please be certain.</p>
                            </template>
                        </CoConfirmation>
                    </CoTippy>
                </template>
            </CoTable>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import { McWysiwyg } from '@mycure/vue-wysiwyg';
import EventBus from '../../../eventBus';
import { get, set, orderBy, uniq, debounce } from 'lodash';

import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoSkelleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';

export default {
    name: 'Events',
    components: {
        McWysiwyg,
        VueTagsInput,
        CoTable,
        CoTippy,
        CoLink,
        CoDate,
        CoInput,
        CoIcon,
        CoSkelleton,
    },
    data() {
        return {
            // for upcoming/past switcher
            view: 1,
            // for search
            searchText: '',
            onSearch: false,
            loadingSearch: false,
            // items
            objects: [],
            pastObjects: [],

            requestLoading: false,
            loadingUsers: true,

            toDelete: null,
        };
    },
    computed: {
        visibleObjects() {
            if (this.onSearch) return this.objects;

            if (this.view == 1) return this.objects.concat(this.pastObjects);

            if (this.view == 2) return this.objects;

            if (this.view == 3) return this.pastObjects;
        },
    },
    created() {},
    mounted() {
        this.requestLoading = true;
        this.getEvents();
    },
    methods: {
        get,
        debounce,
        switchView(number) {
            this.view = number;
        },

        requestFinished() {
            this.requestLoading = false;
            this.switchView(1);
            this.getPastEvents();
        },

        createEvent() {
            this.$router.push('/admin/event/create');
        },

        fetchOrganizers(userIDs) {
            if (!userIDs || userIDs.length < 1) {
                this.loadingUsers = false;
                return;
            }
            this.loadingUsers = true;
            axios({
                method: 'POST',
                url: '/user/listbyids',
                data: { IDS: uniq(userIDs).map((ID) => ({ ID: ID })) },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const users = get(response, 'data.Users', []);
                    // assign Organizers Profile Info to the event list
                    this.objects = this.objects.map((obj) => {
                        return set(
                            obj,
                            'object.OrganizerProfiles',
                            get(obj, 'object.Organizers', []).map((ID) => {
                                return users.find((user) => get(user, 'ID', '') == ID);
                            })
                        );
                    });
                    this.pastObjects = this.pastObjects.map((obj) => {
                        return set(
                            obj,
                            'object.OrganizerProfiles',
                            get(obj, 'object.Organizers', []).map((ID) => {
                                return users.find((user) => get(user, 'ID', '') == ID);
                            })
                        );
                    });
                })
                .catch((error) => {
                    console.log('error while fetching organizers', error);
                })
                .finally(() => {
                    this.loadingUsers = false;
                });
        },

        getEvents(next) {
            this.$store
                .dispatch('getEventsV2', next)
                .then((response) => {
                    if (!get(response, 'objects', null)) {
                        this.requestFinished();
                        return;
                    }

                    // add participant count to the list of objects
                    let objects = get(response, 'objects', []).map((obj) => {
                        return set(obj, 'object.ParticipantsCount', get(obj, 'object.Participants', []).length);
                    });
                    // sort descending by startdate
                    objects = orderBy(objects, 'object.StartDate', ['desc']);
                    // append to the list of objects
                    this.objects.push.apply(this.objects, objects);

                    if (response.next && response.next != '') {
                        this.getEvents(response.next);
                    } else {
                        this.requestFinished();
                    }
                })
                .catch((error) => {
                    console.log('error loading upcoming events', error);
                    this.requestFinished();
                });
        },

        getPastEvents(next) {
            this.$store
                .dispatch('getPastEvents', next)
                .then((response) => {
                    // add participant count to the list of objects
                    let objects = get(response, 'objects', []).map((obj) => {
                        return set(obj, 'object.ParticipantsCount', get(obj, 'object.Participants', []).length);
                    });
                    // append to the list of objects
                    this.pastObjects.push.apply(this.pastObjects, objects);
                    if (response.next && response.next != '') {
                        this.getPastEvents(response.next);
                    } else {
                        // if all pages were loaded fetch organizers
                        let upcomingIDs = this.objects.map((obj) => get(obj, 'object.Organizers', [])).flat();
                        let pastIDs = this.pastObjects.map((obj) => get(obj, 'object.Organizers', [])).flat();
                        this.fetchOrganizers(upcomingIDs.concat(pastIDs));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },

        createDuplicate(obj) {
            obj.Title += ' Copy';
            delete obj.Slug;
            delete obj.WebFlowID;
            delete obj.Participants;
            delete obj.ParticipantsCount;
            delete obj.StartDate;
            delete obj.EndDate;

            this.$store.state.eventDuplicate = obj;
            this.$router.push('/admin/event/create');
        },

        deleteEvent(eventObj) {
            if (!eventObj) return;

            this.deleting = true;
            const data = JSON.stringify(eventObj);
            axios({
                method: 'DELETE',
                url: '/admin/event',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$router.go();
                })
                .catch((error) => {
                    console.log(error);
                    this.deleting = false;
                    if (error && error.response && error.response.status == 404) {
                        EventBus.$emit('ERROR', {
                            Message:
                                'This event was already deleted. Refreshing the page will show correct event list.',
                            Details: '',
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Failed to delete event due to internal error. Please, contact support',
                            Details: '',
                        });
                    }
                })
                .finally(() => {
                    this.deleting = false;
                });
        },
        searchEvents: debounce(function (e) {
            this.loadingSearch = true;
            this.onSearch = true;

            // Exit search mode if search text is empty
            if (!this.searchText || (this.searchText && this.searchText.trim() === '')) {
                this.loadingSearch = false;
                this.onSearch = false;
                return;
            }

            if (this.searchText && this.searchText.trim().length < 2) {
                this.loadingSearch = false;
                this.onSearch = false;
                return;
            }

            this.$store
                .dispatch('searchEvents', this.searchText)
                .then(({ objects }) => {
                    this.objects = objects || [];
                    this.loadingSearch = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.onSearch = false;
                    this.loadingSearch = false;
                });
        }, 500),
    },
};
</script>
