import { render, staticRenderFns } from "./MemberAccountV2.vue?vue&type=template&id=dbb8caac&scoped=true&"
import script from "./MemberAccountV2.vue?vue&type=script&lang=ts&"
export * from "./MemberAccountV2.vue?vue&type=script&lang=ts&"
import style0 from "@/assets/less/manage-space/base.less?vue&type=style&index=0&id=dbb8caac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbb8caac",
  null
  
)

export default component.exports